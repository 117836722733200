import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { useTranslation } from 'react-i18next';
import {
    Col,
    Form,
    FormInstance,
    Input,
    message,
} from 'antd';
import { validatePurchaseOrder } from 'network/consignments.api';
import { CheckCircleFilled } from '@ant-design/icons';

const styles = () => ({
    formItem: {
        display: 'flex',
        flexDirection: 'column',
        width: 250,
        alignItems: 'left',
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>> {
    form: FormInstance;
    params: any;
}

const PurchaseOrderNumberField = (props: IProps) => {
    const { t } = useTranslation();
    const {
        classes,
        params,
        form,
    } = props;
    const {
        PurchaseOrderNumber,
    } = formFields;
    const {
        key,
        fetchPoDetails,
        pieceIndex,
        resetProductCode,
        showProductCodeDropdown,
        getValidationRules,
        getIsRequiredValidation,
    } = params;

    const [value, setValue] = React.useState<string>(form.getFieldValue(key));
    const [valid, setValid] = React.useState<boolean>(false);
    const firstRender = React.useRef(true);

    React.useEffect(() => {
        if (firstRender.current) {
            setValid(form.getFieldValue(`valid_${key}`));
            firstRender.current = false;
            return;
        }
        form.setFieldsValue({
            [`valid_${key}`]: false,
        });
        if (showProductCodeDropdown && valid) {
            resetProductCode(pieceIndex);
        }
        setValid(false);
    }, [value]);

    const validatePo = async () => {
        const isValid = form.getFieldValue(`valid_${key}`);
        setValid(isValid);
        const childClient = form.getFieldValue('childClient');
        if (!childClient || !Array.isArray(childClient?.split('+'))) {
            message.error('Please select account to validate');
            return;
        }
        if (!isValid) {
            const response = await validatePurchaseOrder({
                referenceNumber: value,
                customerCode: childClient?.split('+')[0],
            });
            if (response.isSuccess) {
                if (response.data?.success) {
                    message.success('Valid Purchase Order Number');
                    if (showProductCodeDropdown) {
                        fetchPoDetails(value, pieceIndex);
                    }
                    form.setFieldsValue({
                        [`valid_${key}`]: true,
                    });
                    setValid(true);
                } else {
                    message.error('Invalid Purchase Order Number');
                }
            } else {
                message.error(response.errorMessage);
            }
        }
    };
    return (
        <Col span={12}>
            <div style={{ display: 'flex' }}>
                <div className={classes.formItem}>
                    <div
                        className={classes.title}
                        style={{ width: PurchaseOrderNumber.width }}
                    >
                        {t('purchaseOrderNumber')}
                        {getIsRequiredValidation(PurchaseOrderNumber.key, true) && '*'}
                    </div>
                    <Form.Item
                        name={key}
                        initialValue={PurchaseOrderNumber.defaultValue}
                        valuePropName={PurchaseOrderNumber.valuePropName}
                        rules={getValidationRules(PurchaseOrderNumber, true)}
                    >
                        <Input
                            placeholder={t('purchaseOrderNumber')}
                            onChange={(e) => setValue(e.target.value)}
                        />
                    </Form.Item>
                </div>
                {!valid && (
                    <div
                        style={{
                            marginLeft: 20,
                            display: 'flex',
                            alignItems: 'center',
                            color: !valid ? '#1890ff' : '#ccc',
                            cursor: 'pointer',
                        }}
                        onClick={validatePo}
                    >
                        Validate
                    </div>
                )}
                {valid && (
                    <div
                        style={{
                            marginLeft: 20,
                            display: 'flex',
                            alignItems: 'center',
                            color: 'green',
                        }}
                    >
                        <CheckCircleFilled />
                    </div>
                )}
            </div>
        </Col>
    );
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(PurchaseOrderNumberField);

import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import {
    Col,
    Form,
    FormInstance,
    Input,
} from 'antd';
import { commonStyleSheet } from 'library/common-styles';
import { FormField } from 'components/create-consignment/create-consignment.types';

const styles = (theme: ThemeType) => ({
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
    },
    referenceItemEway: {
        display: 'flex',
        flexDirection: 'column',
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    formItem: {
        width: '100%',
    },
    titleEway: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
    },
    contentType: {
        ...commonStyleSheet(theme).flexRow,
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    form: FormInstance;
    field: FormField;
    params: any;
}

const EwayBillAddressDetailsField = (props: IProps) => {
    const {
        classes,
        showEwayBill,
        form,
        field,
        params,
    } = props;
    const {
        ConsignmentCategory,
        DeclaredValue,
        EwayBillType,
    } = formFields;

    const { getValidationRules, getIsRequiredValidation } = params;
    const consignmentCategory = Form.useWatch(ConsignmentCategory.key, form);
    const declaredValueFieldData = Form.useWatch(DeclaredValue.key, form);
    const ewayBillType = Form.useWatch(['ewayBill', EwayBillType.key], form);
    const checkDeclaredValueIsInt = (declaredValueFieldData && !Number.isNaN(declaredValueFieldData));
    const declaredValue = checkDeclaredValueIsInt ? Number(declaredValueFieldData) : 0;

    const showField = declaredValue > 50000 && showEwayBill && consignmentCategory !== 'international';

    if (!showField) return null;
    if (ewayBillType !== 'ewaybill_details') return null;

    return (
        <Col span={12}>
            <div className={classes.referenceItemEway}>
                <div className={classes.titleEway}>
                    {field.label}
                    {getIsRequiredValidation(field.key) && '*'}
                </div>
                <Form.Item
                    name={field.key}
                    className={classes.referenceTitle}
                    initialValue={field.defaultValue}
                    valuePropName={field.valuePropName}
                    rules={getValidationRules(field)}
                >
                    <Input
                        placeholder={field.placeholder}
                        disabled
                    />
                </Form.Item>
            </div>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config } = master;
    return {
        showEwayBill: config?.customer_portal_consignment_config?.show_eway_bill_details || false,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(EwayBillAddressDetailsField);

import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import {
    Col,
    Form,
    FormInstance,
    Radio,
} from 'antd';

const styles = () => ({
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
    },
    referenceItemEway: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 16,
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    form: FormInstance;
    params: any;
}

const EwayBillTypeField = (props: IProps) => {
    const {
        classes,
        showEwayBill,
        form,
        params,
    } = props;
    const {
        ConsignmentCategory,
        EwayBillType,
        DeclaredValue,
    } = formFields;

    const { getValidationRules, getIsRequiredValidation } = params;

    const consignmentCategory = Form.useWatch(ConsignmentCategory.key, form);
    const declaredValueFieldData = Form.useWatch(DeclaredValue.key, form);
    const checkDeclaredValueIsInt = (declaredValueFieldData && !Number.isNaN(declaredValueFieldData));
    const declaredValue = checkDeclaredValueIsInt ? Number(declaredValueFieldData) : 0;

    const showField = declaredValue > 50000 && showEwayBill && consignmentCategory !== 'international';

    if (!showField) return null;

    const EwayBillTypeOptions = EwayBillType.options;
    return (
        <Col span={12}>
            <div className={classes.title}>
                {EwayBillType.label}
                {getIsRequiredValidation(EwayBillType.key) ? '*' : ''}
            </div>
            <Form.Item
                name={['ewayBill', EwayBillType.key]}
                initialValue={EwayBillType.defaultValue}
                valuePropName={EwayBillType.valuePropName}
                rules={getValidationRules(EwayBillType)}
            >
                <Radio.Group
                    options={EwayBillTypeOptions}
                    style={{
                        display: 'flex',
                        flexDirection: EwayBillType.direction,
                    }}
                />
            </Form.Item>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config } = master;
    return {
        showEwayBill: config?.customer_portal_consignment_config?.show_eway_bill_details || false,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(EwayBillTypeField);

import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { useTranslation } from 'react-i18next';
import {
    Checkbox,
    Col,
    Form,
    FormInstance,
} from 'antd';
import { commonStyleSheet } from 'library/common-styles';

const styles = (theme: ThemeType) => ({
    formItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        '& .ant-checkbox + span': {
            color: '#111111',
            fontSize: 14,
            fontWeight: 600,
        },
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    contentType: {
        ...commonStyleSheet(theme).flexRow,
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
    dimensions: {
        ...commonStyleSheet(theme).flexColumn,
        alignItems: 'left',
    },
    dimentionTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    form: FormInstance;
    isRenderedFromLayout: boolean | undefined;
    params: any;
}

const RiskField = (props: IProps) => {
    const { t } = useTranslation();
    const {
        classes,
        isCpdp,
        showRiskSurchargeType,
        form,
        pieceSubLayout,
        documentSubLayout,
        envelopeSubLayout,
        isRenderedFromLayout,
        params,
    } = props;
    const {
        RiskSurcharge,
        ItemType,
        DeclaredValue,
        ConsignmentCategory,
    } = formFields;

    const { getValidationRules } = params;
    const itemType = Form.useWatch(ItemType.key, form);
    const consignmentCategory = Form.useWatch(ConsignmentCategory.key, form);
    const declaredAmount = Form.useWatch(DeclaredValue.key, form);

    const getDisableRiskSurcharge = () => {
        if (!isCpdp || consignmentCategory === 'international') return false;
        if (!showRiskSurchargeType) return false;
        if (declaredAmount > 200000) {
            // setRiskOptions([
            //     { label: 'Owner Risk 0.1 %', value: 'owner' },
            // ]);
            // if (form.getFieldValue('riskSurchargeType') !== 'owner') {
            //     form.setFieldsValue({
            //         riskSurchargeType: 'owner',
            //     });
            // }
            return true;
        }
        if (declaredAmount > 100000) {
            // setRiskOptions([
            //     { label: 'Owner Risk 0.1 %', value: 'owner' },
            //     { label: 'Carrier Risk 1 %', value: 'carrier' },
            // ]);
            return true;
            // if (disableRiskSurcharge === false) {
            //     setDisableRiskSurcharge(true);
            //     form.setFieldsValue({
            //         riskSurchargeType: 'owner',
            //         [RiskSurcharge.key]: true,
            //     });
            // }
        }
        if (declaredAmount > 50000) {
            return false;
            // setRiskOptions([
            //     { label: 'Owner Risk 0.1 %', value: 'owner' },
            //     { label: 'Carrier Risk 2 %', value: 'carrier' },
            // ]);
            // if (disableRiskSurcharge === false) {
            //     if (!(form?.getFieldValue('riskSurchargeType')
            //         && form?.getFieldValue('riskSurchargeType')?.length)) {
            //         form.setFieldsValue({
            //             riskSurchargeType: 'owner',
            //             [RiskSurcharge.key]: true,
            //         });
            //     }
            //     setDisableRiskSurcharge(true);
            // }
        }
        return false;
        // if (disableRiskSurcharge === true) {
        //     setDisableRiskSurcharge(false);
        // }
        // setRiskOptions([
        //     { label: 'Owner Risk 0.2 % or Rs 25 whichever is higher', value: 'owner' },
        //     { label: 'Carrier Risk 2 % or Rs 25 whichever is higher', value: 'carrier' },
        // ]);
    };

    const disableRiskSurcharge = getDisableRiskSurcharge();

    if (!isRenderedFromLayout) {
        if (itemType === 'document' && !documentSubLayout.includes('isRiskSurchargeApplicable')) {
            return <></>;
        }
        if (itemType === 'envelope' && !envelopeSubLayout.includes('isRiskSurchargeApplicable')) {
            return <></>;
        }
        if ((!itemType || itemType === 'non-document') && !pieceSubLayout.includes('isRiskSurchargeApplicable')) {
            return <></>;
        }
    }

    return (
        <Col span={12}>
            <div className={classes.formItem}>
                <Form.Item
                    name={RiskSurcharge.key}
                    className={classes.contentType}
                    initialValue={RiskSurcharge.defaultValue}
                    valuePropName={RiskSurcharge.valuePropName}
                    dependencies={[DeclaredValue.key]}
                    rules={getValidationRules(RiskSurcharge)}
                >
                    <Checkbox disabled={disableRiskSurcharge}>
                        {t(RiskSurcharge.key)}
                    </Checkbox>
                </Form.Item>
            </div>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config, Customer } = master;
    const customerPortalConfig = config?.customer_portal_config;
    const isCpdp = Customer?.is_cpdp;
    const showRiskSurchargeType = customerPortalConfig?.show_risk_surcharge_type;
    const formSubLayout = config?.customer_portal_order_creation_config?.sub_layout || {};
    const documentSubLayout = formSubLayout?.document || [];
    const pieceSubLayout = formSubLayout?.non_document || [];
    const envelopeSubLayout = formSubLayout?.envelope || [];
    return {
        isCpdp,
        showRiskSurchargeType,
        pieceSubLayout,
        documentSubLayout,
        envelopeSubLayout,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(RiskField);

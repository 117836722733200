import React from 'react';
import { FormInstance } from 'antd';
import { consigneeFormFields, consignorFormFields, formFields } from 'components/create-consignment/create-modal.constants';
import ConsignmentCategoryField from './consignment-category';
import consignmentAccountField from './consignment-account';
import consignmentTypeField from './consignment-type';
import RetailTransactionField from './retail-transaction';
import ConsignmentReferenceNumberField from './consignment-reference-number';
import CustomerReferenceNumberField from './customer-reference-number';
import CashPickupAmountField from './cash-pickup-amount';
import DeliveryTypeField from './delivery-type';
import PudoStoreField from './pudo-store';
import EWBNumberField from './ewb-number';
import EWBDateField from './ewb-date';
import ItemTypeField from './item-type';
import DeclaredValueField from './declared-value';
import RiskField from './risk-surcharge';
import DescriptionField from './description';
import AddressField from './address';
import SingleAddressField from './SingleAddress';
import ServiceTypeSelectorField from './service-type';
import GenericInternationalField from './generic-international-field';
import GenericField from './generic-field';
import PieceField from './piece-field/piece-field';
import DocumentField from './ItemTypes/document-field/document-field';
import EnvelopeField from './ItemTypes/envelope-field/envelope-field';
import BoxDetailsField from './box-details-field/box-details-field';
import DestinationType from './destination-type';
import GenericInternationalConsignorField from './generic-international-consignor-field';
import LoadDataConsignor from './load-data-consignor';
import ConsignorKycImageField from './consignor-kyc-image-field';
import IsCODField from './is-cod';
import CodAmountField from './cod-amount';
import CODModeField from './cod-mode';
import CodInFavour from './cod-in-favour';
import GenericInternationalCurrencyField from './generic-international-currency-field';
import EwayBillTypeField from './eway-bill-type';
import EwayBillNumberField from './eway-bill-number';
import EwayBillDownloadDeclaration from './eway-bill-download-declaration';
import EwayBillTransactionTypeField from './eway-bill-transaction-type';
import EwayBillTransactionOptionsField from './eway-bill-transaction-options';
import EwayBillTransportationModeField from './eway-bill-transportation-mode';
import EwayBillApproxDistanceField from './eway-bill-approx-distance';
import EwayBillDocumentTypesField from './eway-bill-doc-type';
import EwayBillAddressDetails from './eway-bill-address-details';
import EwayBillSenderGstNumberField from './eway-bill-sender-gst-number';
import EwayBillReceiverGSTNumberField from './eway-bill-receiver-gst-number';
import EwayBillInvoiceAmountField from './eway-bill-invoice-amount';
import EwayBillGenericField from './eway-bill-generic-field';
import PickupScheduleField from './pickup-schedule-field';
import PickupTimeField from './pickup-time-field';
import NumPiecesField from './num-pieces';
import WeightField from './weight';
import DimensionsField from './dimensions';
import CustomReferenceNumberField from './custom-reference-number-field';
import DangerousGoodFields from './dangerous-good-renderer';
import pieceField from './piece-field/piece-field-2';

const {
    ConsignmentCategory,
    SubAccount,
    MovementType,
    ActualMovementType,
    RetailTransaction,
    ConsignmentReference,
    CustomerReference,
    CashonPickup,
    DeliveryType,
    PudoStores,
    EwbNumber,
    EwbDate,
    ItemType,
    DeclaredValue,
    RiskSurcharge,
    Description,
    Services,
    PickupSchedule,
    PickupTime,
    Cust_Seller_code,
    EORI_No,
    Against_Bond_LUT,
    ECom_Shipment,
    MEIS,
    Is_Battery,
    DeliveryNote,
    shipment_purpose,
    shipment_purpose_document,
    shipment_Terms,
    IsCOD,
    Amount,
    Mode,
    InFavour,
    EwayBillNo,
    IncoTerms,
    InvoiceType,
    InvoiceDate,
    InvoiceNumber,
    Freight_Cost,
    Freight_Cost_Currency,
    FOB_Value,
    FOB_Value_Currency,
    TotalGSTPaidAmnt,
    TotalGSTPaidCurrency,
    EwayBillType,
    EwayBillNumber,
    TransactionType,
    InwardTransactionSubTypes,
    OutwardTransactionSubTypes,
    TransportationModes,
    ApproxDistance,
    DocumentTypes,
    SenderName,
    SenderAddressLineOne,
    SenderAddressLineTwo,
    SenderPincode,
    SenderCity,
    SenderState,
    SenderCountry,
    SenderPhoneNo,
    SenderGSTIN,
    RecieverName,
    RecieverAddressLineOne,
    RecieverAddressLineTwo,
    RecieverPincode,
    RecieverCity,
    RecieverState,
    RecieverCountry,
    RecieverPhoneNo,
    RecieverGSTIN,
    ProductName,
    HsnValue,
    ProductDescription,
    ProductQuantity,
    ProductUnit,
    ProductValue,
    ProductCGST,
    ProductIGST,
    ProductSGST,
    ProductCESS,
    InvoiceAmount,
    CustomsAmount,
    ImportReferenceNumber,
    ExportReferenceNumber,
    purpose,
    IsCustomsDeclarable,
    Weight,
    NumPieces,
    SrcAddress,
    DstAddress,
    AlternateDelAddress1,
    AlternateDelAddress2,
    RedirectionAddress,
    BillingDetailsAddress,
    IsCsbv,
    TaxPercentage,
    TotalTax,
    ManufacturingCountry,
    IsStackable,
    IGST,
    CGST,
    SGST,
    TaxDetailsRecieverGstin,
    TaxDetailsSenderGstin,
    ForwardReferenceNumber,
} = formFields;

const {
    ConsigneeCompanyName,
    ReceiverVATNumber,
    ConsigneeType,
    ConsigneeCode,
} = consigneeFormFields;

const {
    ConsignorCompanyName,
    OriginType,
    ConsignorIECNo,
    ConsignorGSTINo,
    Consignor_PAN,
    Sender_Bank_Account_No,
    Sender_Bank_AD_Code,
    Sender_Bank_IFSC_Code,
    NEFI_Flag,
    CPCCode,
    ConsignorKycDocType,
    ConsignorKycDocNo,
} = consignorFormFields;

const mapping = {
    dangerousGood: DangerousGoodFields,
    [ConsignmentCategory.key]: ConsignmentCategoryField,
    [SubAccount.key]: consignmentAccountField,
    [MovementType.key]: consignmentTypeField,
    [RetailTransaction.key]: RetailTransactionField,
    [ConsignmentReference.key]: ConsignmentReferenceNumberField,
    [CustomerReference.key]: CustomerReferenceNumberField,
    [CashonPickup.key]: CashPickupAmountField,
    [DeliveryType.key]: DeliveryTypeField,
    [PudoStores.key]: PudoStoreField,
    [EwbNumber.key]: EWBNumberField,
    [EwbDate.key]: EWBDateField,
    [ItemType.key]: (props: any) => {
        const { params = {} } = props;
        if (params?.loadTypeList?.length) {
            ItemType.options = params?.loadTypeList?.map((loadType: any) => {
                return {
                    value: loadType?.name?.toLowerCase(),
                    label: loadType?.id,
                };
            });
        }
        return <ItemTypeField field={ItemType} {...props} />;
    },
    [DeclaredValue.key]: (props: any) => {
        return <DeclaredValueField isRenderedFromLayout field={DeclaredValue} {...props} />;
    },
    [RiskSurcharge.key]: (props: any) => {
        return <RiskField isRenderedFromLayout field={RiskSurcharge} {...props} />;
    },
    [Description.key]: DescriptionField,
    address: (props: any) => {
        return <AddressField {...props} />;
    },
    [SrcAddress.key]: (props: any) => {
        return <SingleAddressField field={SrcAddress} {...props} />;
    },
    [DstAddress.key]: (props: any) => {
        return <SingleAddressField field={DstAddress} {...props} />;
    },
    [AlternateDelAddress1.key]: (props: any) => {
        return <SingleAddressField field={AlternateDelAddress1} {...props} />;
    },
    [AlternateDelAddress2.key]: (props: any) => {
        return <SingleAddressField field={AlternateDelAddress2} {...props} />;
    },
    [RedirectionAddress.key]: (props: any) => {
        return <SingleAddressField field={RedirectionAddress} {...props} />;
    },
    [BillingDetailsAddress.key]: (props: any) => {
        return <SingleAddressField field={BillingDetailsAddress} {...props} />;
    },
    [Services.key]: ServiceTypeSelectorField,
    [PickupSchedule.key]: PickupScheduleField,
    [PickupTime.key]: PickupTimeField,
    [Cust_Seller_code.key]: (props: any) => {
        return <GenericInternationalField field={Cust_Seller_code} {...props} />;
    },
    [shipment_purpose.key]: (props: any) => {
        return (
            <GenericField
                field={shipment_purpose}
                {...props}
            />
        );
    },
    [shipment_purpose_document.key]: (props: any) => {
        return (
            <GenericInternationalField
                field={shipment_purpose_document}
                extraValidationForVisibility={(params: any) => {
                    const { itemType } = params;
                    return itemType !== 'non-document';
                }}
                {...props}
            />
        );
    },
    [shipment_Terms.key]: (props: any) => {
        return <GenericInternationalField field={shipment_Terms} {...props} />;
    },
    [DeliveryNote.key]: (props: any) => {
        return <GenericInternationalField field={DeliveryNote} {...props} />;
    },
    [EORI_No.key]: (props: any) => {
        return <GenericInternationalField field={EORI_No} {...props} />;
    },
    [Against_Bond_LUT.key]: (props: any) => {
        return <GenericInternationalField field={Against_Bond_LUT} {...props} />;
    },
    [ECom_Shipment.key]: (props: any) => {
        return <GenericInternationalField field={ECom_Shipment} {...props} />;
    },
    [MEIS.key]: (props: any) => {
        return <GenericInternationalField field={MEIS} {...props} />;
    },
    [Is_Battery.key]: (props: any) => {
        return <GenericInternationalField field={Is_Battery} {...props} />;
    },
    non_document: PieceField,
    piece: pieceField,
    document: DocumentField,
    envelope: EnvelopeField,
    boxDetails: BoxDetailsField,
    [ReceiverVATNumber.key]: (props: any) => {
        return <GenericInternationalField field={ReceiverVATNumber} {...props} />;
    },
    [ConsigneeCompanyName.key]: (props: any) => {
        return <GenericInternationalField field={ConsigneeCompanyName} {...props} />;
    },
    [ConsigneeType.key]: DestinationType,
    [ConsignorCompanyName.key]: (props: any) => {
        return <GenericInternationalConsignorField field={ConsignorCompanyName} {...props} />;
    },
    [OriginType.key]: (props: any) => {
        return <GenericInternationalConsignorField field={OriginType} {...props} />;
    },
    [ConsignorIECNo.key]: (props: any) => {
        return <GenericInternationalConsignorField field={ConsignorIECNo} {...props} />;
    },
    [ConsignorGSTINo.key]: (props: any) => {
        return <GenericInternationalConsignorField field={ConsignorGSTINo} {...props} />;
    },
    [Consignor_PAN.key]: (props: any) => {
        return <GenericInternationalConsignorField field={Consignor_PAN} {...props} />;
    },
    [Sender_Bank_Account_No.key]: (props: any) => {
        return <GenericInternationalConsignorField field={Sender_Bank_Account_No} {...props} />;
    },
    [Sender_Bank_AD_Code.key]: (props: any) => {
        return <GenericInternationalConsignorField field={Sender_Bank_AD_Code} {...props} />;
    },
    [Sender_Bank_IFSC_Code.key]: (props: any) => {
        return <GenericInternationalConsignorField field={Sender_Bank_IFSC_Code} {...props} />;
    },
    [NEFI_Flag.key]: (props: any) => {
        return <GenericInternationalConsignorField field={NEFI_Flag} {...props} />;
    },
    [CPCCode.key]: (props: any) => {
        return <GenericInternationalConsignorField field={CPCCode} {...props} />;
    },
    importConsignorDataControl: LoadDataConsignor,
    [ConsignorKycDocType.key]: (props: any) => {
        return <GenericInternationalConsignorField field={ConsignorKycDocType} {...props} />;
    },
    [ConsignorKycDocNo.key]: (props: any) => {
        return <GenericInternationalConsignorField field={ConsignorKycDocNo} {...props} />;
    },
    consignorKycFrontImage: (props: any) => {
        return <ConsignorKycImageField fieldKey="consignorKycFrontImage" title="KYC Doc Front" {...props} />;
    },
    consignorKycBackImage: (props: any) => {
        return <ConsignorKycImageField fieldKey="consignorKycBackImage" title="KYC Doc Back" {...props} />;
    },
    [IsCOD.key]: IsCODField,
    [Amount.key]: CodAmountField,
    [Mode.key]: CODModeField,
    [InFavour.key]: CodInFavour,
    [EwayBillNo.key]: (props: any) => {
        return <GenericInternationalField field={EwayBillNo} {...props} />;
    },
    [InvoiceType.key]: (props: any) => {
        return <GenericInternationalField field={InvoiceType} {...props} />;
    },
    [InvoiceDate.key]: (props: any) => {
        return <GenericField field={InvoiceDate} {...props} />;
    },
    [InvoiceNumber.key]: (props: any) => {
        return <GenericField field={InvoiceNumber} {...props} />;
    },
    [IncoTerms.key]: (props: any) => {
        const { params } = props;
        return (
            <GenericField
                field={{
                    ...IncoTerms,
                    options: params?.incotermsList || [],
                }}
                {...props}
            />
        );
    },
    [Freight_Cost.key]: (props: any) => {
        return (
            <GenericInternationalCurrencyField
                field={Freight_Cost}
                unitField={Freight_Cost_Currency}
                {...props}
            />
        );
    },
    [FOB_Value.key]: (props: any) => {
        return (
            <GenericInternationalCurrencyField
                field={FOB_Value}
                unitField={FOB_Value_Currency}
                {...props}
            />
        );
    },
    [TotalGSTPaidAmnt.key]: (props: any) => {
        return (
            <GenericInternationalCurrencyField
                field={TotalGSTPaidAmnt}
                unitField={TotalGSTPaidCurrency}
                {...props}
            />
        );
    },
    [EwayBillType.key]: EwayBillTypeField,
    [EwayBillNumber.key]: EwayBillNumberField,
    ewayBillDownloadDeclaration: EwayBillDownloadDeclaration,
    [TransactionType.key]: EwayBillTransactionTypeField,
    [InwardTransactionSubTypes.key]: EwayBillTransactionOptionsField,
    [OutwardTransactionSubTypes.key]: EwayBillTransactionOptionsField,
    [TransportationModes.key]: EwayBillTransportationModeField,
    [ApproxDistance.key]: EwayBillApproxDistanceField,
    [DocumentTypes.key]: EwayBillDocumentTypesField,
    [NumPieces.key]: (props: any) => {
        return <NumPiecesField isRenderedFromLayout field={NumPieces} {...props} />;
    },
    [Weight.key]: (props: any) => {
        return <WeightField isRenderedFromLayout field={Weight} {...props} />;
    },
    dimensions: (props: any) => {
        return <DimensionsField isRenderedFromLayout {...props} />;
    },
    // This was removed because there were multiple fields with same key and in
    // custom form we do not want fields with multiple checks. So, for now
    // we have used InvoiceDate as the main field
    // Ticket: https://shipsy.atlassian.net/browse/CORE-211733
    // [DocumentNumber.key]: EwayBillDocumentNumberField,
    [InvoiceAmount.key]: EwayBillInvoiceAmountField,
    [CustomsAmount.key]: (props: any) => {
        const { params, form } = props;
        return (
            <GenericField params={params} form={form} field={CustomsAmount} />
        );
    },
    [ImportReferenceNumber.key]: (props: any) => {
        const { params, form } = props;
        return (
            <GenericField params={params} form={form} field={ImportReferenceNumber} />
        );
    },
    [ExportReferenceNumber.key]: (props: any) => {
        const { params, form } = props;
        return (
            <GenericField params={params} form={form} field={ExportReferenceNumber} />
        );
    },
    [purpose.key]: (props: any) => {
        const { params, form } = props;
        return (
            <GenericField params={params} form={form} field={purpose} />
        );
    },
    [ConsigneeCode.key]: (props: any) => {
        const { params, form } = props;
        return (
            <GenericField params={params} form={form} field={ConsigneeCode} />
        );
    },
    [IsCustomsDeclarable.key]: (props: any) => {
        const { params, form } = props;
        return (
            <GenericField params={params} form={form} field={IsCustomsDeclarable} />
        );
    },
    [IsCsbv.key]: (props: any) => {
        return (
            <GenericField {...props} field={IsCsbv} />
        );
    },
    [TaxPercentage.key]: (props: any) => {
        return (
            <GenericField {...props} field={TaxPercentage} MAX_LIMIT={100} />
        );
    },
    [TotalTax.key]: (props: any) => {
        return (
            <GenericField {...props} field={TotalTax} />
        );
    },
    [ManufacturingCountry.key]: (props: any) => {
        const { params } = props;
        const { manufacturingCountryList } = params;
        return (
            <GenericField
                field={
                    {
                        ...ManufacturingCountry,
                        options: manufacturingCountryList || [],
                    }
                }
                {...props}
            />
        );
    },
    // This was removed because there were multiple fields with same key and in
    // custom form we do not want fields with multiple checks. So, for now
    // we have used InvoiceDate as the main field
    // Ticket: https://shipsy.atlassian.net/browse/CORE-211733
    // [DocumentDate.key]: EwayBillDocumentDateField,
    [SenderName.key]: (props: any) => {
        return <EwayBillAddressDetails field={SenderName} {...props} />;
    },
    [SenderAddressLineOne.key]: (props: any) => {
        return <EwayBillAddressDetails field={SenderAddressLineOne} {...props} />;
    },
    [SenderAddressLineTwo.key]: (props: any) => {
        return <EwayBillAddressDetails field={SenderAddressLineTwo} {...props} />;
    },
    [SenderPincode.key]: (props: any) => {
        return <EwayBillAddressDetails field={SenderPincode} {...props} />;
    },
    [SenderCity.key]: (props: any) => {
        return <EwayBillAddressDetails field={SenderCity} {...props} />;
    },
    [SenderState.key]: (props: any) => {
        return <EwayBillAddressDetails field={SenderState} {...props} />;
    },
    [SenderCountry.key]: (props: any) => {
        return <EwayBillAddressDetails field={SenderCountry} {...props} />;
    },
    [SenderPhoneNo.key]: (props: any) => {
        return <EwayBillAddressDetails field={SenderPhoneNo} {...props} />;
    },
    [SenderGSTIN.key]: EwayBillSenderGstNumberField,
    [RecieverName.key]: (props: any) => {
        return <EwayBillAddressDetails field={RecieverName} {...props} />;
    },
    [RecieverAddressLineOne.key]: (props: any) => {
        return <EwayBillAddressDetails field={RecieverAddressLineOne} {...props} />;
    },
    [RecieverAddressLineTwo.key]: (props: any) => {
        return <EwayBillAddressDetails field={RecieverAddressLineTwo} {...props} />;
    },
    [RecieverPincode.key]: (props: any) => {
        return <EwayBillAddressDetails field={RecieverPincode} {...props} />;
    },
    [RecieverCity.key]: (props: any) => {
        return <EwayBillAddressDetails field={RecieverCity} {...props} />;
    },
    [RecieverState.key]: (props: any) => {
        return <EwayBillAddressDetails field={RecieverState} {...props} />;
    },
    [RecieverCountry.key]: (props: any) => {
        return <EwayBillAddressDetails field={RecieverCountry} {...props} />;
    },
    [RecieverPhoneNo.key]: (props: any) => {
        return <EwayBillAddressDetails field={RecieverPhoneNo} {...props} />;
    },
    [RecieverGSTIN.key]: EwayBillReceiverGSTNumberField,
    [ProductName.key]: (props: any) => {
        return <EwayBillGenericField field={ProductName} {...props} />;
    },
    [HsnValue.key]: (props: any) => {
        return <EwayBillGenericField field={HsnValue} {...props} />;
    },
    ewayBillDescription: (props: any) => {
        return <EwayBillGenericField field={ProductDescription} {...props} />;
    },
    [ProductQuantity.key]: (props: any) => {
        return <EwayBillGenericField field={ProductQuantity} {...props} />;
    },
    [ProductUnit.key]: (props: any) => {
        return <EwayBillGenericField field={ProductUnit} {...props} />;
    },
    [ProductValue.key]: (props: any) => {
        return <EwayBillGenericField field={ProductValue} {...props} />;
    },
    [ProductCGST.key]: (props: any) => {
        return <EwayBillGenericField field={ProductCGST} {...props} />;
    },
    [ProductIGST.key]: (props: any) => {
        return <EwayBillGenericField field={ProductIGST} {...props} />;
    },
    [ProductSGST.key]: (props: any) => {
        return <EwayBillGenericField field={ProductSGST} {...props} />;
    },
    [ProductCESS.key]: (props: any) => {
        return <EwayBillGenericField field={ProductCESS} {...props} />;
    },
    [IsStackable.key]: (props: any) => {
        return <GenericField field={IsStackable} {...props} />;
    },
    [IGST.key]: (props: any) => {
        return (
            <GenericField {...props} field={IGST} MAX_LIMIT={100} />
        );
    },
    [CGST.key]: (props: any) => {
        return (
            <GenericField {...props} field={CGST} MAX_LIMIT={100} />
        );
    },
    [SGST.key]: (props: any) => {
        return (
            <GenericField {...props} field={SGST} MAX_LIMIT={100} />
        );
    },
    [TaxDetailsRecieverGstin.key]: (props: any) => {
        return (
            <GenericField {...props} field={TaxDetailsRecieverGstin} />
        );
    },
    [TaxDetailsSenderGstin.key]: (props: any) => {
        return (
            <GenericField {...props} field={TaxDetailsSenderGstin} />
        );
    },
    [ActualMovementType.key]: (props: any) => {
        return (
            <GenericField {...props} field={ActualMovementType} />
        );
    },
    [ForwardReferenceNumber.key]: (props: any) => {
        return (
            <GenericField {...props} field={ForwardReferenceNumber} />
        );
    },
};

export const getFormFieldFromMapping = (id: any, form: FormInstance, params: any, setCreatingAddress?: any) => {
    const Component = mapping[id] as any;
    if (!Component && Array.isArray(params.currentCustomField) && params.currentCustomField.length) {
        return <CustomReferenceNumberField form={form} params={params} />;
    }
    if (Component) {
        return <Component form={form} params={params} setCreatingAddress={setCreatingAddress} />;
    }
    console.log('Cannot find component for ', id);
    return null;
};

export const ifFieldExistsInMapping = (id: any) => {
    if (id === 'consigneeDetails' || id === 'consignorDetails' || id === 'dimensionsV1') return true;
    let Component = mapping[id] as any;
    if (id === 'customReferenceNumber') Component = CustomReferenceNumberField;
    return !!Component;
};

import { ReactElement } from 'react';

export type KeyValue = {
    key: string;
    val: any;
};

export type KeyLabelObject = {
    key: number | string;
    label: string;
};

export interface APIResponseType<DataType> {
    isSuccess: boolean;
    data?: DataType;
    errorMessage?: string;
}

export type Styles<getStylesType extends (...args: any) => any> = {
    [X in keyof ReturnType<getStylesType>]: React.CSSProperties;
};

type ColumnAlign = 'left' | 'right';
type ColumnFixed = boolean | ColumnAlign;

export interface TableColumn<T> {
    key: string;
    align?: ColumnAlign | 'center';
    width?: NumberString;
    fixed?: ColumnFixed;
    dataIndex: string;
    textWrap?: string;
    padding?: number;
    title: string | ReactElement;
    ellipsis?: boolean;
    className?: string;
    render: (key: string, record: T, index?: number) => React.ReactNode;
}

export type NextOrPrev = 'first' | 'prev' | 'next';

export type NumberString = number | string;

export interface RouterProps<Params = any> {
    history: any;
    location: any;
    match: {
        url: any;
        params: Params;
    };
}

export type IdName = {
    id: string;
    name: string;
};

export type KeyLabel = {
    key: string;
    label: string;
};

export type LabelValue = {
    label: string;
    value: string;
};

export type KeyValueMap = {
    [key: string]: string;
};

export enum FilterType {
    date = 'date',
    search = 'search',
    select = 'select',
    input = 'input',
    checkbox = 'checkbox',
    selectSearch = 'select_search',
    rangeDate = 'range_date',
    switch = 'switch',
}

export type TrackingEvent = {
    type: string;
    strActionTime: string;
    strActionDate: string;
    worker_name: string;
    worker_code: string;
    hub_name: string;
    hub_code: string;
    lat: string;
    lng: string;
    failure_reason: string;
    signature_image: string;
    strAction: string;
    poc_image: string;
    event_name: string;
    strOrigin: string;
    strCode: string;
    tracking_location_string: string;
    tracking_note: string;
    tracking_event_name: string;

    drs_image: string;
    event_time: string;
    is_client_synced: boolean;
    premise_image: string;
    location_string: string;
    notes: string;
    otp_success: boolean;
    event_proof_images: string[];
};

export enum ConsignmentCategory {
    DOMESTIC = 'Domestic',
    INTERNATIONAL = 'International',
}

export type Commodity = {
    COMMODITY_CODE: string;
    COMMODITY_ID: string;
    COMMODITY_NAME: string;
    ORGANISATION_ID: string;
    showInMyDtdc: boolean;
    showInPudo: boolean;
    showInSmartApp: boolean;
    captureActualCommodity: boolean;
};

export type Category = {
    categoryId: string;
    categoryName: string;
    commodityList: Commodity[];
};

export type PudoHubs = {
    code: string;
    hub_type: string;
    id: string;
    name: string;
};

export type TimeSlot = {
    start: string;
    end: string;
};

export type Pincodes = {
    srcPincode?: string;
    dstPincode?: string;
};

export type Service = {
    TAT: number;
    period: string;
    serviceType: string;
    serviceTypeId: string;
    isLiteServiceable?: boolean;
    isCodServiceable?: boolean;
    rate?: {
        amount: number;
        currencySymbol: string;
    };
    notes?: string[];
    mandatoryDocuments?: ServiceDocument[];
    additionalDocuments?: ServiceDocument[];
    rateError: string;
    deliveryTime: string;
    tat?: any;
};

export type Inventory = {
    availableCount: number;
    clientId: string;
    counter: string;
    createdAt: number;
    end: string;
    exhausted: boolean;
    id: string;
    prefix: string;
    serviceType: string[];
    start: string;
};

export enum Buckets {
    CONSIGNMENTS = 'CONSIGNMENTS',
    PICKUPS = 'PICKUPS',
    ASN = 'ASN',
    ADDRESSES = 'ADDRESSES',
    RTO_DASHBOARD = 'RTO_DASHBOARD',
    REMITTANCE = 'REMITTANCE',
    INVOICE = 'INVOICE',
    PLUGIN = 'PLUGIN',
    SUPP_SERVICES = 'SUPP_SERVICES',
    PURCHASE_ORDER = 'PURCHASE_ORDER',
}

export type Pagination = {
    nextOrPrev: NextOrPrev;
    currentPageNumber: number;
    resultPerPage: number;
    isNextPresent: boolean;
    lastUpdatedAt?: any;
};

export type Customer = {
    customer_code: string;
    customer_hub_type: string;
    customer_id: string;
    customer_name: string;
};

export type ServiceDocument = {
    _id: string;
    vendor: string;
    country: string;
    document_name: string;
    document_link: string;
    is_optional?: boolean;
};

export type PhoneValidation = {
    _id: string,
    code: string,
    lengthRegex: RegExp,
};

export type Country = {
    country: string;
    phoneValidations: PhoneValidation[],
    pincode_regex: string,
    sample_pincode: string;
};

export type State = {
    country_code: string;
    country_name: string;
    is_active: boolean;
    state_code: string;
    state_name: string;
};

export type City = {
    city_code: string;
    city_name: string;
    city_type: string;
    country_code: string;
    country_name: string;
    is_active: boolean,
};

import Cross from '../../assets/cross';
import { Drawer } from 'antd';
import withStyles from 'react-jss';
import { StylesProps } from '../../theme/jss-types';
import { useTranslation } from 'react-i18next';
import React from 'react';
import i18n from 'i18n';
import { snakeToPretty } from 'library/lodash-helper';
import { pieceKeys, detailKeys, pieceDetailKeys } from './purchase-orders.constants';
import { purchaseDetailsStyles } from './purchase-orders.styles';

interface Props extends StylesProps<ReturnType<typeof purchaseDetailsStyles>> {
    onClose: () => void;
    data?: any;
}

const PurchaseDetails = (props: Props) => {
    const {
        classes,
        onClose,
        data,
    } = props;

    const { t } = useTranslation();
    const [viewMore, setViewMore] = React.useState<boolean[]>([]);
    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.addText}>
                    <Cross onClick={() => onClose()} alt="close" className={classes.closeIcon} />
                    <span>
                        {t('details')}
                    </span>
                </div>
            </div>
        );
    };

    const activePieces = data?.pieces_detail?.filter((piece: any) => piece.status === 'Open') || [];

    const renderPieceField = (idx: any, key: string | undefined) => {
        if (!key) return null;
        const piece = {
            label: key,
            value: activePieces[idx][key] ?? '-',
        };
        if (typeof piece.value === 'boolean') piece.value = piece.value ? 'Yes' : 'No';
        if (piece.label === 'reference_number') piece.label = 'piece_reference_number';
        return (
            <div className={classes.fieldGroup} key={piece.label}>
                <div className={classes.fieldTitle}>
                    {i18n.exists(piece.label) ? t(piece.label) : snakeToPretty(piece.label)}
                </div>
                <div className={classes.fieldValue}>
                    {piece.value}
                </div>
            </div>
        );
    };

    const toggleViewMore = (idx: number) => {
        const newViewMore = [...viewMore];
        newViewMore[idx] = !newViewMore[idx];
        setViewMore(newViewMore);
    };

    const renderPieceDetails = (piece: any, idx: number) => {
        return (
            <div className={classes.box}>
                <div>
                    <div style={{ padding: '5px 10px' }}>{`${data.is_international ? 'Box' : t('Piece')} ${idx + 1}`}</div>
                    <div
                        key={piece.id}
                    >
                        <div className={classes.hr} style={{ margin: '0 0 16px 0' }} />
                        <div className={classes.tabDetails}>
                            {
                                pieceKeys.map((key) => renderPieceField(idx, key))
                            }
                        </div>
                        {!viewMore[idx]
                            ? <div className={classes.view} onClick={() => toggleViewMore(idx)}>View More</div>
                            : null}
                        {viewMore[idx]
                            ? (
                                <>
                                    <div className={classes.tabDetails}>
                                        {
                                            pieceDetailKeys.map((key) => renderPieceField(idx, key))
                                        }
                                    </div>
                                    {viewMore[idx]
                                        ? <div className={classes.view} onClick={() => toggleViewMore(idx)}>View Less</div>
                                        : null}
                                </>
                            ) : null}
                    </div>
                </div>
            </div>
        );
    };

    const renderPair = (key: string) => {
        return (
            <div
                className={classes.pair}
            >
                {key === 'reference_number' ? <span className={classes.name}>PO#</span>
                    : <span className={classes.name}>{i18n.exists(key) ? t(key) : snakeToPretty(key)}</span>}
                <span className={classes.value}>{data[key] || '-'}</span>
            </div>
        );
    };

    return (
        <Drawer
            visible
            width="70%"
            title={renderHeader()}
            onClose={() => onClose()}
            className={classes.main}
            closable={false}
        >
            <div>
                <div style={{ margin: '40px 0 0 0', display: 'flex', flexWrap: 'wrap' }}>
                    {
                        detailKeys?.map((key: any) => {
                            return renderPair(key);
                        })
                    }
                </div>
                {
                    activePieces?.map((piece: any, idx: number) => {
                        return renderPieceDetails(piece, idx);
                    })
                }
            </div>
        </Drawer>
    );
};

const PurchaseOrderDetails = withStyles(purchaseDetailsStyles)(PurchaseDetails);
export default PurchaseOrderDetails;

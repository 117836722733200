import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { useTranslation } from 'react-i18next';
import {
    Col,
    Form,
    FormInstance,
    Input,
    Select,
} from 'antd';
import { commonStyleSheet } from 'library/common-styles';

const styles = (theme: ThemeType) => ({
    formItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        '& .ant-checkbox + span': {
            color: '#111111',
            fontSize: 14,
            fontWeight: 600,
        },
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    contentType: {
        ...commonStyleSheet(theme).flexRow,
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
    dimensions: {
        ...commonStyleSheet(theme).flexColumn,
        alignItems: 'left',
    },
    dimentionTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    params: any;
    form: FormInstance;
    isRenderedFromLayout: boolean | undefined;
}

const DeclaredValueField = (props: IProps) => {
    const { t } = useTranslation();
    const {
        classes,
        autoCalculateDetails,
        declaredCurrenciesList,
        params,
        form,
        documentSubLayout,
        envelopeSubLayout,
        isRenderedFromLayout,
    } = props;
    const {
        showDeclaredCurrencyField, isRequired, getValidationRules, getIsRequiredValidation,
    } = params;
    const {
        ConsignmentCategory,
        ItemType,
        DeclaredValue,
        DeclaredValueCurrency,
    } = formFields;

    const itemType = Form.useWatch(ItemType.key, form);
    const consignmentCategory = Form.useWatch(ConsignmentCategory.key, form);
    const { pieceIndex } = params;
    const isPieceLevel = pieceIndex !== null && pieceIndex !== undefined;

    const renderDeclaredCurrency = () => {
        if (isPieceLevel || !declaredCurrenciesList || declaredCurrenciesList.length === 0) {
            return null;
        }
        return (
            <Form.Item
                name={DeclaredValueCurrency.key}
                valuePropName={DeclaredValueCurrency.valuePropName}
                rules={getValidationRules(DeclaredValueCurrency, isPieceLevel)}
                initialValue={declaredCurrenciesList[0]}
            >
                <Select
                    options={declaredCurrenciesList.map((curr: string) => {
                        return {
                            value: curr,
                            label: curr,
                        };
                    })}
                    placeholder={DeclaredValueCurrency.placeholder}
                    dropdownMatchSelectWidth={false}
                    showSearch
                    onChange={(value) => {
                        form.setFieldsValue({
                            [isPieceLevel ? `${DeclaredValueCurrency.key}_${pieceIndex}` : DeclaredValueCurrency.key]: value,
                        });
                    }}
                />
            </Form.Item>
        );
    };

    if (!isRenderedFromLayout) {
        if (itemType === 'document' && !documentSubLayout.includes('declaredValue')) {
            return <></>;
        }
        if (itemType === 'envelope' && !envelopeSubLayout.includes('declaredValue')) {
            return <></>;
        }
    }

    return (
        <Col span={12}>
            <div className={classes.formItem}>
                <div
                    className={classes.title}
                >
                    {t(DeclaredValue.key)}
                    {isRequired(false, DeclaredValue.key)
                    || getIsRequiredValidation(DeclaredValue.key, isPieceLevel) ? '*' : null}
                </div>
                <div style={{ display: 'flex', height: '100%', width: '100%' }}>
                    <Form.Item
                        name={isPieceLevel ? `${DeclaredValue.key}_${pieceIndex}` : DeclaredValue.key}
                        className={classes.contentType}
                        valuePropName={DeclaredValue.valuePropName}
                        rules={getValidationRules(DeclaredValue, isPieceLevel)}
                        style={{ flexBasis: '100%' }}
                    >
                        <Input
                            type="number"
                            placeholder={t(DeclaredValue.key)}
                            disabled={consignmentCategory === 'international' && itemType === 'non-document'
                                && autoCalculateDetails}
                            // eslint-disable-next-line no-nested-ternary
                            // addonAfter={}
                            onChange={(e) => form.setFieldsValue({
                                [isPieceLevel ? `${DeclaredValue.key}_${pieceIndex}` : DeclaredValue.key]: e.target.value,
                            })}
                        />
                    </Form.Item>
                    {(showDeclaredCurrencyField
                        ? renderDeclaredCurrency()
                        : undefined)}
                </div>
            </div>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config, parts_to_show } = master;
    const customerPortalConfig = config?.customer_portal_config;
    const autoCalculateDetails = customerPortalConfig?.auto_calculate_single_cn_fields_international;
    const declaredCurrenciesList = parts_to_show?.declared_currency_list || ['INR'];
    const formSubLayout = config?.customer_portal_order_creation_config?.sub_layout || {};
    const documentSubLayout = formSubLayout?.document || [];
    const envelopeSubLayout = formSubLayout?.envelope || [];
    return {
        autoCalculateDetails,
        declaredCurrenciesList,
        documentSubLayout,
        envelopeSubLayout,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(DeclaredValueField);

import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { get, last } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
    Cascader,
    Col,
    Form,
    FormInstance,
} from 'antd';
import { commonStyleSheet } from 'library/common-styles';
import Helper from 'library/Helper';

const styles = (theme: ThemeType) => ({
    formItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        '& .ant-checkbox + span': {
            color: '#111111',
            fontSize: 14,
            fontWeight: 600,
        },
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    contentType: {
        ...commonStyleSheet(theme).flexRow,
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    params: any;
    form: FormInstance;
    shouldNotMakeItemTypeCheck: boolean;
}

const ContentTypeField = (props: IProps) => {
    const { t, i18n } = useTranslation();
    const {
        classes,
        form,
        params,
        useCategoryInCommodityData,
        consignmentConfig,
        shouldNotMakeItemTypeCheck,
    } = props;
    const {
        ContentTypes,
        ConsignmentCategory,
        ContentNameForOthers,
        ItemType,
    } = formFields;
    const {
        categoryList,
        contentList,
        isRequired,
        getValidationRules,
        getIsRequiredValidation,
    } = params;

    const filter = (inputValue: string, path: any) => {
        return path.some(
            (option: { label: string; }) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
        );
    };
    const consignmentCategory = Form.useWatch(ConsignmentCategory.key, form);
    const itemType = Form.useWatch(ItemType.key, form);
    const useCategory = useCategoryInCommodityData && consignmentCategory === 'domestic';
    const showCustomFields = consignmentConfig?.show_custom_fields;
    const customFields: Record<any, any> = {};
    if (consignmentConfig?.single_consignment_creation_fields) {
        (consignmentConfig?.single_consignment_creation_fields || {}).forEach((field: Record<any, any>) => {
            customFields[field.id] = field;
        });
    }

    if (!shouldNotMakeItemTypeCheck && itemType !== 'non-document') {
        return <></>;
    }

    if (consignmentConfig?.allow_multiple_piece_dimensions) {
        return <></>;
    }

    if (showCustomFields && !customFields.content_type) {
        return <></>;
    }

    const categories: {
        value: string;
        label: string;
        children: { value: string; label: string; }[],
    }[] = [];
    const captureOtherCommodityIdList: string[] = [];
    categoryList.forEach((category: any) => {
        const commodities: { value: string; label: string; }[] = [];
        category.commodityList?.forEach((item: any) => {
            commodities.push({
                value: item.COMMODITY_ID,
                label: item.COMMODITY_NAME,
            });
            if (item.captureActualCommodity) {
                captureOtherCommodityIdList.push(item.COMMODITY_ID as string);
            }
        });
        categories.push({
            value: category.categoryId,
            label: i18n.exists(category.categoryName.toUpperCase())
                ? t(category.categoryName.toUpperCase()) : category.categoryName,
            children: commodities,
        });
    });
    const optionList = useCategory ? categories : contentList.map((item: any) => {
        return {
            value: item.COMMODITY_ID,
            label: i18n.exists(item.COMMODITY_NAME.toUpperCase())
                ? t(item.COMMODITY_NAME.toUpperCase()) : item.COMMODITY_NAME,
        };
    });
    return (
        <Col span={12}>
            <div className={classes.formItem}>
                <div className={classes.title}>
                    {useCategory ? t('content_type_label_2') : t('content_type_label')}
                    {isRequired(false, ContentTypes.key) || getIsRequiredValidation(ContentTypes.key) ? '*' : ''}
                </div>
                <Form.Item
                    name={ContentTypes.key}
                    className={classes.contentType}
                    initialValue={ContentTypes.defaultValue}
                    valuePropName={ContentTypes.valuePropName}
                    rules={getValidationRules(ContentTypes)}
                >
                    <Cascader
                        placeholder={useCategory ? t('content_type_label_2') : t('content_type_label')}
                        showSearch={{ filter }}
                        allowClear
                        options={optionList}
                        onChange={(commodity) => {
                            const id = (Helper.isValidArray(commodity) ? last(commodity) : '') as string;
                            let isOtherReq = false;
                            if (useCategory) {
                                if (captureOtherCommodityIdList.includes(id)) {
                                    isOtherReq = true;
                                }
                            } else {
                                contentList?.forEach((content: any) => {
                                    if (content?.COMMODITY_ID === id && content?.captureActualCommodity) {
                                        isOtherReq = true;
                                    }
                                });
                            }
                            if (!isOtherReq) {
                                form.setFieldsValue({ [ContentNameForOthers.key]: undefined });
                            }
                        }}
                    />
                </Form.Item>
            </div>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config } = master;
    return {
        useCategoryInCommodityData: get(config, 'use_category_in_commodity_data', false),
        consignmentConfig: get(config, 'customer_portal_consignment_config', {}),
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(ContentTypeField);

/* eslint-disable max-len */
import React from 'react';
import { HocOptions } from './generic-hoc.types';
import GenericHoc from './generic-hoc';
import { Modal } from 'antd';
import { StylesProps, ThemeType } from '../../theme/jss-types';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const NewUIBannerStyles = (theme: ThemeType) => ({
    modal: {
        '& .ant-modal-content': {
            borderRadius: '12px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        },
    },
    title: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: `${theme.primaryColor} !important`,
    },
    checklistContainer: {
        textAlign: 'left',
        width: '100%',
        marginBottom: '16px',
    },
    checklistItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '8px',
        fontWeight: 600,
        '& span:first-child': {
            marginRight: '8px',
        },
    },
    infoBox: {
        backgroundColor: '#f5f7fa',
        borderLeft: `4px solid ${theme.primaryColor}`,
        padding: '12px 16px',
        width: '100%',
        color: 'black',
        fontWeight: 600,
    },
    closeButton: {
        fontWeight: 700,
        border: 'none',
        color: '#8c8c8c',
        cursor: 'pointer',
        alignContent: 'center',
        marginRight: '8px',
        '&:hover': {
            color: '#262626',
        },
    },
    footer: {
        justifyItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    actionButton: {
        backgroundColor: `${theme.primaryColor} !important`,
        color: '#ffffff',
        cursor: 'pointer',
        marginTop: '24px',
        padding: '10px 20px',
        borderRadius: '6px',
        textAlign: 'center',
        width: 'fit-content',
    },
    tickColor: {
        color: `${theme.primaryColor} !important`,
    },
    subHeading: {
        fontSize: '14px',
        marginBottom: '16px',
        fontWeight: 600,
    },
    hr: {
        width: '100%',
        height: 1,
        backgroundColor: '#eeeeee',
        margin: '16px 0px',
    },
});

interface NewUIBannerProps extends StylesProps<ReturnType<typeof NewUIBannerStyles>> {
    newUIBannerVisible: boolean;
    handleNewUIBannerVisible: () => void;
    handleNewUIBannerAcknowledge: () => void;
}

const NewUIBanner = (props: NewUIBannerProps) => {
    const { t } = useTranslation();
    const {
        classes, newUIBannerVisible, handleNewUIBannerVisible, handleNewUIBannerAcknowledge,
    } = props;

    const renderFooter = () => {
        return (
            <div className={classes.footer}>
                <div
                    className={classes.actionButton}
                    onClick={handleNewUIBannerAcknowledge}
                >
                    {t('looking_forward_to_it')}
                </div>
            </div>
        );
    };

    return (
        <Modal
            className={classes.modal}
            visible={newUIBannerVisible}
            centered
            closable={false}
            footer={null}
            width={550}
        >
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className={classes.title}>
                        🚀
                        {'  '}
                        {t('new_look_coming_soon')}
                    </div>
                    <div className={classes.closeButton} onClick={handleNewUIBannerVisible}><CloseOutlined /></div>
                </div>
                <div className={classes.hr} />
                <div className={classes.subHeading}>
                    {t('banner_first_line')}
                </div>
                <div className={classes.checklistContainer}>
                    <div className={classes.checklistItem}>
                        <span className={classes.tickColor}>✓</span>
                        {t('banner_second_line')}
                    </div>
                    <div className={classes.checklistItem}>
                        <span className={classes.tickColor}>✓</span>
                        {t('banner_third_line')}
                    </div>
                    <div className={classes.checklistItem}>
                        <span className={classes.tickColor}>✓</span>
                        {t('banner_fourth_line')}
                    </div>
                </div>

                <div className={classes.infoBox}>
                    {t('banner_fifth_line')}
                </div>
                {renderFooter()}
            </div>
        </Modal>
    );
};

const hocConfig: HocOptions = {
    connectRouter: true,
    connectJss: {
        useJss: true,
        styleSheet: NewUIBannerStyles,
    },
    connectRedux: {
        useRedux: true,
    },
    connectTranslession: true,
};

export default GenericHoc(hocConfig)(NewUIBanner);

import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import {
    Col,
    Form,
    Input,
} from 'antd';
import { formRules } from 'library/constants';
import { useTranslation } from 'react-i18next';
import { commonStyleSheet } from 'library/common-styles';

const styles = (theme: ThemeType) => ({
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
    },
    referenceItemEway: {
        display: 'flex',
        flexDirection: 'column',
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    formItem: {
        width: '100%',
    },
    titleEway: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
    },
    contentType: {
        ...commonStyleSheet(theme).flexRow,
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    params: any;
}

const EwayBillInvoiceAmountField = (props: IProps) => {
    const { t, i18n } = useTranslation();
    const {
        classes,
        params,
    } = props;
    const {
        InvoiceAmount,
    } = formFields;

    const { getValidationRules, getIsRequiredValidation } = params;
    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };

    return (
        <Col span={12}>
            <div className={classes.referenceItemEway}>
                <div className={classes.titleEway}>
                    {InvoiceAmount.label}
                    {getIsRequiredValidation(InvoiceAmount.key) && '*'}
                </div>
                <Form.Item
                    name={InvoiceAmount.key}
                    className={classes.contentType}
                    initialValue={InvoiceAmount.defaultValue}
                    valuePropName={InvoiceAmount.valuePropName}
                    rules={[formRuleRequired, ...getValidationRules(InvoiceAmount)]}
                >
                    <Input
                        type={InvoiceAmount.type}
                        placeholder={InvoiceAmount.placeholder}
                    />
                </Form.Item>
            </div>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config } = master;
    return {
        ...config,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(EwayBillInvoiceAmountField);

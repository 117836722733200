import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { useTranslation } from 'react-i18next';
import {
    Col,
    Form,
    FormInstance,
    Input,
} from 'antd';
import { commonStyleSheet } from 'library/common-styles';
// import { set } from 'lodash';
// import Helper from 'library/Helper';

// interface PieceDetails {
//     key: any;
//     height?: any;
//     length?: any;
//     numberOfPieces?: any;
//     unit?: any;
//     weight?: any;
//     weightUnit?: any;
//     dimensionsUnit?: any;
//     width?: any;
//     declaredValue?: any;
//     description?: any;
//     items?: Record<any, any>;
//     codAmount?: any;
//     reference_number?: any;
// }

// interface PieceTotals {
//     totalWeight: number;
//     totalVolume: number;
// }

const styles = (theme: ThemeType) => ({
    formItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        '& .ant-checkbox + span': {
            color: '#111111',
            fontSize: 14,
            fontWeight: 600,
        },
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    contentType: {
        ...commonStyleSheet(theme).flexRow,
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    params: any;
    form: FormInstance;
    isRenderedFromLayout: boolean | undefined;
}

const NumPiecesField = (props: IProps) => {
    const { t } = useTranslation();
    const {
        classes,
        params,
        form,
        pieceSubLayout,
        documentSubLayout,
        envelopeSubLayout,
        isRenderedFromLayout,
    } = props;
    const {
        NumPieces,
        ItemType,
    } = formFields;
    const {
        pieceIndex,
        isRequired,
        placeholderNumPieces,
        isDisabledNumPiece,
        getValidationRules,
        getIsRequiredValidation,
    } = params;
    const isPieceLevel = pieceIndex !== null && pieceIndex !== undefined;

    const itemType = Form.useWatch(ItemType.key, form);

    if (!isRenderedFromLayout) {
        if (itemType === 'document' && !documentSubLayout.includes('numberOfPieces')) {
            return <></>;
        }
        if (itemType === 'envelope' && !envelopeSubLayout.includes('numberOfPieces')) {
            return <></>;
        }
        if ((!itemType || itemType === 'non-document') && !pieceSubLayout.includes('numberOfPieces')) {
            return <></>;
        }
    }

    return (
        <Col span={12}>
            <div className={classes.formItem}>
                <div className={classes.title}>
                    {isPieceLevel ? t('num_pieces_label_2') : t('num_pieces_label')}
                    {isRequired(isPieceLevel, NumPieces.key)
                        || getIsRequiredValidation(NumPieces.key, isPieceLevel) ? '*' : ''}
                </div>
                <Form.Item
                    name={isPieceLevel ? ['pieces', pieceIndex, NumPieces.key] : NumPieces.key}
                    className={classes.contentType}
                    initialValue={NumPieces.defaultValue}
                    valuePropName={NumPieces.valuePropName}
                    rules={getValidationRules(NumPieces, isPieceLevel, { MAX_LIMIT: 1000 })}
                >
                    <Input
                        type="number"
                        min={0}
                        max={1000}
                        placeholder={placeholderNumPieces()}
                        style={{ width: NumPieces.width }}
                        disabled={
                            isDisabledNumPiece()
                        }
                    />
                </Form.Item>
            </div>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config } = master;
    const formSubLayout = config?.customer_portal_order_creation_config?.sub_layout || {};
    const documentSubLayout = formSubLayout?.document || [];
    const pieceSubLayout = formSubLayout?.non_document || [];
    const envelopeSubLayout = formSubLayout?.envelope || [];
    return {
        pieceSubLayout,
        documentSubLayout,
        envelopeSubLayout,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(NumPiecesField);

import Cross from '../../assets/cross';
import { Button, Drawer, Input } from 'antd';
import * as React from 'react';
import withStyles from 'react-jss';
import FallbackPage from '../common/fallback';
import Loader from '../common/Loader';
import { Pincodes, Service } from '../../library/Types';
import { getLocationServiceabilityTat } from '../../network/common.api';
import { StylesProps } from '../../theme/jss-types';
import { serviceAvailabilityStyles } from './service-availability.styles';
import { useTranslation } from 'react-i18next';

interface PincodeServiceProps extends StylesProps<ReturnType<typeof serviceAvailabilityStyles>> {
    onClose: () => void;
    pincodes: Pincodes;
    filterServicesWithoutTat?: boolean;

}

const PincodeService = (props: PincodeServiceProps) => {
    const {
        classes,
        onClose,
        pincodes,
        filterServicesWithoutTat,
    } = props;
    const { t } = useTranslation();
    const [pincodeValues, setPincodeValues] = React.useState<Pincodes>(pincodes);
    const [services, setService] = React.useState<Service[]>([]);
    const [currentPincodes, setCurrentPins] = React.useState<Pincodes>({});
    const [loading, setLoading] = React.useState<boolean>(false);
    const [toggle, setToggle] = React.useState<boolean>(true);

    const loadServiceabilityTat = async () => {
        setLoading(true);
        const storage = window.localStorage;
        setCurrentPins(pincodeValues);
        const response = await getLocationServiceabilityTat({
            srcAddress: {
                pincode: pincodeValues?.srcPincode,
            },
            dstAddress: {
                pincode: pincodeValues?.dstPincode,
            },
            clientCode: storage.getItem('userCode'),
            filterServicesWithoutTat,
        });
        setService(response?.data || []);
        setLoading(false);
    };

    React.useEffect(() => {
        const {
            srcPincode,
            dstPincode,
        } = pincodeValues;

        if (
            dstPincode?.length !== 6 || srcPincode?.length !== 6
        ) {
            return;
        }
        loadServiceabilityTat();
    }, [toggle]);

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.addText}>
                    <Cross onClick={() => onClose()} alt="close" className={classes.closeIcon} />
                    <span>
                        {t('pincode_serviceability')}
                    </span>
                </div>
            </div>
        );
    };

    const disableButton = () => {
        return (
            pincodeValues.dstPincode?.length !== 6
      || pincodeValues.srcPincode?.length !== 6
        );
    };

    const renderPincodeInputs = () => {
        const disable = disableButton();
        const handleChange = (field: 'srcPincode' | 'dstPincode', value: string) => {
            const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '');
            setPincodeValues((prev) => ({ ...prev, [field]: sanitizedValue }));
        };
        return (
            <div className={classes.inputs}>
                <Input
                    placeholder={t('origin_pin')}
                    className={classes.input}
                    value={pincodeValues.srcPincode}
                    onChange={(e) => handleChange('srcPincode', e.target.value)}
                />
                <Input
                    placeholder={t('destination_pin')}
                    className={classes.input}
                    value={pincodeValues.dstPincode}
                    onChange={(e) => handleChange('dstPincode', e.target.value)}
                />
                <Button
                    type="primary"
                    disabled={disable}
                    onClick={() => setToggle(!toggle)}
                >
                    {t('check')}
                </Button>
            </div>
        );
    };

    const renderPincodePair = (config: any) => {
        return (
            <div className={classes.nameValuePair}>
                <span className={classes.name}>{config.name}</span>
                <span className={classes.value}>{config.value}</span>
            </div>
        );
    };

    const renderServiceTat = (config: any) => {
        return (
            <div className={classes.serviceableTatPair}>
                <span className={classes.name}>{config?.name}</span>
                {config.value && (
                    <span className={classes.value}>
                        {`${config?.value} days`}
                    </span>
                )}
            </div>
        );
    };

    const renderLine = () => {
        return <div className={classes.hr} />;
    };

    const renderAvailabilityText = () => {
        return (
            <div
                className={classes.availableText}
            >
                {t('services_available_for')}
            </div>
        );
    };

    const renderPincodes = () => {
        if (!currentPincodes.srcPincode || !currentPincodes.dstPincode) {
            return null;
        }
        return (
            <div className={classes.flexRow}>
                {renderPincodePair({ name: currentPincodes.srcPincode, value: t('pickup_pincode') })}
                {renderPincodePair({ name: currentPincodes.dstPincode, value: t('delivery_pincode') })}
            </div>
        );
    };

    const renderServices = () => {
        if (loading) {
            return <Loader zIndex={5} />;
        }
        if (!services.length) {
            return (
                <FallbackPage
                    errorMsg={t('pincode_serviceability_error')}
                />
            );
        }
        return (
            <div className={classes.services}>
                {services.map((service) => {
                    return renderServiceTat({
                        name: `${service?.serviceType} (${service?.serviceTypeId})`,
                        value: service?.tat,
                    });
                })}
            </div>
        );
    };

    const renderBody = () => {
        return (
            <div className={classes.body}>
                {renderAvailabilityText()}
                {renderPincodes()}
            </div>
        );
    };

    return (
        <Drawer
            visible
            width="35%"
            title={renderHeader()}
            onClose={() => onClose()}
            className={classes.main}
            closable={false}
        >
            {renderPincodeInputs()}
            {renderBody()}
            {renderLine()}
            {renderServices()}
        </Drawer>
    );
};

export default withStyles(serviceAvailabilityStyles)(PincodeService);

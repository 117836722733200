
const getCurrentCustomerId = () => {
    const storage = window.localStorage;
    const userId = storage.getItem('userId');
    const childUserId = storage.getItem('childCustomerId');
    const isChildCustomer = storage.getItem('isChildCustomer');

    return isChildCustomer ? childUserId : userId;
};

export const getCurrentCustomer = (children_list: any) => {
    const currentCustomerId = getCurrentCustomerId();
    return [children_list.find((child: any) => child.customer_id === currentCustomerId)];
};

export const convertCentimeterVolumeWithUnit = (volume: number, targetUnit: string) => {
    const centimeterDivider: Record<string, number> = {
        cm: 1,
        inch: 2.54,
        ft: 30.48,
        mtr: 100,
    };

    const volumeDivider: number = centimeterDivider[targetUnit] ** 3;

    const convertedVolume = volume / volumeDivider;

    return convertedVolume;
};


import { Button, Modal, Select } from 'antd';
import CrossIcon from 'assets/cross';
import * as React from 'react';
import { StylesProps, ThemeType } from 'theme/jss-types';
import GenericHoc from './generic-hoc';
import { HocOptions } from './generic-hoc.types';
import { useTranslation } from 'react-i18next';
import { changeCustomerUser, searchCustomers } from 'network/common.api';
import Loader from './Loader';
import { getDefaultLandingRoute } from 'routing/routing-helper';

const styles = (theme: ThemeType) => ({
    main: {
        borderRadius: 8,
        '& .ant-select-selector, .ant-select:not(.ant-select-customize-input) .ant-select-selector': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            background: 'inherit',
            width: '100%',
        },
    },
    header: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 0,
    },
    addText: {
        display: 'flex',
        alignItems: 'center',
        color: '#000000',
        fontWeight: 600,
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontSize: 20,
    },
    closeIcon: {
        marginRight: 8,
        cursor: 'pointer',
        color: '#000000',
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: 16,
    },
    newButton: {
        height: 32,
        fontSize: 12,
        backgroundColor: theme.primaryColor,
        border: `1px solid ${theme.primaryColor}`,
        color: '#FFFFFF',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        letterSpacing: 0,
        padding: '0px 24px',
        borderRadius: 4,
        marginLeft: 12,
    },
    title: {
        fontFamily: 'Open Sans',
        fontSize: '12px',
        fontWeight: 400,
        letterSpacing: 0,
        textAlign: 'left',
        marginBottom: 4,
    },
    option: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
});

interface ChangeCustomerProps extends StylesProps<ReturnType<typeof styles>> {
    onClose: () => void;
}

const ChangeCustomer = (props: ChangeCustomerProps) => {
    const { t } = useTranslation();
    const { classes, onClose } = props;

    const [saving, setSaving] = React.useState(false);
    const [selectedCustomer, setSelectedCustomer] = React.useState<string | undefined>();
    const [loading, setLoading] = React.useState(false);
    const [customerList, setCustomerList] = React.useState([]);

    const saveLoginResult = async (result: any) => {
        const storage = window.localStorage;
        const defaultLandingUrl = result.data?.config?.default_landing_url || '/consignments';
        storage.setItem('userId', result.data.Customer.id);
        storage.setItem('authToken', result.data.access_token.id);
        storage.setItem('username', result.data.Customer.name);
        storage.setItem('userCode', result.data.Customer.code);
        storage.setItem('childCustomerId', result.data.Customer.id);
        storage.setItem('childCustomerCode', result.data.Customer.code);
        storage.setItem('childAccessToken', result.data.access_token.id);
        storage.setItem('isChildCustomer', 'false');
        storage.setItem('parentId', result.data.Customer.parent_id || null);
        storage.setItem('defaultLandingUrl', defaultLandingUrl);
        storage.setItem('customerUserId', result?.data?.CustomerUserDetails?.id);
        storage.setItem('childCustomerUserId', result?.data?.CustomerUserDetails?.id);
    };

    const changeCustomer = async () => {
        setSaving(true);
        const result = await changeCustomerUser({
            customer_code: selectedCustomer,
            customer_access_token: localStorage.getItem('authToken'),
        });

        saveLoginResult(result);
        setSaving(false);
        window.location.href = getDefaultLandingRoute();
        onClose();
    };

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.addText}>
                    <CrossIcon
                        onClick={() => onClose()}
                        alt="close"
                        className={classes.closeIcon}
                    />
                    <span>
                        {t('change_customer')}
                    </span>
                </div>
                <Button
                    type="primary"
                    disabled={loading || !selectedCustomer}
                    loading={saving}
                    onClick={() => changeCustomer()}
                    className={classes.newButton}
                >
                    {t('submit')}
                </Button>
            </div>
        );
    };

    let timeout: any = null;
    const fetchCustomerList = async (searchString: string) => {
        if (!searchString || searchString?.length < 1) {
            return;
        }
        setLoading(true);

        if (timeout) {
            timeout = null;
        }
        timeout = setTimeout(async () => {
            const result = await searchCustomers({
                search: searchString,
            });
            setCustomerList(result?.data || []);
            setLoading(false);
        }, 500);
    };

    const renderCustomerChange = () => {
        return (
            <Select
                showSearch
                placeholder={t('customer_code')}
                onChange={(value: string) => {
                    setSelectedCustomer(value);
                }}
                onSearch={fetchCustomerList}
                style={{ width: '100%' }}
                notFoundContent={loading ? <Loader zIndex={5} /> : null}
            >
                {customerList.map((item: any) => {
                    return (
                        <Select.Option
                            value={item.code}
                            key={item.code}
                        >
                            <div className={classes.option}>
                                <span>
                                    {item.code}
                                </span>
                                <span>
                                    (
                                    {item.name}
                                    )
                                </span>
                            </div>
                        </Select.Option>
                    );
                })}
            </Select>
        );
    };

    return (
        <Modal
            visible
            title={renderHeader()}
            closable={false}
            footer={null}
            className={classes.main}
        >
            <div className={classes.title}>
                {t('customer_code')}
            </div>
            {renderCustomerChange()}
        </Modal>
    );
};

const hocConfig: HocOptions = {
    connectJss: {
        styleSheet: styles,
        useJss: true,
    },
};

export default GenericHoc(hocConfig)(ChangeCustomer);

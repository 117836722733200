import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { useTranslation } from 'react-i18next';
import {
    Col,
    Form,
    FormInstance,
    Input,
    Select,
} from 'antd';
import { commonStyleSheet } from 'library/common-styles';
import { formRules } from 'library/constants';
import { FormField, InputTypes } from 'components/create-consignment/create-consignment.types';

const styles = (theme: ThemeType) => ({
    formItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        '& .ant-checkbox + span': {
            color: '#111111',
            fontSize: 14,
            fontWeight: 600,
        },
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    contentType: {
        ...commonStyleSheet(theme).flexRow,
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
    formItemWeight: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    params: any;
    form: FormInstance;
    shouldNotMakeItemTypeCheck: boolean;
    isRenderedFromLayout: boolean | undefined;
}

const WeightField = (props: IProps) => {
    const { t, i18n } = useTranslation();
    const {
        classes,
        params,
        autoCalculateDetails,
        form,
        pieceSubLayout,
        envelopeSubLayout,
        documentSubLayout,
        isRenderedFromLayout,
    } = props;
    let {
        shouldNotMakeItemTypeCheck,
    } = props;
    const {
        WeightUnit,
        ConsignmentCategory,
        Weight,
        ItemType,
    } = formFields;
    const {
        id: pieceIndex, isRequired, getValidationRules, getIsRequiredValidation,
    } = params;

    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };

    const consignmentCategory = Form.useWatch(ConsignmentCategory.key, form);
    const itemType = Form.useWatch(ItemType.key, form);
    const isPieceLevel = pieceIndex !== null && pieceIndex !== undefined;
    shouldNotMakeItemTypeCheck = shouldNotMakeItemTypeCheck || false;

    if (!isRenderedFromLayout) {
        if (itemType === 'document' && !documentSubLayout.includes('weight') && !isPieceLevel) {
            return <></>;
        }
        if (itemType === 'envelope' && !envelopeSubLayout.includes('weight') && !isPieceLevel) {
            return <></>;
        }
        if ((!itemType || itemType === 'non-document') && !pieceSubLayout.includes('weight') && !isPieceLevel) {
            return <></>;
        }
    }

    const shouldDisable = () => {
        if (shouldNotMakeItemTypeCheck) {
            return !isPieceLevel && consignmentCategory === 'international' && autoCalculateDetails;
        }

        return !isPieceLevel && consignmentCategory === 'international'
            && itemType === 'non-document' && autoCalculateDetails;
    };

    const renderInput = (field: FormField, type: string, fieldName: string | undefined) => {
        return (
            <Form.Item
                name={fieldName}
                className={classes.contentType}
                style={{ flex: 1 }}
                initialValue={Weight.defaultValue}
                valuePropName={Weight.valuePropName}
                rules={getValidationRules(field, isPieceLevel)}
            >
                <Input
                    type="number"
                    min={0}
                    placeholder={t(Weight.key)}
                    style={{ width: '100%', flex: 1 }}
                    disabled={shouldDisable()}
                />
            </Form.Item>
        );
    };

    const renderSelect = (field: FormField, fieldName: string | undefined) => {
        if (Array.isArray(field.options) && field.options.length > 0) {
            field.options.forEach((item: any) => {
                /* eslint-disable no-param-reassign */
                item.label = i18n.exists(item.value) ? t(item.value) : item.label;
            });
        }
        return (
            <Form.Item
                name={fieldName}
                key={WeightUnit.key}
                initialValue={WeightUnit.defaultValue}
                rules={isRequired(isPieceLevel, WeightUnit.key) || getIsRequiredValidation(WeightUnit.key, isPieceLevel)
                    ? [formRuleRequired] : undefined}
                className={classes.contentType}
            >
                <Select
                    placeholder={WeightUnit.placeholder}
                    options={WeightUnit.options}
                    dropdownMatchSelectWidth={false}
                    disabled={shouldDisable()}
                >
                    {WeightUnit.options?.map((item: any) => {
                        return (
                            <Select.Option
                                key={item.value}
                                value={item.value}
                            >
                                {item.label}
                            </Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
        );
    };

    const renderFormItem = (
        field: FormField,
        type: string = 'text',
        fieldName: string | undefined = undefined,
    ) => {
        switch (field.type) {
            case InputTypes.Input: return renderInput(field, type, fieldName);
            case InputTypes.Select: return renderSelect(field, fieldName);
            default: return 'Input is not defined';
        }
    };

    const id = isPieceLevel ? `_${pieceIndex}` : '';
    return (
        <Col span={12}>
            <div className={classes.formItemWeight}>
                <div className={classes.title}>
                    {t(Weight.key)}
                    {isRequired(isPieceLevel, Weight.key) || getIsRequiredValidation(Weight.key, isPieceLevel) ? '*' : null}
                </div>
                <div style={{
                    display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'baseline',
                }}
                >
                    {renderFormItem(Weight, 'number', `weight${id}`)}
                    {renderFormItem(WeightUnit, 'string', `weightUnit${id}`)}
                </div>
            </div>
        </Col>
    );
    // );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config } = master;
    const customerPortalConfig = config?.customer_portal_config;
    const autoCalculateDetails = customerPortalConfig?.auto_calculate_single_cn_fields_international;
    const formSubLayout = config?.customer_portal_order_creation_config?.sub_layout || {};
    const documentSubLayout = formSubLayout?.document || [];
    const pieceSubLayout = formSubLayout?.non_document || [];
    const envelopeSubLayout = formSubLayout?.envelope || [];
    return {
        autoCalculateDetails,
        pieceSubLayout,
        documentSubLayout,
        envelopeSubLayout,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(WeightField);

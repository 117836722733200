import moment from 'moment';
import { CurrentPage, FormField, InputTypes } from './create-consignment.types';
import Helper from 'library/Helper';
import PrimaryIcon from './single-consignment-icons/primary';
import ContentsIcon from './single-consignment-icons/content';
import AddressIcon from './single-consignment-icons/address';
import ServicesIcon from './single-consignment-icons/services';
import PaymentIcon from './single-consignment-icons/payment';

export enum CreateActions {
    SingleConsignment = 'SingleConsignment',
    BulkConsignment = 'BulkConsignment',
    BulkPickupSlot = 'BulkPickupSlot',
    SinglePickup = 'SinglePickup',
    BulkPickup = 'BulkPickup',
    SingleHyperlocal = 'SingleHyperlocal',
    BulkHyperlocal = 'BulkHyperlocal',
    PincodeAvailability = 'PincodeAvailability',
    Track = 'Track',
    FTLHyperlocal = 'FTLHyperlocal',
}
export const SECTION_NAMES = {
    SCHEDULE_DETAILS: 'Schedule Details',
};
export const initialState = {
    currentPage: CurrentPage.FIRST,
    visited: {
        FIRST: true,
        SECOND: false,
        THIRD: false,
        FOUR: false,
        FIFTH: false,
    },
};

export const editInitialState = {
    currentPage: CurrentPage.FIRST,
    visited: {
        FIRST: true,
        SECOND: true,
        THIRD: true,
        FOUR: true,
        FIFTH: true,
    },
};

export const ButtonNames = {
    PREV: 'previous',
    NEXT: 'next',
    SUBMIT: 'submit',
    SAVE: 'save',
};

export const TabNames = {
    FIRST: 'primary_details',
    SECOND: 'consignee_details',
    THIRD: 'consignor_details',
    FOUR: 'service',
    FIFTH: 'payment_details',
    SUMMARY: 'summary',
};

export const TabDescription = {
    FIRST: 'Enter the consignment details, value & dimensions',
    SECOND: 'Select or create the address of consignee',
    THIRD: 'Select or create the address of consignor',
    FOUR: 'Enter the details of service & pickup schedule',
    FIFTH: 'Enter the payment details & mode of payment',
};

export const formFields: Record<string, FormField> = {
    ConsignmentCategory: {
        label: 'Consignment Category',
        type: InputTypes.Radio,
        key: 'consignmentCategory',
        defaultValue: 'domestic',
        valuePropName: 'value',
        direction: 'column',
        options: [{
            label: 'Domestic',
            value: 'domestic',
        },
        {
            label: 'International',
            value: 'international',
        }],
    },
    VehicleCategory: {
        label: 'Vehicle Category',
        placeholder: 'Select Vehicle Category',
        type: InputTypes.Select,
        key: 'vehicleCategory',
        valuePropName: 'value',
        width: 250,
    },
    SubAccount: {
        label: 'Account',
        placeholder: 'Select Account',
        type: InputTypes.Select,
        key: 'childClient',
        valuePropName: 'value',
        width: 250,
    },
    // this is actual obj for movement type, MovementType is being used mistakenly for consignmentType
    ActualMovementType: {
        label: 'Movement Type',
        type: InputTypes.Radio,
        direction: 'column',
        key: 'movementType',
        defaultValue: 'forward',
        valuePropName: 'value',
        options: [{
            label: 'Forward',
            value: 'forward',
        }, {
            label: 'RTO',
            value: 'rto',
        }, {
            label: 'RTV',
            value: 'rtv',
        }, {
            label: 'RTS',
            value: 'rts',
        }, {
            label: 'RVP',
            value: 'rvp',
        }],
    },
    MovementType: {
        label: 'Consignment Type',
        type: InputTypes.Radio,
        direction: 'column',
        key: 'consignmentType',
        defaultValue: 'forward',
        defaultValue2: 'ftlhyperlocal',
        valuePropName: 'value',
        options: [{
            label: 'Forward',
            value: 'forward',
        }, {
            label: 'FTL Hyperlocal',
            value: 'ftlhyperlocal',
        }, {
            label: 'Reverse',
            value: 'reverse',
        }, {
            label: 'Hyperlocal',
            value: 'hyperlocal',
        }],
    },
    EwayBillType: {
        label: 'Please Enter E-Way Bill Details, as the declared value of goods exceeds INR 50000',
        type: InputTypes.Radio,
        direction: 'column',
        key: 'type',
        defaultValue: 'ewaybill_number',
        valuePropName: 'value',
        options: [{
            label: 'Enter E-waybill No',
            value: 'ewaybill_number',
        }, {
            label: 'Both consignor and consignee are unregistered in GSTN and the declaration is available',
            value: 'no_gstn',
        }, {
            label: 'Continue Without E-waybill No',
            value: 'ewaybill_number_not_available',
        }, {
            label: 'Input Details for Declaration',
            value: 'ewaybill_details',
        }],
    },
    EwayBillNumber: {
        label: 'Eway Bill Number *',
        type: InputTypes.Input,
        key: 'ewbNumber',
        valuePropName: 'value',
        placeholder: 'Eway Bill No.*',
        width: 250,
    },
    ApproxDistance: {
        label: 'Approx Distance *',
        type: InputTypes.Input,
        key: 'transDistance',
        valuePropName: 'value',
        placeholder: 'Distance in KMs',
        width: 210,
    },
    DocumentNumber: {
        label: 'Document Number *',
        type: InputTypes.Input,
        key: 'invoiceNumber',
        valuePropName: 'value',
        placeholder: 'Document Number',
        width: 150,
    },
    InvoiceAmount: {
        label: 'Invoice Amount',
        type: InputTypes.Number,
        key: 'invoiceAmount',
        valuePropName: 'value',
        placeholder: 'Invoice Amount',
        width: 150,
    },
    CustomsAmount: {
        label: 'Customs Amount',
        type: InputTypes.Number,
        key: 'customsAmount',
        valuePropName: 'value',
        placeholder: 'Customs Amount',
        width: 150,
    },
    ImportReferenceNumber: {
        label: 'Import Reference Number',
        type: InputTypes.Input,
        key: 'importReferenceNumber',
        valuePropName: 'value',
        placeholder: 'Import Reference Number',
        width: 150,
    },
    ExportReferenceNumber: {
        label: 'Export Reference Number',
        type: InputTypes.Input,
        key: 'exportReferenceNumber',
        valuePropName: 'value',
        placeholder: 'Export Reference Number',
        width: 150,
    },
    purpose: {
        label: 'Purpose',
        type: InputTypes.Input,
        key: 'purpose',
        valuePropName: 'value',
        placeholder: 'Purpose',
        width: 150,
    },
    IsCustomsDeclarable: {
        label: 'Is Customs Declarable?',
        type: InputTypes.Radio,
        key: 'isCustomsDeclarable',
        direction: 'column',
        defaultValue: 'false',
        valuePropName: 'value',
        options: [{
            label: 'Yes',
            value: 'true',
        }, {
            label: 'No',
            value: 'false',
        }],
    },
    SenderName: {
        label: 'Name',
        type: InputTypes.Input,
        key: 'sendername',
        valuePropName: 'value',
        placeholder: 'XXXX',
        width: 300,
    },
    SenderAddressLineOne: {
        label: 'Address Line 1',
        type: InputTypes.Input,
        key: 'senderaddressLine1',
        valuePropName: 'value',
        placeholder: 'XXXX',
        width: 300,
    },
    SenderAddressLineTwo: {
        label: 'Address Line 2',
        type: InputTypes.Input,
        key: 'senderaddressLine2',
        valuePropName: 'value',
        placeholder: 'XXXX',
        width: 300,
    },
    SenderPincode: {
        label: 'PIN Code',
        type: InputTypes.Input,
        key: 'senderpincode',
        valuePropName: 'value',
        placeholder: 'XXXX',
        width: 100,
    },
    SenderCity: {
        label: 'City',
        type: InputTypes.Input,
        key: 'sendercityName',
        valuePropName: 'value',
        placeholder: 'XXXX',
        width: 190,
    },
    SenderState: {
        label: 'State',
        type: InputTypes.Input,
        key: 'senderstateName',
        valuePropName: 'value',
        placeholder: 'XXXX',
        width: 150,
    },
    SenderCountry: {
        label: 'Country',
        type: InputTypes.Input,
        key: 'sendercountryName',
        valuePropName: 'value',
        placeholder: 'XXXX',
        width: 150,
    },
    SenderPhoneNo: {
        label: 'Mobile Number',
        type: InputTypes.Input,
        key: 'senderphone',
        valuePropName: 'value',
        placeholder: 'XXXX',
        width: 200,
    },
    RecieverName: {
        label: 'Name',
        type: InputTypes.Input,
        key: 'recievername',
        valuePropName: 'value',
        placeholder: 'XXXX',
        width: 300,
    },
    RecieverAddressLineOne: {
        label: 'Address Line 1',
        type: InputTypes.Input,
        key: 'recieveraddressLine1',
        valuePropName: 'value',
        placeholder: 'XXXX',
        width: 300,
    },
    RecieverAddressLineTwo: {
        label: 'Address Line 2',
        type: InputTypes.Input,
        key: 'recieveraddressLine2',
        valuePropName: 'value',
        placeholder: 'XXXX',
        width: 300,
    },
    RecieverPincode: {
        label: 'PIN Code',
        type: InputTypes.Input,
        key: 'recieverpincode',
        valuePropName: 'value',
        placeholder: 'XXXX',
        width: 100,
    },
    RecieverCity: {
        label: 'City',
        type: InputTypes.Input,
        key: 'recievercityName',
        valuePropName: 'value',
        placeholder: 'XXXX',
        width: 190,
    },
    RecieverState: {
        label: 'State',
        type: InputTypes.Input,
        key: 'recieverstateName',
        valuePropName: 'value',
        placeholder: 'XXXX',
        width: 150,
    },
    RecieverCountry: {
        label: 'Country',
        type: InputTypes.Input,
        key: 'recievercountryName',
        valuePropName: 'value',
        placeholder: 'XXXX',
        width: 150,
    },
    RecieverPhoneNo: {
        label: 'Mobile Number',
        type: InputTypes.Input,
        key: 'recieverphone',
        valuePropName: 'value',
        placeholder: 'XXXX',
        width: 200,
    },
    SenderGSTIN: {
        label: 'Senders GSTIN *',
        type: InputTypes.Input,
        key: 'senderGstin',
        valuePropName: 'value',
        placeholder: 'XXXX',
        width: 200,
    },
    RecieverGSTIN: {
        label: 'Recievers GSTIN *',
        type: InputTypes.Input,
        key: 'receiverGstin',
        valuePropName: 'value',
        placeholder: 'XXXX',
        width: 200,
    },
    ProductName: {
        label: 'Product Name',
        type: InputTypes.Input,
        key: 'prodName',
        valuePropName: 'value',
        placeholder: 'Enter Name',
        width: 150,
    },
    HsnValue: {
        label: 'HSN *',
        type: InputTypes.Input,
        key: 'hsnCode',
        valuePropName: 'value',
        placeholder: 'Enter Value',
        width: 150,
        validate: true,
    },
    ProductDescription: {
        label: 'Description',
        type: InputTypes.Input,
        key: 'description',
        valuePropName: 'value',
        placeholder: 'Type Description (Max 250 Words)',
        width: 640,
    },
    ProductQuantity: {
        label: 'Quantity',
        type: InputTypes.Input,
        key: 'quantity',
        valuePropName: 'value',
        placeholder: 'Enter Value',
        width: 150,
    },
    noOfItems: {
        label: 'Quantity',
        placeholder: 'Quantity',
        type: InputTypes.Number,
        key: 'numberOfItems',
        valuePropName: 'value',
        width: 150,
    },
    ProductUnit: {
        label: 'Unit',
        type: InputTypes.Input,
        key: 'unit',
        valuePropName: 'value',
        placeholder: 'Enter Value',
        width: 150,
    },
    ProductInvoiceNumber: {
        label: 'Invoice Number',
        type: InputTypes.Input,
        key: 'invoiceNumber',
        valuePropName: 'value',
        placeholder: 'Invoice Number',
        width: 150,
    },
    ProductValue: {
        label: 'Value/Taxable Value',
        type: InputTypes.Input,
        key: 'totalValue',
        valuePropName: 'value',
        placeholder: '60000',
        width: 150,
        validate: true,
    },
    ProductCGST: {
        label: 'CGST*',
        type: InputTypes.Input,
        key: 'cgstValue',
        valuePropName: 'value',
        placeholder: 'Enter Value',
        width: 150,
        validate: true,
    },
    ProductSGST: {
        label: 'SGST*',
        type: InputTypes.Input,
        key: 'sgstValue',
        valuePropName: 'value',
        placeholder: 'Enter Value',
        width: 150,
        validate: true,
    },
    ProductIGST: {
        label: 'IGST*',
        type: InputTypes.Input,
        key: 'igstValue',
        valuePropName: 'value',
        placeholder: 'Enter Value',
        width: 150,
        validate: true,
    },
    ProductCESS: {
        label: 'CESS*',
        type: InputTypes.Input,
        key: 'cessValue',
        valuePropName: 'value',
        placeholder: 'Enter Value',
        width: 150,
        validate: true,
    },
    RetailTransaction: {
        label: 'Retail Transaction',
        type: InputTypes.Radio,
        direction: 'column',
        key: 'retailTransactionType',
        defaultValue: 'retailTransactionFalse',
        valuePropName: 'value',
        options: [{
            label: 'Yes',
            value: 'retailTransactionTrue',
        }, {
            label: 'No',
            value: 'retailTransactionFalse',
        }],
    },
    ConsignmentReference: {
        label: 'Consignment Number',
        type: InputTypes.Input,
        key: 'referenceNumber',
        valuePropName: 'value',
        placeholder: 'Enter Consignment Number',
        width: 250,
    },
    CustomerReference: {
        label: 'Customer Reference Number',
        type: InputTypes.Input,
        key: 'customerReferenceNumber',
        valuePropName: 'value',
        placeholder: 'Enter Customer Reference Number',
        width: 250,
    },
    Cust_Seller_code: {
        label: 'Customer seller code',
        type: InputTypes.Input,
        key: 'custSellerCode',
        valuePropName: 'value',
        placeholder: 'Customer seller code',
        width: 250,
        titleWidth: 250,
    },
    shipment_purpose: {
        label: 'Shipment Purpose',
        type: InputTypes.Select,
        key: 'shipmentPurpose',
        valuePropName: 'value',
        placeholder: 'Shipment Purpose',
        getOptions: (master: any) => {
            return Helper.isValidArray(master?.config?.shipment_purpose_list)
                ? master?.config?.shipment_purpose_list?.map((key: string) => {
                    return {
                        label: key,
                        value: key,
                    };
                })
                : null;
        },
        options: [
            {
                label: 'Commercial',
                value: 'COMMERCIAL',
            },
            {
                label: 'Courier',
                value: 'COURIER',
            },
            {
                label: 'Gift',
                value: 'GIFT',
            },
            {
                label: 'Personal Use',
                value: 'PERSONAL USE',
            },
            {
                label: 'Returns & Repair',
                value: 'RETURN AND REPAIR',
            },
            {
                label: 'Sample',
                value: 'SAMPLE',
            },
        ],
        width: 250,
        titleWidth: 250,
    },
    shipment_purpose_document: {
        label: 'Shipment Purpose',
        type: InputTypes.Select,
        valuePropName: 'value',
        placeholder: 'Shipment Purpose',
        key: 'shipmentPurposeDoc',
        options: [
            {
                label: 'Document',
                value: 'DOCUMENT',
            },
        ],
        width: 250,
        titleWidth: 250,
    },
    shipment_Terms: {
        label: 'Shipment Terms',
        type: InputTypes.Select,
        key: 'deliveryTerms',
        valuePropName: 'value',
        placeholder: 'Shipment Terms',
        options: [
            {
                label: 'DDP',
                value: 'DDP',
            },
            {
                label: 'DDU',
                value: 'DDU',
            },
        ],
        titleWidth: 250,
        width: 250,
    },
    DeliveryNote: {
        label: 'Delivery Note',
        type: InputTypes.Textarea,
        key: 'deliveryInstructions',
        valuePropName: 'value',
        placeholder: 'delivery_note_placeholder',
        width: '100%',
    },
    EORI_No: {
        label: 'EORI Number',
        type: InputTypes.Input,
        key: 'eoriNumber',
        valuePropName: 'value',
        placeholder: 'EORI Number',
        width: 250,
        titleWidth: '100%',
    },
    IORI_No: {
        label: 'IORI Number',
        type: InputTypes.Input,
        key: 'ioriNumber',
        valuePropName: 'value',
        placeholder: 'IORI Number',
        width: 250,
        titleWidth: '100%',
    },
    Freight_Cost: {
        label: 'Freight Cost',
        type: InputTypes.Number,
        key: 'freightCost',
        valuePropName: 'value',
        placeholder: 'Freight Cost',
        width: 250,
    },
    Freight_Cost_Currency: {
        label: 'Freight Cost Currency',
        type: InputTypes.Select,
        options: [
            {
                label: 'INR',
                value: 'INR',
            },
        ],
        defaultValue: 'INR',
        key: 'freightCostCurrency',
        valuePropName: 'value',
        placeholder: 'Freight Cost Currency',
        width: 60,
    },
    Against_Bond_LUT: {
        label: 'Bond LUT',
        placeholder: 'Bond LUT',
        type: InputTypes.Select,
        key: 'againstBondLut',
        valuePropName: 'value',
        options: [
            {
                label: 'Against Bond',
                value: 'B',
            },
            {
                label: 'Undertaking',
                value: 'U',
            },
            {
                label: 'GST',
                value: 'G',
            },
        ],
        titleWidth: '50%',
        width: 250,
    },
    ECom_Shipment: {
        label: 'ECom Shipment',
        type: InputTypes.Radio,
        key: 'ecomShipment',
        valuePropName: 'value',
        options: [
            {
                label: 'Yes',
                value: 'true',
            },
            {
                label: 'No',
                value: 'false',
            },
        ],
        titleWidth: '50%',
        defaultValue: 'true',
        width: 200,
    },
    Currency: {
        label: 'Currency',
        type: InputTypes.Select,
        options: [
            {
                label: 'INR',
                value: 'INR',
            },
        ],
        defaultValue: 'INR',
        key: 'currency',
        valuePropName: 'value',
        width: 60,
    },
    MEIS: {
        label: 'MEIS Applicable',
        type: InputTypes.Radio,
        key: 'meis',
        valuePropName: 'value',
        options: [
            {
                label: 'Yes',
                value: 'true',
            },
            {
                label: 'No',
                value: 'false',
            },
        ],
        titleWidth: '50%',
        defaultValue: 'true',
        width: 200,
    },
    Is_Battery: {
        label: 'Is Battery?',
        type: InputTypes.Radio,
        key: 'isBattery',
        valuePropName: 'value',
        options: [
            {
                label: 'Yes',
                value: 'true',
            },
            {
                label: 'No',
                value: 'false',
            },
        ],
        titleWidth: '50%',
        defaultValue: 'false',
        width: 200,
    },
    Export_Inv_Date: {
        label: 'Export Invoice Date',
        type: InputTypes.Date,
        key: 'exportInvoiceDate',
        valuePropName: 'value',
        width: 250,
    },
    FOB_Value: {
        label: 'FOB Value',
        type: InputTypes.Number,
        key: 'fobValue',
        valuePropName: 'value',
        placeholder: 'FOB Value',
        width: 250,
    },
    FOB_Value_Currency: {
        label: 'FOB Value Currency',
        type: InputTypes.Select,
        options: [
            {
                label: 'INR',
                value: 'INR',
            },
            {
                label: 'USD',
                value: 'USD',
            },
        ],
        defaultValue: 'INR',
        key: 'fobValueCurrency',
        valuePropName: 'value',
        width: 60,
    },
    TotalGSTPaidAmnt: {
        label: 'Total GST Amount Paid',
        type: InputTypes.Number,
        key: 'totalGSTPaidAmount',
        valuePropName: 'value',
        placeholder: 'Total GST Amount Paid',
        width: 250,
        titleWidth: 250,
    },
    TotalGSTPaidCurrency: {
        label: 'GST Amount Paid Currency',
        type: InputTypes.Select,
        key: 'totalGSTPaidCurrency',
        options: [
            {
                label: 'INR',
                value: 'INR',
            },
        ],
        defaultValue: 'INR',
        valuePropName: 'value',
        placeholder: 'GST Amount Paid Currency',
        width: 60,
    },
    CashonPickup: {
        label: 'Cash on Pickup',
        type: InputTypes.Input,
        key: 'cashonpickup',
        valuePropName: 'value',
        placeholder: 'Enter Cash on Pickup Value',
        width: 250,
    },
    DeliveryType: {
        label: 'Type of Delivery',
        type: InputTypes.Radio,
        key: 'typeOfDelivery',
        direction: 'row',
        defaultValue: 'HOME_DELIVERY',
        valuePropName: 'value',
        options: [{
            label: 'HD',
            value: 'HOME_DELIVERY',
        }, {
            label: 'Pudo',
            value: 'PUDO',
        }],
    },
    ItemType: {
        label: 'Item Type',
        type: InputTypes.Radio,
        key: 'courierType',
        direction: 'row',
        defaultValue: 'non-document',
        valuePropName: 'value',
        options: [{
            label: 'Document',
            value: 'document',
        }, {
            label: 'Non Document',
            value: 'non-document',
        }],
    },
    Weight: {
        label: 'Weight',
        type: InputTypes.Input,
        key: 'weight',
        valuePropName: 'value',
        placeholder: 'Weight',
        width: 150,
    },
    Length: {
        label: 'Dimensions',
        type: InputTypes.Input,
        key: 'length',
        valuePropName: 'value',
        placeholder: 'Length',
        width: 90,
        titleWidth: '25%',
    },
    Width: {
        label: 'Width',
        type: InputTypes.Input,
        key: 'width',
        valuePropName: 'value',
        placeholder: 'Width',
        width: 90,
    },
    Height: {
        label: 'Height',
        type: InputTypes.Input,
        key: 'height',
        valuePropName: 'value',
        placeholder: 'Height',
        width: 90,
    },
    Unit: {
        label: 'Unit',
        type: InputTypes.Select,
        key: 'unit',
        defaultValue: 'cm',
        valuePropName: 'value',
        placeholder: 'Unit',
        width: 70,
        options: [{
            label: 'Cm',
            value: 'cm',
        }, {
            label: 'Inch',
            value: 'inch',
        }, {
            label: 'Ft',
            value: 'ft',
        }, {
            label: 'Mtr',
            value: 'mtr',
        }],
    },
    NumPieces: {
        label: 'No. of Pieces',
        label2: 'Number of Items',
        type: InputTypes.Input,
        key: 'numberOfPieces',
        valuePropName: 'value',
        placeholder: 'No. of Pieces',
        width: 250,
    },
    DeclaredValue: {
        label: 'Declared Value',
        type: InputTypes.Input,
        inputType: 'number',
        key: 'declaredValue',
        valuePropName: 'value',
        placeholder: 'Declared Value',
        width: 250,
        titleWidth: '40%',
    },
    BoxNumber: {
        label: 'Box No.',
        type: InputTypes.Input,
        key: 'pieceNumber',
        valuePropName: 'value',
        placeholder: 'Box Number',
        width: 250,
        titleWidth: '20%',
    },
    Vol_weight: {
        label: 'Volumetric Weight(in Kg)',
        type: InputTypes.Input,
        inputType: 'number',
        key: 'volumetricWeight',
        valuePropName: 'value',
        placeholder: 'Volumetric Weight',
        width: 250,
        titleWidth: '50%',
    },
    DeclaredValueCurrency: {
        label: 'Declared Currency',
        type: InputTypes.Select,
        key: 'declaredCurrency',
        valuePropName: 'value',
        placeholder: 'Declared Value Currency',
        width: 50,
    },
    RiskSurcharge: {
        label: 'Risk Surcharge',
        type: InputTypes.Checkbox,
        key: 'isRiskSurchargeApplicable',
        valuePropName: 'checked',
        options: [{
            label: 'Risk Surcharge',
            value: 'riskSurcharge',
        }],
    },
    Description: {
        label: 'Description',
        type: InputTypes.Textarea,
        key: 'description',
        valuePropName: 'value',
        placeholder: 'Description',
        width: 460,
    },
    Quantity: {
        label: 'Quantity',
        type: InputTypes.Input,
        key: 'quantity',
        valuePropName: 'value',
        placeholder: 'Quantity',
        width: 460,
    },
    AddressOption: {
        label: '',
        type: InputTypes.Radio,
        key: 'adressOption',
        direction: 'row',
        defaultValue: 'saved',
        valuePropName: 'value',
        options: [{
            label: 'From Saved Address',
            value: 'saved',
        }, {
            label: 'Add/Edit Address',
            value: 'createnew',
        }],
    },
    AddressTypes: {
        label: 'Address Types',
        type: InputTypes.Radio,
        key: '',
        valuePropName: '',
        options: [{
            label: 'Consignor Address*',
            value: 'srcAddress',
        },
        {
            label: 'Delivery Address*',
            value: 'dstAddress',
        },
        {
            label: 'Alternate Address 1',
            value: 'alternateDelAddress1',
        },
        {
            label: 'Alternate Address 2',
            value: 'alternateDelAddress2',
        },
        {
            label: 'Redirection Address',
            value: 'redirectionAddress',
        },
        {
            label: 'Billing Address',
            value: 'billToDetails',
        }],
    },
    SrcAddress: {
        label: 'Consignor Address*',
        type: InputTypes.Select,
        key: 'srcAddress',
        valuePropName: 'value',
        placeholder: 'Select Consignor Address',
        width: 250,
    },
    DstAddress: {
        label: 'Delivery Address*',
        type: InputTypes.Select,
        key: 'dstAddress',
        valuePropName: 'value',
        placeholder: 'Select Delivery Address',
        width: 250,
    },
    AlternateDelAddress1: {
        label: 'Alternate Address 1',
        type: InputTypes.Select,
        key: 'alternateDelAddress1',
        valuePropName: 'value',
        placeholder: 'Select Alternate Address 1',
        width: 250,
    },
    AlternateDelAddress2: {
        label: 'Alternate Address 2',
        type: InputTypes.Select,
        key: 'alternateDelAddress2',
        valuePropName: 'value',
        placeholder: 'Select Alternate Address 2',
        width: 250,
    },
    RedirectionAddress: {
        label: 'Redirection Address',
        type: InputTypes.Select,
        key: 'redirectionAddress',
        valuePropName: 'value',
        placeholder: 'Select Redirection Address',
        width: 250,
    },
    BillingDetailsAddress: {
        label: 'Billing Address',
        type: InputTypes.Select,
        key: 'billToDetails',
        valuePropName: 'value',
        placeholder: 'Select Billing Address',
        width: 250,
    },
    Services: {
        label: 'Select Service*',
        type: InputTypes.Checkbox,
        key: 'serviceType',
        valuePropName: 'value',
    },
    PickupSchedule: {
        label: 'Pickup Schedule',
        type: InputTypes.Date,
        key: 'pickupSchedule',
        defaultValue: moment(),
        valuePropName: 'value',
        direction: 'column',
        placeholder: 'Select Pickup Schedule',
        width: 250,
    },
    DocumentDate: {
        label: 'Document Date *',
        type: InputTypes.Date,
        key: 'invoiceDate',
        defaultValue: moment(),
        valuePropName: 'value',
        direction: 'column',
        placeholder: 'Select Document Date',
        width: 200,
    },
    PickupTime: {
        label: 'Pickup Time Slot',
        type: InputTypes.Time,
        key: 'pickupTime',
        valuePropName: 'value',
        direction: 'column',
        placeholder: 'Select Time',
        width: 125,
    },
    Amount: {
        label: 'Amount',
        type: InputTypes.Number,
        key: 'codAmount',
        valuePropName: 'value',
        direction: 'column',
        placeholder: 'Enter value',
        width: 175,
    },
    InFavour: {
        label: 'In Favour of',
        type: InputTypes.Input,
        key: 'codFavorOf',
        valuePropName: 'value',
        direction: 'column',
        placeholder: 'Enter Name',
        width: 200,
    },
    Mode: {
        label: 'Mode',
        type: InputTypes.Select,
        key: 'codCollectionMode',
        valuePropName: 'value',
        direction: 'column',
        placeholder: 'Select Mode',
        width: 215,
    },
    WeightUnit: {
        label: 'Unit',
        type: InputTypes.Select,
        key: 'weightUnit',
        defaultValue: 'kg',
        valuePropName: 'value',
        placeholder: 'Unit',
        width: 50,
        options: [{
            label: 'kg',
            value: 'kg',
        }, {
            label: 'gm',
            value: 'gm',
        }],
    },
    ContentTypes: {
        label: 'Content Type',
        label2: 'Commodity Type',
        type: InputTypes.Select,
        key: 'commodityId',
        valuePropName: 'value',
        placeholder: 'Content Type',
        placeholder2: 'Commodity Type',
        width: 250,
    },
    ItemUnitType: {
        label: 'Item Unit',
        label2: 'Item Type',
        type: InputTypes.Select,
        key: 'itemUnit',
        valuePropName: 'value',
        placeholder: 'Item Unit Type',
        placeholder2: 'Item Unit Type',
        width: 300,
    },
    ContentNameForOthers: {
        label: 'If "Others" please specify',
        type: InputTypes.Input,
        key: 'unlistedCommodityName',
        valuePropName: 'value',
        placeholder: 'If "Others" please specify',
        width: 250,
    },
    TransactionType: {
        label: 'Transaction Type *',
        type: InputTypes.Radio,
        key: 'transaction',
        direction: 'row',
        defaultValue: 'outward',
        valuePropName: 'value',
        options: [{
            label: 'Outward',
            value: 'outward',
        }, {
            label: 'Inward',
            value: 'inward',
        }],
    },
    InwardTransactionSubTypes: {
        label: 'Transaction Sub Type *',
        type: InputTypes.Select,
        key: 'reasonTransport',
        valuePropName: 'value',
        placeholder: 'Transaction Sub Type',
        defaultValue: '1',
        width: 150,
        options: [{
            label: 'Supply',
            value: '1',
        }, {
            label: 'Import',
            value: '2',
        }, {
            label: 'SKD/CKD',
            value: '9',
        }, {
            label: 'Job Work Returns',
            value: '6',
        }, {
            label: 'Sales Returns',
            value: '7',
        }, {
            label: 'Exhibition or Fairs',
            value: '12',
        }, {
            label: 'For Own Use',
            value: '5',
        }, {
            label: 'Others',
            value: '8',
        }],
    },
    OutwardTransactionSubTypes: {
        label: 'Transaction Sub Type *',
        type: InputTypes.Select,
        key: 'reasonTransport',
        valuePropName: 'value',
        placeholder: 'Transaction Sub Type',
        width: 150,
        options: [{
            label: 'Supply',
            value: '1',
        }, {
            label: 'Export',
            value: '3',
        }, {
            label: 'Job Work',
            value: '4',
        }, {
            label: 'SKD/CKD',
            value: '9',
        }, {
            label: 'Recipient Not Known',
            value: '11',
        }, {
            label: 'For Own Use',
            value: '5',
        }, {
            label: 'Exhibition or Fairs',
            value: '12',
        }, {
            label: 'Line Sales',
            value: '10',
        }, {
            label: 'Others',
            value: '8',
        }],
    },
    DocumentTypes: {
        label: 'Document Type *',
        type: InputTypes.Select,
        key: 'documentType',
        valuePropName: 'value',
        placeholder: 'Document Type',
        defaultValue: 'Tax Invoice',
        width: 150,
        options: [{
            label: 'Tax Invoice',
            value: 'Tax Invoice',
        }, {
            label: 'Bill of Supply',
            value: 'Bill of Supply',
        }, {
            label: 'Bill of Entry',
            value: 'Bill of Entry',
        }, {
            label: 'Delivery Challan',
            value: 'Delivery Challan',
        }, {
            label: 'Credit Note',
            value: 'Credit Note',
        }, {
            label: 'Others',
            value: 'Others',
        }],
    },
    TransportationModes: {
        label: 'Transportation Mode *',
        type: InputTypes.Select,
        key: 'transMode',
        valuePropName: 'value',
        placeholder: 'Transportation Mode Type',
        defaultValue: '1',
        width: 150,
        options: [{
            label: 'Road',
            value: '1',
        }, {
            label: 'Rail',
            value: '2',
        }, {
            label: 'Air',
            value: '3',
        }, {
            label: 'Ship',
            value: '4',
        }],
    },
    PudoStores: {
        label: 'Pudo Store',
        type: InputTypes.Select,
        key: 'kiranaHubCode',
        valuePropName: 'value',
        placeholder: 'Pudo Store',
        width: 250,
    },
    EwayBillNo: {
        label: 'Eway Bill Number',
        type: InputTypes.Input,
        key: 'ewayBillNumber',
        valuePropName: 'value',
        placeholder: 'Ewail Bill Number',
        width: 250,
    },
    SRFNumber: {
        label: 'SRF Number',
        type: InputTypes.Input,
        key: 'srfNumber',
        valuePropName: 'value',
        placeholder: 'SRF Number',
        width: 250,
    },
    InvoiceType: {
        label: 'Invoice Type',
        type: InputTypes.Radio,
        key: 'invoiceType',
        valuePropName: 'value',
        placeholder: 'Invoice Type',
        defaultValue: 'gst',
        options: [
            {
                label: 'GST',
                value: 'GST',
            },
            {
                label: 'Non GST',
                value: 'NonGST',
            },
        ],
        width: 250,
    },
    InvoiceDate: {
        label: 'Invoice Date',
        type: InputTypes.Date,
        key: 'invoiceDate',
        valuePropName: 'value',
        placeholder: 'Invoice Date',
        width: 250,
    },
    InvoiceNumber: {
        label: 'Invoice Number',
        type: InputTypes.Input,
        key: 'invoiceNumber',
        valuePropName: 'value',
        placeholder: 'Invoice Number',
        width: 250,
    },
    IncoTerms: {
        label: 'Inco Terms',
        type: InputTypes.Select,
        key: 'incoTerms',
        valuePropName: 'value',
        placeholder: 'Inco Terms',
        width: 250,
    },
    EwbNumber: {
        label: 'EWB Number',
        placeholder: 'EWB Number',
        type: InputTypes.Input,
        key: 'ewbNumber',
        valuePropName: 'value',
        width: '250',
    },
    EwbDate: {
        label: 'EWB Date',
        placeholder: 'EWB Date',
        type: InputTypes.Date,
        key: 'ewbDate',
        valuePropName: 'value',
        width: '250',
    },
    IsCOD: {
        label: 'Payment via COD',
        placeholder: 'Payment via COD',
        defaultValue: 'yes',
        type: InputTypes.Radio,
        key: 'isCOD',
        valuePropName: 'value',
        width: '250',
        options: [
            {
                label: 'Yes',
                value: 'yes',
            },
            {
                label: 'No',
                value: 'no',
            },
        ],
    },
    ReferenceNumber: {
        label: 'Reference Number',
        placeholder: 'Reference Number',
        type: InputTypes.Input,
        key: 'reference_number',
        valuePropName: 'value',
        width: '250',
    },
    IsCsbv: {
        label: 'Is CSBV',
        placeholder: 'Is CSBV',
        type: InputTypes.Radio,
        key: 'isCsbv',
        defaultValue: 'false',
        valuePropName: 'value',
        width: '250',
        options: [{
            label: 'False',
            value: 'false',
        },
        {
            label: 'True',
            value: 'true',
        }],
    },
    TaxPercentage: {
        label: 'Tax Percentage',
        placeholder: 'Tax Percentage',
        type: InputTypes.Number,
        key: 'taxDetailsTaxPercentage',
        valuePropName: 'value',
        width: '250',
    },
    TotalTax: {
        label: 'Total Tax',
        placeholder: 'Total Tax',
        type: InputTypes.Number,
        key: 'taxDetailsTotalTax',
        valuePropName: 'value',
        width: '250',
    },
    PurchaseOrderNumber: {
        label: 'Purchase Order Number',
        placeholder: 'Purchase Order Number',
        type: InputTypes.Input,
        key: 'purchaseOrderNumber',
        valuePropName: 'value',
        width: '250',
    },
    Eccn: {
        label: 'ECCN',
        placeholder: 'ECCN',
        type: InputTypes.Input,
        key: 'eccn',
        valuePropName: 'value',
        width: '250',
    },
    PieceProductCode: {
        label: 'Product Code',
        placeholder: 'Product Code',
        type: InputTypes.Input,
        key: 'pieceProductCode',
        valuePropName: 'value',
        width: '250',
    },
    HsCode: {
        label: 'HS Code',
        placeholder: 'HS Code',
        type: InputTypes.Input,
        key: 'hsCode',
        valuePropName: 'value',
        width: '250',
    },
    IossCode: {
        label: 'IOSS Code',
        placeholder: 'IOSS Code',
        type: InputTypes.Input,
        key: 'iossCode',
        valuePropName: 'value',
        width: '250',
    },
    IsKit: {
        label: 'Is Kit',
        placeholder: 'Is Kit',
        type: InputTypes.Radio,
        key: 'isKit',
        valuePropName: 'value',
        width: '250',
        defaultValue: 'no',
        options: [
            {
                label: 'Yes',
                value: 'yes',
            },
            {
                label: 'No',
                value: 'no',
            },
        ],
    },
    IsStackable: {
        label: 'Is Stackable',
        placeholder: 'Is Stackable',
        type: InputTypes.Radio,
        key: 'isStackable',
        valuePropName: 'value',
        width: '250',
        defaultValue: 'no',
        options: [
            {
                label: 'Yes',
                value: 'yes',
            },
            {
                label: 'No',
                value: 'no',
            },
        ],
    },
    ManufacturingCountry: {
        label: 'Manufacturing Country',
        placeholder: 'Manufacturing Country',
        type: InputTypes.Select,
        key: 'manufacturingCountry',
        valuePropName: 'value',
        width: '250',
    },
    IGST: {
        label: 'IGST Amount',
        placeholder: 'IGST Amount',
        type: InputTypes.Number,
        key: 'taxDetailsIgst',
        valuePropName: 'value',
        width: '250',
    },
    CGST: {
        label: 'CGST Amount',
        placeholder: 'CGST Amount',
        type: InputTypes.Number,
        key: 'taxDetailsCgst',
        valuePropName: 'value',
        width: '250',
    },
    SGST: {
        label: 'SGST Amount',
        placeholder: 'SGST Amount',
        type: InputTypes.Number,
        key: 'taxDetailsSgst',
        valuePropName: 'value',
        width: '250',
    },
    TaxDetailsSenderGstin: {
        label: 'Sender GSTIN',
        placeholder: 'Sender GSTIN',
        type: InputTypes.Input,
        key: 'taxDetailsSenderGstin',
        valuePropName: 'value',
        width: '250',
    },
    TaxDetailsRecieverGstin: {
        label: 'Reciever GSTIN',
        placeholder: 'Reciever GSTIN',
        type: InputTypes.Input,
        key: 'taxDetailsRecieverGstin',
        valuePropName: 'value',
        width: '250',
    },
    ForwardReferenceNumber: {
        label: 'Forward Reference Number',
        placeholder: '',
        type: InputTypes.Input,
        key: 'forwardReferenceNumber',
        valuePropName: 'value',
        width: '250',
    },
};

export const consigneeFormFields: Record<string, FormField> = {
    ConsigneeCompanyName: {
        label: 'Consignee Company Name',
        type: InputTypes.Input,
        key: 'consigneeCompanyName',
        titleKey: 'consignee_company_name',
        valuePropName: 'value',
    },
    ReceiverVATNumber: {
        label: 'Receiver VAT Number',
        type: InputTypes.Input,
        key: 'receiverVATNumber',
        titleKey: 'receiver_vat_number',
        valuePropName: 'value',
    },
    ConsigneeType: {
        label: 'Consignee Type',
        type: InputTypes.Radio,
        key: 'destinationType',
        titleKey: 'consignee_type',
        valuePropName: 'value',
    },
    ConsigneeCode: {
        label: 'Consignee Code',
        type: InputTypes.Input,
        key: 'consigneeCode',
        titleKey: 'consignee_code',
        valuePropName: 'value',
    },
};

export const consignorFormFields: Record<string, FormField> = {
    ConsignorCompanyName: {
        label: 'Consignor Company Name',
        placeholder: 'Consignor Company Name',
        type: InputTypes.Input,
        key: 'consignorCompanyName',
        valuePropName: 'value',
        width: '90%',
    },
    OriginType: {
        label: 'Sender Type',
        type: InputTypes.Radio,
        key: 'originType',
        valuePropName: 'value',
        options: [{
            label: 'Individual',
            value: 'individual',
        },
        {
            label: 'Business',
            value: 'business',
        }],
        width: '90%',
    },
    ConsignorTaxID: {
        label: 'Consignor Tax No',
        placeholder: 'Consignor Tax No',
        type: InputTypes.Input,
        key: 'consignorTaxId',
        valuePropName: 'value',
        width: '90%',
    },
    ConsignorGSTINo: {
        label: 'Consignor GSTIN No',
        placeholder: 'Consignor GSTIN No',
        type: InputTypes.Input,
        key: 'consignorGSTINNumber',
        valuePropName: 'value',
        width: '90%',
    },
    ConsignorIECNo: {
        label: 'Consignor IEC No',
        placeholder: 'Consignor IEC No',
        type: InputTypes.Input,
        key: 'consignorIECNumber',
        valuePropName: 'value',
        width: '90%',
    },
    Consignor_PAN: {
        label: 'Consignor PAN No',
        placeholder: 'Consignor PAN No',
        type: InputTypes.Input,
        key: 'consignorPanId',
        valuePropName: 'value',
        width: '90%',
    },
    ConsignorKycDocType: {
        label: 'Sender KYC Type',
        placeholder: 'Sender KYC Type',
        type: InputTypes.Select,
        key: 'consignorKycDocType',
        valuePropName: 'value',
        width: '90%',
        options: [
            {
                label: 'PAN CARD',
                value: 'PAN CARD',
            },
            {
                label: 'AADHAAR CARD',
                value: 'AADHAAR CARD',
            },
            {
                label: 'VOTER ID',
                value: 'VOTER ID',
            },
            {
                label: 'PASSPORT',
                value: 'PASSPORT',
            },
        ],
    },
    ConsignorKycDocNo: {
        label: 'Sender KYC Document No',
        placeholder: 'Sender KYC Document No',
        type: InputTypes.Input,
        key: 'consignorKycDocNumber',
        valuePropName: 'value',
        width: '90%',
    },
    Sender_Bank_Account_No: {
        label: 'Sender Bank Account No',
        placeholder: 'Sender Bank Account No',
        type: InputTypes.Input,
        key: 'senderBankAccountNumber',
        valuePropName: 'value',
        width: '90%',
    },
    Sender_Bank_AD_Code: {
        label: 'Sender Bank AD Code',
        placeholder: 'Sender Bank AD Code',
        type: InputTypes.Input,
        key: 'senderBankADCode',
        valuePropName: 'value',
        width: '90%',
    },
    Sender_Bank_IFSC_Code: {
        label: 'Sender Bank IFSC Code',
        placeholder: 'Sender Bank IFSC Code',
        type: InputTypes.Input,
        key: 'senderBankIFSCCode',
        valuePropName: 'value',
        width: '90%',
    },
    NEFI_Flag: {
        label: 'NEFI Flag',
        type: InputTypes.Radio,
        key: 'nefiFlag',
        valuePropName: 'value',
        options: [{
            label: 'Yes',
            value: 'true',
        },
        {
            label: 'No',
            value: 'false',
        }],
        width: '90%',
    },
    CPCCode: {
        label: 'Sender CPC Code',
        placeholder: 'Sender CPC Code',
        type: InputTypes.Input,
        key: 'cpcCode',
        valuePropName: 'value',
        width: '90%',
    },
    Cess_Value: {
        label: 'CESS Value',
        placeholder: 'CESS Value',
        type: InputTypes.Number,
        key: 'cessValue',
        valuePropName: 'value',
        width: '90%',
    },
};

export const itemFormFields: Record<string, FormField> = {
    HSNCode: {
        label: 'Product HSN Code',
        placeholder: 'Product HSN Code',
        type: InputTypes.Input,
        key: 'hsnCode',
        valuePropName: 'value',
        width: '90%',
    },
    OriginOfGoods: {
        label: 'Origin Of Goods',
        placeholder: 'Origin Of Goods',
        type: InputTypes.Radio,
        key: 'originOfGoods',
        valuePropName: 'value',
        width: '90%',
        options: [
            {
                value: 'INDIA',
                label: 'INDIA',
            },
        ],
    },
    SKU_Number: {
        label: 'SKU Number',
        placeholder: 'SKU Number',
        type: InputTypes.Input,
        key: 'skuNumber',
        valuePropName: 'value',
        width: '90%',
    },
    ATE_Number: {
        label: 'ATE Number',
        placeholder: 'ATE Number',
        type: InputTypes.Input,
        key: 'ateNumber',
        valuePropName: 'value',
        width: '90%',
    },
    Prod_URL: {
        label: 'Prod URL',
        placeholder: 'Prod URL',
        type: InputTypes.Input,
        key: 'productUrl',
        valuePropName: 'value',
        width: '90%',
    },
    Item_Rate: {
        label: 'Item Rate',
        placeholder: 'Item Rate',
        type: InputTypes.Number,
        key: 'itemValue',
        valuePropName: 'value',
        width: '90%',
    },
    Duty_Value: {
        label: 'Item\'s Total Value',
        placeholder: 'Item\'s Total Value',
        type: InputTypes.Number,
        key: 'dutyValue',
        valuePropName: 'value',
        width: '90%',
        disabled: true,
    },
    Gst_Value: {
        label: 'Item\'s GST Value',
        placeholder: 'Item\'s GST Value',
        type: InputTypes.Number,
        key: 'gstValue',
        valuePropName: 'value',
        width: '90%',
        disabled: true,
    },
    GST_Percentage: {
        label: 'GST Percentage',
        placeholder: 'GST Percentage',
        type: InputTypes.Number,
        key: 'gstPercentage',
        valuePropName: 'value',
        width: '90%',
    },
    Prod_dead_weight: {
        label: 'Product dead weight (Kg)',
        placeholder: 'Product dead weight',
        type: InputTypes.Number,
        key: 'productDeadWeight',
        valuePropName: 'value',
        width: '90%',
    },
    Prod_height: {
        label: 'Product height (cm)',
        placeholder: 'Product height',
        type: InputTypes.Number,
        key: 'productHeight',
        valuePropName: 'value',
        width: '90%',
    },
    Prod_length: {
        label: 'Product length (cm)',
        placeholder: 'Product length',
        type: InputTypes.Number,
        key: 'productLength',
        valuePropName: 'value',
        width: '90%',
    },
    Prod_width: {
        label: 'Product width (cm)',
        placeholder: 'Product width',
        type: InputTypes.Number,
        key: 'productWidth',
        valuePropName: 'value',
        width: '90%',
    },
    Composition: {
        label: 'Product Composition',
        placeholder: 'Product Composition',
        type: InputTypes.Input,
        key: 'composition',
        valuePropName: 'value',
        width: '90%',
    },
    Prod_Type: {
        label: 'Product Type',
        placeholder: 'Product Type',
        type: InputTypes.Input,
        key: 'productType',
        valuePropName: 'value',
        width: '90%',
    },
    RODTEP_Scheme: {
        label: 'RODTEP Scheme',
        placeholder: 'RODTEP Scheme',
        type: InputTypes.Input,
        key: 'rodtepScheme',
        valuePropName: 'value',
        width: '90%',
    },
    Item_Size: {
        label: 'Item Size',
        placeholder: 'Item Size',
        type: InputTypes.Input,
        key: 'itemSize',
        valuePropName: 'value',
        width: '90%',
    },
    Cess_Value: {
        label: 'Cess Value',
        placeholder: 'Cess Value',
        type: InputTypes.Number,
        key: 'cessValue',
        valuePropName: 'value',
        width: '90%',
    },
    no_of_items: {
        label: 'Quantity',
        placeholder: 'Quantity',
        type: InputTypes.Number,
        key: 'numberOfItems',
        valuePropName: 'value',
        width: '90%',
    },
    item_Description: {
        label: 'Item description',
        placeholder: 'Item description',
        type: InputTypes.Textarea,
        key: 'itemDescription',
        valuePropName: 'value',
        width: '90%',
    },
    TaxPercentage: {
        label: 'Tax Percentage',
        placeholder: 'Tax Percentage',
        type: InputTypes.Number,
        key: 'taxDetailsTaxPercentage',
        valuePropName: 'value',
        width: '250',
    },
    TotalTax: {
        label: 'Total Tax',
        placeholder: 'Total Tax',
        type: InputTypes.Number,
        key: 'taxDetailsTotalTax',
        valuePropName: 'value',
        width: '250',
    },
    ManufacturingCountry: {
        label: 'Manufacturing Country',
        placeholder: 'Manufacturing Country',
        type: InputTypes.Select,
        key: 'manufacturingCountry',
        valuePropName: 'value',
        width: '250',
    },
};

export const getRequiredKeys = (courierType: string) => {
    const required = {
        FIRST: [] as string[],
        SECOND: ['dstAddress'],
        THIRD: ['srcAddress'],
        FOUR: [formFields.Services.key],
        FIFTH: [],
        SUMMARY: [],
    };
    if (courierType === 'non-document') {
        required.FIRST = [
            // formFields.ContentTypes.key,
            // formFields.DeclaredValue.key,
            // formFields.Weight.key,
            // formFields.SubAccount.key,
        ];
    }
    return required;
};

export const dangerousGoodFields: Record<string, FormField> = {
    IsDangerousGood: {
        label: 'Is Dangerous Good',
        type: InputTypes.Radio,
        key: 'isDangerousGood',
        valuePropName: 'value',
        direction: 'column',
        defaultValue: 'false',
        options: [{
            label: 'Yes',
            value: 'true',
        }, {
            label: 'No',
            value: 'false',
        }],
        width: '90%',
    },
    DangerousGoodAccessible: {
        label: 'Dangerous Good Accessible',
        key: 'dangerousGoodAccessible',
        valuePropName: 'value',
        width: '90%',
        type: InputTypes.Radio,
        direction: 'column',
        defaultValue: 'false',
        options: [{
            label: 'Yes',
            value: 'true',
        }, {
            label: 'No',
            value: 'false',
        }],
    },
    DangerousGoodUnNumber: {
        label: 'Dangerous Good UN Number',
        type: InputTypes.Input,
        key: 'dangerousGoodUnNumber',
        valuePropName: 'value',
        placeholder: 'Dangerous Good UN Number',
        width: '90%',
    },
    DangerousGoodName: {
        label: 'Dangerous Good Name',
        type: InputTypes.Input,
        key: 'dangerousGoodName',
        valuePropName: 'value',
        placeholder: 'Dangerous Good Name',
        width: '90%',
    },
    DangerousGoodClass: {
        label: 'Dangerous Good Class',
        type: InputTypes.Input,
        key: 'dangerousGoodClass',
        valuePropName: 'value',
        placeholder: 'Dangerous Good Class',
        width: '90%',
    },
    DangerousGoodDivision: {
        label: 'Dangerous Good Division',
        type: InputTypes.Input,
        key: 'dangerousGoodDivision',
        valuePropName: 'value',
        placeholder: 'Dangerous Good Division',
        width: '90%',
    },
    DangerousGoodCategory: {
        label: 'Dangerous Good Category',
        type: InputTypes.Select,
        key: 'dangerousGoodCategory',
        valuePropName: 'value',
        placeholder: 'Dangerous Good Category',
        width: '90%',
        options: [{
            label: 'Environmentally Hazardous Substances',
            value: 'environmentally_hazardous_substances',
        }, {
            label: 'Lithium Batteries',
            value: 'lithium_batteries',
        }, {
            label: 'Magnetized Materials',
            value: 'magnetized_materials',
        }, {
            label: 'Asbestos',
            value: 'asbestos',
        }, {
            label: 'Genetically Modified Organisms',
            value: 'genetically_modified_organisms',
        }, {
            label: 'Dry Ice',
            value: 'dry_ice',
        }, {
            label: 'Safety Devices',
            value: 'safety_devices',
        }, {
            label: 'Others',
            value: 'others',
        }],
    },
    DangerousGoodPackingGroup: {
        label: 'Dangerous Good Packing Group',
        type: InputTypes.Select,
        key: 'dangerousGoodPackingGroup',
        valuePropName: 'value',
        placeholder: 'Dangerous Good Packing Group',
        width: '90%',
        options: [{
            label: 'Packing Group 1',
            value: 'packing_group_1',
        }, {
            label: 'Packing Group 2',
            value: 'packing_group_2',
        }, {
            label: 'Packing Group 3',
            value: 'packing_group_3',
        }],
    },

    DangerousGoodCategoryType: {
        label: 'Dangerous Good Category Type',
        type: InputTypes.Input,
        key: 'dangerousGoodCategoryType',
        valuePropName: 'value',
        placeholder: 'Dangerous Good Category Type',
        width: '90%',
    },
    DangerousGoodCategoryPackingInstruction: {
        label: 'Dangerous Good Category Packing Instruction',
        type: InputTypes.Input,
        key: 'dangerousGoodCategoryPackingInstruction',
        valuePropName: 'value',
        placeholder: 'Dangerous Good Category Packing Instruction',
        width: '90%',
    },
};

export type PieceDetails = {
    key: any;
    height?: any;
    length?: any;
    numberOfPieces?: any;
    unit?: any;
    weight?: any;
    weightUnit?: any;
    dimensionsUnit?: any;
    width?: any;
    declaredValue?: any;
    description?: any;
    items?: Record<any, any>;
    codAmount?: any;
    reference_number?: any;
};

/* eslint-disable */
export const validationBuilderFieldMapping: any = {
    "codAmount": "cod_amount", 
    "codCollectionMode": "cod_collection_mode",
    "codFavorOf": "cod_favor_of",
    "commodityId": "commodity_id",
    "currency": "currency", // cod_currency, declared_currency, currency_code, freight_cost_currency, fob_value_currency, total_gst_paid_currency
    "consignmentType": "consignment_type",
    "courierType": "load_type",
    "customerReferenceNumber": "customer_reference_number",
    "copAmount": "cop_amount",
    "isRetailTransaction": "is_retail_transaction",
    "typeOfDelivery": "type_of_delivery",
    "description": "description",
    "dimensions.length": "length",
    "dimensions.width": "width",
    "dimensions.height": "height",
    "dstAddress.addressLine1": "destination_details.address_line_1",
    "dstAddress.addressLine2": "destination_details.address_line_2",
    "dstAddress.addressLine3": "destination_details.address_line_3",
    "dstAddress.cityName": "destination_details.city",
    "dstAddress.countryName": "destination_details.country",
    "dstAddress.name": "destination_details.name",
    "dstAddress.phone": "destination_details.phone",
    "dstAddress.pincode": "destination_details.pincode",
    "dstAddress.stateName": "destination_details.state",
    "dstAddress.latitude": "destination_details.latitude",
    "dstAddress.longitude": "destination_details.longitude",
    "dstAddress.companyName": "destination_details.company_name",
    "ewayBill": "eway_bill",
    "kiranaHubCode": "kirana_hub_code",
    "isInternational": "is_international",
    "isRiskSurchargeApplicable": "is_risk_surcharge_applicable",
    "numberOfPieces": "num_pieces",
    "referenceNumber": "reference_number",
    "retAddress.addressLine1": "return_details.address_line_1",
    "retAddress.addressLine2": "return_details.address_line_2",
    "retAddress.addressLine3": "return_details.address_line_3",
    "retAddress.cityName": "return_details.city",
    "retAddress.countryName": "return_details.country",
    "retAddress.name": "return_details.name",
    "retAddress.phone": "return_details.phone",
    "retAddress.pincode": "return_details.pincode",
    "retAddress.stateName": "return_details.state",
    "retAddress.latitude": "return_details.latitude",
    "retAddress.longitude": "return_details.longitude",
    "serviceType": "service_type_id",
    "srcAddress.addressLine1": "origin_details.address_line_1",
    "srcAddress.addressLine2": "origin_details.address_line_2",
    "srcAddress.addressLine3": "origin_details.address_line_3",
    "srcAddress.cityName": "origin_details.city",
    "srcAddress.countryName": "origin_details.country",
    "srcAddress.name": "origin_details.name",
    "srcAddress.phone": "origin_details.phone",
    "srcAddress.pincode": "origin_details.pincode",
    "srcAddress.stateName": "origin_details.state",
    "srcAddress.latitude": "origin_details.latitude",
    "srcAddress.longitude": "origin_details.longitude",
    "srcAddress.companyName": "origin_details.company_name",
    "weight": "weight",
    "declaredPrice": "declared_value", // declared_value_without_tax
    "alternateDelAddress1.addressLine1": "alternate_delivery_details_1.address_line_1",
    "alternateDelAddress1.addressLine2": "alternate_delivery_details_1.address_line_2",
    "alternateDelAddress1.addressLine3": "alternate_delivery_details_1.address_line_3",
    "alternateDelAddress1.cityName": "alternate_delivery_details_1.city",
    "alternateDelAddress1.countryName": "alternate_delivery_details_1.country",
    "alternateDelAddress1.name": "alternate_delivery_details_1.name",
    "alternateDelAddress1.phone": "alternate_delivery_details_1.phone",
    "alternateDelAddress1.pincode": "alternate_delivery_details_1.pincode",
    "alternateDelAddress1.stateName": "alternate_delivery_details_1.state",
    "alternateDelAddress1.latitude": "alternate_delivery_details_1.latitude",
    "alternateDelAddress1.longitude": "alternate_delivery_details_1.longitude",
    "alternateDelAddress2.addressLine1": "alternate_delivery_details_2.address_line_1",
    "alternateDelAddress2.addressLine2": "alternate_delivery_details_2.address_line_2",
    "alternateDelAddress2.addressLine3": "alternate_delivery_details_2.address_line_3",
    "alternateDelAddress2.cityName": "alternate_delivery_details_2.city",
    "alternateDelAddress2.countryName": "alternate_delivery_details_2.country",
    "alternateDelAddress2.name": "alternate_delivery_details_2.name",
    "alternateDelAddress2.phone": "alternate_delivery_details_2.phone",
    "alternateDelAddress2.pincode": "alternate_delivery_details_2.pincode",
    "alternateDelAddress2.stateName": "alternate_delivery_details_2.state",
    "alternateDelAddress2.latitude": "alternate_delivery_details_2.latitude",
    "alternateDelAddress2.longitude": "alternate_delivery_details_2.longitude",
    "redirectionAddress.addressLine1": "redirection_address.address_line_1",
    "redirectionAddress.addressLine2": "redirection_address.address_line_2",
    "redirectionAddress.addressLine3": "redirection_address.address_line_3",
    "redirectionAddress.cityName": "redirection_address.city",
    "redirectionAddress.countryName": "redirection_address.country",
    "redirectionAddress.name": "redirection_address.name",
    "redirectionAddress.phone": "redirection_address.phone",
    "redirectionAddress.pincode": "redirection_address.pincode",
    "redirectionAddress.stateName": "redirection_address.state",
    "redirectionAddress.latitude": "redirection_address.latitude",
    "redirectionAddress.longitude": "redirection_address.longitude",
    "childClient.code": "customer_code",
    "childClient.id": "customer_id",
    "purpose": "purpose",
    "freightCost": "freight_cost",
    "freightCostCurrency": "freight_cost_currency",
    "againstBondLut": "against_bond_lut",
    "ecomShipment": "ecom_shipment",
    "meis": "meis",
    "isBattery": "is_battery",
    "exportInvoiceDate": "export_invoice_date",
    "fobValueCurrency": "fob_value_currency",
    "fobValue": "fob_value",
    "totalGSTPaidCurrency": "total_gst_paid_currency",
    "totalGSTPaidAmount": "total_gst_paid_amount",
    "consignorCompanyName": "consignor_company_name",
    "consignorTaxId": "consignor_tax_id",
    "consignorIECNumber": "consignor_iec_number",
    "consignorGSTINNumber": "consignor_gstin_number",
    "consignorPanId": "consignor_pan",
    "consignorKycDocType": "consignor_kyc_doc_type",
    "consignorKycDocNumber": "consignor_kyc_doc_number",
    "consignorKycFrontImage": "consignor_kyc_front_image",
    "consignorKycBackImage": "consignor_kyc_back_image",
    "senderBankAccountNumber": "sender_bank_account_number",
    "senderBankADCode": "sender_bank_ad_code",
    "senderBankIFSCCode": "sender_bank_ifsc_code",
    "nefiFlag": "nefi_flag",
    "invoiceType": "invoice_type",
    "invoiceDate": "invoice_date",
    "invoiceNumber": "invoice_number",
    "consigneeCompanyName": "consignee_company_name",
    "incoTerms": "inco_terms",
    "deliveryTerms": "delivery_terms",
    "originType": "origin_type",
    "destinationType": "destination_type",
    "dimensionUnit": "dimension_unit",
    "weightUnit": "weight_unit",
    "receiverVATNumber": "receiver_vat_number",
    "pickupTimeSlotStart": "pickup_time_slot_start",
    "pickupTimeSlotEnd": "pickup_time_slot_end",
    "declaredCurrency": "declared_currency",
    "riskSurchargeType": "risk_surcharge_type",
    "source": "source",
    "createdBy": "created_by",
    "invoiceAmount": "invoice_amount",
    "customsAmount": "customs_value.amount",
    "movementReferenceNumber": "customs_value.movement_reference_number",
    "isCustomsDeclarable": "customs_value.is_customs_declarable",
    "remarks": "notes",
    "consigneeCode": "consignee_code",
    "declaredValue": "declared_value",
    "piecesDetail": "piecesDetail",
    "reference_number": "reference_number",
    "itemUnit": "item_unit",
    'quantity': 'quantity',
    "height": "height",
    "width": "width",
    "length": "length",
    "unit": "unit",
    "item_unit": "item_unit",
    "cod_amount": "cod_amount",
    "weight_unit": "weight_unit",
    "purchaseOrderNumber": "purchase_order_number",
    "eccn": "additional_properties.eccn",
    "pieceProductCode": "piece_product_code",
    "hsnCode": "hs_code",
    "hsCode": "additional_properties.hsn_code",
    "isKit": "additional_properties.is_kit",
    "iossNumber": "additional_properties.ioss_number",
    "iossCode": "additional_properties.ioss_number",
    "manufacturingCountry": "manufacturing_country",
    "isDangerousGood": "is_dangerous_good",
    "dangerousGoodAccessible": "dangerous_good_accessible",
    "dangerousGoodUnNumber": "dangerous_good_un_number",
    "dangerousGoodName": "dangerous_good_name",
    "dangerousGoodClass": "dangerous_good_class",
    "dangerousGoodDivision": "dangerous_good_division",
    "dangerousGoodCategory": "dangerous_good_category",
    "dangerousGoodPackingGroup": "dangerous_good_packing_group",
    "dangerousGoodCategoryType": "dangerous_good_category_type",
    "dangerousGoodCategoryPackingInstruction": "dangerous_good_category_packing_instruction",
    "taxDetailsTotalTax": "total_tax",
    "taxDetailsTaxPercentage": "tax_percentage",
    "isCsbv": "is_csbv",
    'taxDetailsIgst': 'igst',
    'taxDetailsCgst': 'cgst',
    'taxDetailsSgst': 'sgst',
    'taxDetailsRecieversGstin': 'recievers_gstin',
    'taxDetailsSendersGstin': 'senders_gstin',
    'forwardReferenceNumber':'forward_reference_number',
    'importReferenceNumber': 'customs_value.import_reference_number',
    'exportReferenceNumber': 'customs_value.export_reference_number'
};

export const consignmentSections = [
    {
        prettyName: 'Primary Details',
        fieldsList: [
            'consignmentCategory',
            'account',
            'consignmentType',
            'referenceNumber',
            'customerReferenceNumber',
            'retailTransactionType',
            'cashonpickup',
        ],
        goTo: 'FIRST',
        icon: PrimaryIcon,
    },
    {
        prettyName: 'Item Details',
        fieldsList: [
            'courierType',
            'dimensionsV1',
        ],
        goTo: 'FIRST',
        icon: ContentsIcon,
    },
    {
        prettyName: 'Consignee Details',
        fieldsList: [
            'consigneeDetails',
        ],
        goTo: 'SECOND',
        icon: AddressIcon,
    },
    {
        prettyName: 'Consignor Details',
        fieldsList: [
            'consignorDetails',
        ],
        goTo: 'THIRD',
        icon: AddressIcon,
    },
    {
        prettyName: 'Services',
        fieldsList: [
            'serviceType',
            'pickupSchedule',
            'pickupTime',
        ],
        goTo: 'FOUR',
        icon: ServicesIcon,
    },
    {
        prettyName: 'Payment Details',
        fieldsList: [
            'isCOD',
            'codAmount',
            'codCollectionMode',
            'codFavorOf',
        ],
        goTo: 'FIFTH',
        icon: PaymentIcon,
    },
];

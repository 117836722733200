import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { commonStyleSheet } from 'library/common-styles';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { useTranslation } from 'react-i18next';
import {
    Col,
    Form,
    FormInstance,
    Input,
} from 'antd';

const styles = (theme: ThemeType) => ({
    referenceItem: {
        display: 'flex',
        flexDirection: 'column',
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    contentType: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'space-between',
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    params: any;
    form: FormInstance;
}

const ConsignmentReferenceNumberField = (props: IProps) => {
    const { t } = useTranslation();
    const { classes, params, form } = props;
    const { getValidationRules, getIsRequiredValidation } = params;
    const { ConsignmentReference } = formFields;
    const isEditingCN = Form.useWatch('isEditingCN', form);

    return (
        <Col span={12}>
            <div className={classes.referenceItem}>
                <div className={classes.referenceTitle}>
                    {t(ConsignmentReference.key)}
                    {getIsRequiredValidation(ConsignmentReference.key) && '*'}
                </div>
                <Form.Item
                    name={ConsignmentReference.key}
                    className={classes.contentType}
                    valuePropName={ConsignmentReference.valuePropName}
                    rules={getValidationRules(ConsignmentReference)}
                >
                    <Input
                        placeholder={t('consignment_number_input')}
                        disabled={isEditingCN}
                    />
                </Form.Item>
            </div>
        </Col>
    );
};

const mapStateToProps = () => {
    return {};
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(ConsignmentReferenceNumberField);

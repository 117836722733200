import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps } from 'theme/jss-types';
import { consigneeFormFields, formFields } from 'components/create-consignment/create-modal.constants';
import {
    Col,
    Form,
    FormInstance,
    Radio,
} from 'antd';
import { formRules } from 'library/constants';
import { useTranslation } from 'react-i18next';

const styles = () => ({});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    form: FormInstance;
    params: any;
}

const DestinationTypeField = (props: IProps) => {
    const { t, i18n } = useTranslation();
    const {
        fieldsToShowInternational,
        form,
        isDestinationTypeRequired,
        params,
    } = props;

    const {
        ConsignmentCategory,
        shipment_purpose,
        ItemType,
    } = formFields;

    const { getValidationRules } = params;
    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };

    const { ConsigneeType } = consigneeFormFields;

    const consignmentCategory = Form.useWatch(ConsignmentCategory.key, form);
    const shipmentPurpose = Form.useWatch(shipment_purpose.key, form);
    const courierType = Form.useWatch(ItemType.key, form);
    const isInternational = consignmentCategory === 'international';

    if (!isInternational) {
        return null;
    }

    if (!fieldsToShowInternational[ConsigneeType.key]) return null;

    const required = isDestinationTypeRequired
    && consignmentCategory === 'international'
    && shipmentPurpose === 'COMMERCIAL'
    && courierType === 'non-document';

    const consigneeTypeOptions = [t('consignee_type_individual'), t('consignee_type_business')];

    return (
        <Col span={12}>
            <Form.Item
                name={ConsigneeType.key}
                rules={required
                    ? [formRuleRequired, ...getValidationRules(ConsigneeType)] : getValidationRules(ConsigneeType)}
            >
                <Radio.Group
                    options={consigneeTypeOptions}
                    onChange={(e) => form.setFieldsValue({
                        destinationType: e.target.value,
                    })}
                />
            </Form.Item>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config } = master;
    const customerPortalConfig = config?.customer_portal_config;
    const fieldsToShowInternational = customerPortalConfig?.fields_to_show_international || {};
    const isDestinationTypeRequired = customerPortalConfig?.mandatory_shipment_commercial?.destinationType;
    return {
        fieldsToShowInternational,
        isDestinationTypeRequired,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(DestinationTypeField);

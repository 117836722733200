import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
    Col,
    Form,
    FormInstance,
    Radio,
} from 'antd';

const styles = () => ({
    referenceItem: {
        display: 'flex',
        flexDirection: 'column',
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    formItem: {
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    form: FormInstance;
    params: any;
}

const ConsignmentTypeField = (props: IProps) => {
    const { t } = useTranslation();
    const {
        classes,
        allowHyperlocalCn,
        isReverseCustomer,
        form,
        params,
    } = props;
    const { MovementType, ConsignmentCategory } = formFields;
    const { isFTL, getValidationRules, getIsRequiredValidation } = params;
    const consignmentCategory = Form.useWatch(ConsignmentCategory.key, form);

    let movementTypeOptions = MovementType.options;
    if (!isFTL) {
        movementTypeOptions = movementTypeOptions?.filter((option) => option.value !== 'ftlhyperlocal');
    }
    const isDomestic = consignmentCategory !== 'international';
    if (isDomestic) {
        if (allowHyperlocalCn === false) {
            movementTypeOptions = movementTypeOptions?.filter(
                (option: any) => option.value !== 'hyperlocal',
            );
        }
        // this handles null case as well, if isReverseCustomer is null, then this condition is true
        if (!(isReverseCustomer !== true)) {
            movementTypeOptions = movementTypeOptions?.filter((option: any) => option.value !== 'forward');
        }
        // this handles null case as well, if isReverseCustomer is null, then this condition is true
        if (!(isReverseCustomer !== false)) {
            movementTypeOptions = movementTypeOptions?.filter((option: any) => option.value !== 'reverse');
        }
        if (isFTL) {
            movementTypeOptions = movementTypeOptions?.filter((option) => option.value === 'ftlhyperlocal');
        }
    } else {
        movementTypeOptions = MovementType.options?.filter((option: any) => option.value === 'forward');
    }
    if (Array.isArray(movementTypeOptions) && movementTypeOptions.length > 0) {
        movementTypeOptions.forEach((item: any) => {
            /* eslint-disable no-param-reassign */
            item.label = t(item.value);
        });
    }

    return (
        <Col span={12}>
            <div className={classes.referenceItem}>
                <div className={classes.referenceTitle}>
                    {t(MovementType.key)}
                    {getIsRequiredValidation(MovementType.key) && '*'}
                </div>
                <Form.Item
                    name={MovementType.key}
                    initialValue={isFTL ? MovementType.defaultValue2 : MovementType.defaultValue}
                    valuePropName={MovementType.valuePropName}
                    rules={getValidationRules(MovementType)}
                    className={classes.formItem}
                >
                    <Radio.Group
                        options={movementTypeOptions}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    />
                </Form.Item>
            </div>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { parts_to_show, Customer } = master;
    return {
        allowHyperlocalCn: get(parts_to_show, 'allow_hyperlocal_cn', false),
        isReverseCustomer: Customer?.is_reverse,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(ConsignmentTypeField);

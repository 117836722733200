import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps } from 'theme/jss-types';
import { getDangerousGoodFieldFromMapping } from
    'components/create-consignment/single-cn-creation/components/generic-sub-layout-renderer';
import {
    FormInstance,
    Row,
} from 'antd';

const styles = () => ({
    wrapper: {
        gap: '10px',
        margin: '5px',
    },
    colWrapper: {
        flexBasis: '49%',
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    form: FormInstance;
    params: any;
}

const DangerousGoodFields = (props: IProps) => {
    const {
        classes,
        form,
        dangerousGoodSubLayout,
        params,
    } = props;

    const { pieceIndex } = params;
    return (
        <div style={{ minWidth: '100%' }}>
            <Row className={classes.wrapper}>
                {(dangerousGoodSubLayout || []).map(
                    (fieldKey: any) => getDangerousGoodFieldFromMapping(fieldKey, form, { ...params, pieceIndex }),
                )}
            </Row>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config } = master;
    const formSubLayout = config?.customer_portal_order_creation_config?.sub_layout || {};
    const dangerousGoodSubLayout = formSubLayout?.dangerous_good || [];
    return {
        dangerousGoodSubLayout,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(DangerousGoodFields);

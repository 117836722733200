import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { commonStyleSheet } from 'library/common-styles';
import { formRules } from 'library/constants';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { useTranslation } from 'react-i18next';
import {
    Col,
    Form,
    FormInstance,
    Input,
} from 'antd';

const styles = (theme: ThemeType) => ({
    referenceItem: {
        display: 'flex',
        flexDirection: 'column',
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    contentType: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'space-between',
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    form: FormInstance;
    params: any;
}

const CashPickupAmountField = (props: IProps) => {
    const { t, i18n } = useTranslation();
    const {
        classes, isCopApplicable, form, params,
    } = props;
    const { CashonPickup, RetailTransaction } = formFields;

    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };

    const { getValidationRules, getIsRequiredValidation } = params;
    const retailTransactionType = Form.useWatch(RetailTransaction.key, form);
    const exceptThisSymbols = ['e', 'E', '+', '-', '.'];
    if (isCopApplicable) {
        return (
            <Col span={12}>
                <div className={classes.referenceItem}>
                    <div className={classes.referenceTitle}>
                        {t(CashonPickup.key)}
                        {getIsRequiredValidation(CashonPickup.key) && '*'}
                    </div>
                    <Form.Item
                        name={CashonPickup.key}
                        className={classes.contentType}
                        valuePropName={CashonPickup.valuePropName}
                        rules={retailTransactionType === 'retailTransactionTrue'
                            ? [formRuleRequired, ...getValidationRules(CashonPickup)] : getValidationRules(CashonPickup)}
                    >
                        <Input
                            type="number"
                            placeholder={CashonPickup.placeholder}
                            onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                            style={{ width: CashonPickup.width }}
                            addonAfter="INR"
                        />
                    </Form.Item>
                </div>
            </Col>
        );
    }
    return <></>;
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { parts_to_show } = master;
    return {
        isCopApplicable: parts_to_show?.is_cop_applicable,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(CashPickupAmountField);

import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import {
    Col,
    Form,
    FormInstance,
    Select,
} from 'antd';
import moment from 'moment';
import { getTimeSlots } from 'network/consignments.api';
import Helper from 'library/Helper';
import { TimeSlot } from 'library/Types';
import { useTranslation } from 'react-i18next';

const styles = () => ({
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    form: FormInstance;
    params: any;
}

const PickupTimeField = (props: IProps) => {
    const {
        form,
        showScheduleOnlyWhenHyperlocal,
        classes,
        params,
    } = props;

    const {
        MovementType,
        PickupSchedule,
        PickupTime,
    } = formFields;

    const { getValidationRules, getIsRequiredValidation } = params;

    const { t } = useTranslation();
    const consignmentType = Form.useWatch(MovementType.key, form);
    const pickupDate = Form.useWatch(PickupSchedule.key, form);
    const field = PickupTime;
    const [timeSlots, setTimeSlots] = React.useState<TimeSlot[]>([]);

    const loadTimeSlots = async () => {
        const response = await getTimeSlots({
            pickupDate: moment(pickupDate).format('DD/MM/YYYY').toString(),
        });
        setTimeSlots(Helper.isValidArray(response?.data) ? response?.data : []);
    };

    React.useEffect(() => {
        loadTimeSlots();
    }, [pickupDate]);

    if (showScheduleOnlyWhenHyperlocal && consignmentType !== 'hyperlocal') {
        return null;
    }

    return (
        <Col span={12}>
            <div className={classes.title}>
                {t(field.key)}
                <span>{getIsRequiredValidation(field.key) ? '*' : null}</span>
            </div>
            <Form.Item
                name={field.key}
                key={field.key}
                initialValue={field.defaultValue}
                valuePropName={field.valuePropName}
                rules={getValidationRules(field)}
            >
                <Select
                    placeholder="Time Slot"
                    style={{ width: field.width }}
                    disabled={pickupDate === null}
                >
                    {timeSlots.map((item) => {
                        const name = `${item.start} - ${item.end}`;
                        return (
                            <Select.Option key={name} value={name}>
                                {name}
                            </Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config } = master;
    const consignmentConfig = config?.customer_portal_consignment_config;
    const partsToShowSingleConsignment = consignmentConfig?.parts_to_show_single_consignment;
    const showScheduleOnlyWhenHyperlocal = partsToShowSingleConsignment?.scheduleOnlyWhenHyperlocal;
    return {
        showScheduleOnlyWhenHyperlocal,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(PickupTimeField);

import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { commonStyleSheet } from 'library/common-styles';
import { formRules } from 'library/constants';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { get } from 'lodash';
import { Col, Form, Input } from 'antd';

const styles = (theme: ThemeType) => ({
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
    contentType: {
        ...commonStyleSheet(theme).flexRow,
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
    formItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        marginBottom: 16,
        '& .ant-checkbox + span': {
            color: '#111111',
            fontSize: 14,
            fontWeight: 600,
        },
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    params: any;
}

const EWBNumberField = (props: IProps) => {
    const { classes, showEwb, params } = props;
    const { isRequired, getValidationRules, getIsRequiredValidation } = params;
    const { EwbNumber } = formFields;

    if (!showEwb) {
        return null;
    }

    const rules: any[] = [...getValidationRules(EwbNumber), formRules.pattern];

    rules.push({
        pattern: /^[A-Za-z0-9]{12}$/,
        message: 'EWB Number should be alphanumeric & length should be 12',
    });

    return (
        <Col span={12}>
            <div className={classes.formItem}>
                <div className={classes.referenceTitle}>
                    {EwbNumber.label}
                    {isRequired(false, EwbNumber.key) || getIsRequiredValidation(EwbNumber.key) ? '*' : null}
                </div>
                <Form.Item
                    name={EwbNumber.key}
                    className={classes.contentType}
                    valuePropName={EwbNumber.valuePropName}
                    rules={rules}
                >
                    <Input
                        placeholder={EwbNumber.placeholder}
                    />
                </Form.Item>
            </div>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config } = master;

    return {
        showEwb: get(config, 'customer_portal_config.show_ewb_fields', false),
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(EWBNumberField);

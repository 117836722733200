import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { commonStyleSheet } from 'library/common-styles';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
    Col,
    Form,
    FormInstance,
    Radio,
} from 'antd';

const styles = (theme: ThemeType) => ({
    referenceItem: {
        display: 'flex',
        flexDirection: 'column',
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    deliveryType: {
        ...commonStyleSheet(theme).flexColumn,
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    form: FormInstance;
    params: any;
}

const DeliveryTypeField = (props: IProps) => {
    const { t } = useTranslation();
    const {
        classes,
        showDeliveryType,
        form,
        params,
    } = props;
    const { DeliveryType, PudoStores } = formFields;
    const { getValidationRules, getIsRequiredValidation } = params;

    if (!showDeliveryType) return <></>;

    if (Array.isArray(DeliveryType.options) && DeliveryType.options.length > 0) {
        DeliveryType.options.forEach((item: any) => {
            /* eslint-disable no-param-reassign */
            item.label = t(item.value);
        });
    }

    return (
        <Col span={12}>
            <div className={classes.deliveryType}>
                <div className={classes.referenceTitle}>
                    {t(DeliveryType.key)}
                    {getIsRequiredValidation(DeliveryType.key) && '*'}
                </div>
                <Form.Item
                    name={DeliveryType.key}
                    initialValue={DeliveryType.defaultValue}
                    valuePropName={DeliveryType.valuePropName}
                    rules={getValidationRules(DeliveryType)}
                >
                    <Radio.Group
                        options={DeliveryType.options}
                        onChange={(e) => {
                            const deliveryTypeSelected = e?.target?.value;

                            if (deliveryTypeSelected === 'HOME_DELIVERY') {
                                form.setFieldsValue({
                                    [PudoStores.key]: '',
                                });
                            }
                        }}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    />
                </Form.Item>
            </div>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { parts_to_show } = master;
    return {
        showDeliveryType: get(parts_to_show, 'show_delivery_type_in_customerportal', false),
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(DeliveryTypeField);

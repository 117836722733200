import React, { useState } from 'react';
import { Drawer, Button, message } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import Cross from '../../assets/cross';
import Helper from '../../library/Helper';
import { bulkPickupRule, downloadSampleSlot } from '../../network/pickup.api';
import { StylesProps } from '../../theme/jss-types';

const bulkPickupSlotsStyles = () => ({
    main: {
        marginTop: '-5px !important',
        height: '100vh !important',

    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
    },
    addText: {
        display: 'flex',
        alignItems: 'center',
    },
    closeIcon: {
        cursor: 'pointer',
        marginRight: '10px',
    },
    uploadBox: {
        margin: '20px 0',
        textAlign: 'center',
        '& .ant-upload-drag': {
            border: '2px dashed #000',
            height: '200px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    uploadText: {
        marginTop: 8,
        color: '#000',
        fontWeight: 'bold',
    },
    uploadHint: {
        fontSize: '12px',
        color: '#888',
    },
    downloadBtn: {
        color: '#e0e0e0',
        display: 'block',
        border: '1px solid #000',
        margin: '0 auto',
        backgroundColor: '#e0e0e0',
        '& .anticon': {
            marginRight: '8px',
        },
    },
    removeButton: {
        marginTop: '15px',
        backgroundColor: '#ff4d4f',
        color: 'white',
        border: 'none',
        cursor: 'pointer',
    },
    browseButton: {
        border: '1px solid #000',
        backgroundColor: '#e0e0e0',
        color: '#3d3d3d',
    },
});

interface BulkPickupSlotsProps extends StylesProps<ReturnType<typeof bulkPickupSlotsStyles>> {
    isModalVisible: boolean;
    handleCancel: () => void;
    setTableLoading: (value: boolean) => void;
    fetchPickupRules: () => void;
}

const mapping: Record<string, string> = {
    'Pickup day': 'pickupDay',
    'Pickup time slot': 'pickupTimeSlot',
    'Company name': 'companyName',
    'Address line 1': 'addressLine1',
    'Address line 2': 'addressLine2',
    'Pin Code': 'pinCode',
    City: 'city',
    State: 'state',
    Country: 'country',
    Latitude: 'latitude',
    Longitude: 'longitude',
    'Mobile number': 'mobileNumber',
    'Alternate mobile number': 'alternateMobileNumber',
};

const transformPickupSlotsData = (rows: any[]): any[] => {
    if (!rows || rows.length === 0) return [];
    const headers = rows[0];
    const dataRows = rows.slice(1);

    return dataRows.map((row: Record<string, any>) => {
        const intermediate: Record<string, any> = {};
        Object.keys(row).forEach((key) => {
            const headerName = headers[key];
            const mappedKey = mapping[headerName];
            if (mappedKey) {
                if (mappedKey === 'pickupTimeSlot') {
                    const times = row[key] ? row[key].split('-').map((t: string) => t.trim()) : [];
                    intermediate.pickupStart = times[0] || '';
                    intermediate.pickupEnd = times[1] || '';
                } else {
                    intermediate[mappedKey] = row[key];
                }
            }
        });
        return {
            address: {
                addressLine1: intermediate.addressLine1 || '',
                addressLine2: intermediate.addressLine2 || '',
                cityName: intermediate.city || '',
                stateName: intermediate.state || '',
                countryName: intermediate.country || '',
                phone: intermediate.mobileNumber || '',
                pincode: intermediate.pinCode || '',
            },
            day: intermediate.pickupDay || '',
            pickupStart: intermediate.pickupStart || '',
            pickupEnd: intermediate.pickupEnd || '',
        };
    });
};

const BulkPickupSlots = (props: BulkPickupSlotsProps) => {
    const {
        classes, isModalVisible, handleCancel, setTableLoading, fetchPickupRules,
    } = props;
    const { t } = useTranslation();
    const [pickupSlots, setPickupSlots] = useState<any[]>([]);
    const [creating, setCreating] = useState<boolean>(false);
    const [uploadedFile, setUploadedFile] = useState<any | null>(null);
    const [fileList, setFileList] = useState<any[]>([]);

    const handleFileReceived = async (file: any) => {
        try {
            const workbookData = await Helper.handleXLSXFile(file, Object.keys(mapping), 0);
            const parsedData = transformPickupSlotsData(workbookData[0] || []);
            setPickupSlots(parsedData);
            setUploadedFile(file);
        } catch (error) {
            message.error(t('error_parsing_file') || 'Error processing file');
        }
    };

    const handleRemoveFile = () => {
        setUploadedFile(null);
        setPickupSlots([]);
        setFileList([]);
    };

    const customRequest = ({ onSuccess, file }: any) => {
        setTimeout(() => {
            onSuccess(null, file);
        }, 100);
    };

    const handleUploadChange = (info: any) => {
        const { status } = info.file;
        let newFileList = [...info.fileList];
        newFileList = newFileList.slice(-1);
        setFileList(newFileList);
        if (status === 'done') {
            handleFileReceived(info.file.originFileObj);
        }
    };

    const handleSubmit = async () => {
        if (!pickupSlots.length) {
            message.warning(t('no_pickup_slot_data') || 'No pickup slot data to submit');
            return;
        }
        setCreating(true);
        try {
            setTableLoading(true);
            const response = await bulkPickupRule(pickupSlots);
            if (response.isSuccess) {
                message.success(t('bulk_pickup_rule_created') || 'Bulk pickup rule created successfully!');
                await fetchPickupRules();
                handleCancel();
                handleRemoveFile();
            } else {
                // eslint-disable-next-line max-len
                message.error(response.errorMessage || t('failed_to_create_bulk_pickup_rule') || 'Failed to create bulk pickup rule');
            }
            setTableLoading(false);
        } catch (error) {
            message.error(t('error_submitting_pickup_rule') || 'Error submitting pickup rule');
        }
        setCreating(false);
    };

    const handleDownload = async () => {
        try {
            const fileBuffer = await downloadSampleSlot();
            const fileName = fileBuffer.fileName || 'sample-pickup-upload.xlsx';
            if (fileBuffer.isSuccess) {
                Helper.downloadFileData(fileBuffer.data, fileName, true);
                message.success(t('downloaded_successfully') || 'Downloaded Successfully');
            } else {
                message.error(fileBuffer.errorMessage || t('error_downloading_file') || 'Error downloading file');
            }
        } catch (error) {
            message.error(t('error_downloading_sample_file') || 'Error downloading sample file');
        }
    };

    const renderHeader = () => (
        <div className={classes.header}>
            <div className={classes.addText}>
                <Cross
                    onClick={() => {
                        handleRemoveFile();
                        handleCancel();
                    }}
                    alt="close"
                    className={classes.closeIcon}
                />
                <span>{t('add_pickup_slot') || 'Add pickup slot'}</span>
            </div>
            <Button
                type="primary"
                loading={creating}
                disabled={!pickupSlots.length}
                onClick={handleSubmit}
            >
                {t('submit') || 'Submit'}
            </Button>
        </div>
    );

    const renderUpload = () => (
        <div className={classes.uploadBox}>
            <Dragger
                fileList={fileList}
                multiple={false}
                accept=".xlsx, .xls, .csv"
                name="file"
                disabled={uploadedFile !== null}
                onChange={handleUploadChange}
                customRequest={customRequest}
            >
                <p className={classes.uploadText}>
                    <UploadOutlined style={{ marginRight: 8 }} />
                    {t('upload_excel_csv') || 'Upload Excel/CSV file'}
                </p>
                <p className={classes.uploadHint}>
                    {t('drag_and_drop_text') || 'Drag and drop to upload or'}
                    {t('or') || 'or'}
                    <Button type="link" className={classes.browseButton}>
                        {t('browse') || 'Browse'}
                    </Button>
                    {t('to_choose_file') || 'to choose file'}
                </p>
            </Dragger>

            {uploadedFile && (
                <Button className={classes.removeButton} onClick={handleRemoveFile}>
                    {t('remove_uploaded_file') || 'Remove uploaded file'}
                </Button>
            )}
        </div>
    );

    const renderDownload = () => (
        <Button type="link" className={classes.downloadBtn} onClick={handleDownload}>
            <DownloadOutlined />
            {t('download_sample_file') || 'Download Sample File'}
        </Button>
    );

    return (
        <Drawer
            visible={isModalVisible}
            width="35%"
            title={renderHeader()}
            onClose={
                () => {
                    handleRemoveFile();
                    handleCancel();
                }
            }
            className={classes.main}
            closable={false}
        >
            {renderUpload()}
            {renderDownload()}
        </Drawer>
    );
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: bulkPickupSlotsStyles,
    },
    connectRedux: {
        useRedux: true,
    },
    connectRouter: true,
    connectTranslession: true,
};

export default GenericHoc(hocConfig)(BulkPickupSlots);

/* eslint-disable react/jsx-closing-tag-location */
import * as React from 'react';

const walletFaqList = (t: any) => [{
    question: t('virtual_wallet_faq_question_1'),
    answer: <div>
        {t('virtual_wallet_faq_answer_1')}
    </div>,
    answerText: t('virtual_wallet_faq_answer_1'),
}, {
    question: t('virtual_wallet_faq_question_2'),
    answer: <div>
        {t('virtual_wallet_faq_answer_2')}
    </div>,
    answerText: t('virtual_wallet_faq_answer_2'),
}, {
    question: t('virtual_wallet_faq_question_3'),
    answer: <div>
        {t('virtual_wallet_faq_answer_3')}
    </div>,
    answerText: t('virtual_wallet_faq_answer_3'),
}, {
    question: t('virtual_wallet_faq_question_4'),
    answer: <div>
        {t('virtual_wallet_faq_answer_4')}
    </div>,
    answerText: t('virtual_wallet_faq_answer_4'),
}, {
    question: t('virtual_wallet_faq_question_5'),
    answer: <div>
        {t('virtual_wallet_faq_answer_5')}
    </div>,
    answerText: t('virtual_wallet_faq_answer_5'),
}, {
    question: t('virtual_wallet_faq_question_6'),
    answer: <div>
        {t('virtual_wallet_faq_answer_6')}
    </div>,
    answerText: t('virtual_wallet_faq_answer_6'),
}, {
    question: t('virtual_wallet_faq_question_7'),
    answer: <div>
        {t('virtual_wallet_faq_answer_7')}
    </div>,
    answerText: t('virtual_wallet_faq_answer_7'),
}, {
    question: t('virtual_wallet_faq_question_8'),
    answer: <div>
        {t('virtual_wallet_faq_answer_8')}
    </div>,
    answerText: t('virtual_wallet_faq_answer_8'),
}, {
    question: t('virtual_wallet_faq_question_9'),
    answer: <div>
        {t('virtual_wallet_faq_answer_9')}
    </div>,
    answerText: t('virtual_wallet_faq_answer_9'),
}, {
    question: t('virtual_wallet_faq_question_10'),
    answer: <div>
        {t('virtual_wallet_faq_answer_10')}
        <ul>
            <li>{t('virtual_wallet_faq_answer_10_point_1')}</li>
            <li>{t('virtual_wallet_faq_answer_10_point_2')}</li>
            <li>{t('virtual_wallet_faq_answer_10_point_3')}</li>
        </ul>
    </div>,
    answerText: `${t('virtual_wallet_faq_answer_10')}
    - ${t('virtual_wallet_faq_answer_10_point_1')}
    - ${t('virtual_wallet_faq_answer_10_point_2')}
    - ${t('virtual_wallet_faq_answer_10_point_3')}`,
}];

export {
    walletFaqList,
};

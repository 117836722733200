import React, { useEffect, useRef } from 'react';
import styles from './newUIBannerButton.module.css';
import { useTranslation } from 'react-i18next';

interface NewUIBannerButtonProps {
    handleNewUIBannerVisible: () => void,
}

const NewUIBannerButton: React.FC<NewUIBannerButtonProps> = ({ handleNewUIBannerVisible }) => {
    const rocketRef = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        const rocket = rocketRef.current;

        // Rocket floating animation
        const rocketAnimation = rocket.animate([
            { transform: 'translateY(0) rotate(0deg)' },
            { transform: 'translateY(-5px) rotate(5deg)' },
            { transform: 'translateY(0) rotate(0deg)' },
        ], {
            duration: 2000,
            iterations: Infinity,
            easing: 'ease-in-out',
        });

        return () => {
            rocketAnimation.cancel();
        };
    }, []);

    return (
        <div
            className={styles.container}
            onClick={handleNewUIBannerVisible}
        >
            <div className={styles.backgroundShape1} />
            <div className={styles.backgroundShape2} />

            <div className={styles.animatedText}>
                <div className={styles.newLook}>
                    <span ref={rocketRef} className={styles.rocket}>🚀</span>
                    {t('new_look')}
                </div>
                <div className={styles.comingSoon}>
                    {t('coming_soon')}
                </div>
            </div>
        </div>
    );
};

export default NewUIBannerButton;

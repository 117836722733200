import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { StylesProps } from '../../theme/jss-types';
import {
    ProfileOutlined,
    SearchOutlined,
    LogoutOutlined,
    LoginOutlined,
    UserOutlined,
} from '@ant-design/icons';
import Loader from './Loader';
import {
    Button, Dropdown, Input, Menu, Modal, Tooltip, Select,
} from 'antd';
import PincodeService from '../pincode-service/service-availability';
import { Pincodes } from '../../library/Types';
import TrackConsignment from '../track-consignment/track-consignment';
import authHelper from '../../auth/auth-helper';
import PicodeAvailablityIcon from '../../assets/pincode-availability';
import { HocOptions } from './generic-hoc.types';
import GenericHoc from './generic-hoc';
import { navbarStyles } from './navbar.styles';
import { RouteComponentProps } from 'react-router';
import { getSettingsAccountRoute } from 'routing/routing-helper';
import { ReduxStore } from 'reducers/redux.types';
import { Master } from 'types/master-data-types';
import Helper from 'library/Helper';
import { useTranslation } from 'react-i18next';
import RateCalculator from 'components/pincode-service/rate-calculator';
import RateIcon from 'assets/rate-svg';
import { isSuperUserEmployee } from 'auth/local-storage';
import ChangeCustomer from './change-customer';
import { TrackTypeEnum } from 'types/view.types';

interface NavbarProps extends StylesProps<ReturnType<typeof navbarStyles>>, RouteComponentProps {
    pageTitle: string;
    master: Master;
}

const Navbar = (props: NavbarProps) => {
    const {
        pageTitle,
        classes,
        history,
        master,
    } = props;
    const { t } = useTranslation();
    const [pincodeVisible, setPincodeVisible] = React.useState<boolean>(false);
    const [pincodeDrawer, setPincodeDrawer] = React.useState(false);
    const [showChangeCustomer, setChangeCustomerModal] = React.useState(false);
    const [trackVisible, setTrackVisible] = React.useState<string>();
    const [trackQuery, setTrackQuery] = React.useState<string>();
    const [pincodes, setPincodes] = React.useState<Pincodes>({});
    const [rateVisible, setRateVisible] = React.useState<boolean>(false);
    const [trackType, setTrackType] = React.useState<string>('Consignment Number');
    const [loading, setLoading] = React.useState(false);

    const oldBaseURL = master?.config?.customer_portal_config?.old_cp_base_url;

    const show_rate_calculator = master?.config?.customer_portal_config?.show_rate_calculator;
    const show_old_portal_link = master?.config?.customer_portal_config?.show_old_portal_link;

    const customerProperties = master?.properties;
    const CustomerUserDetails = master?.CustomerUserDetails;
    const enable_customer_config = master?.parts_to_show?.enable_customer_config || false;
    const disable_customer_reference_number_search = master?.properties?.disable_customer_reference_number_search;
    const search_carrier_awb_number = master?.properties?.search_carrier_awb_number;
    let showCnTracking = true;
    let showPincodeServiceability = true;
    let filterServicesWithoutTat = false;
    if (customerProperties && enable_customer_config) {
        showCnTracking = customerProperties?.cn_tracking;
        showPincodeServiceability = customerProperties?.pincode_serviceability;
        filterServicesWithoutTat = customerProperties?.filter_services_without_tat;
    }

    const {
        username,
        logoUrl,
    } = authHelper.getUsernameAndLogo();

    const isSuperUser = isSuperUserEmployee();

    const handleLogout = async () => {
        setLoading(true);
        await authHelper.logoutUser();
        setLoading(false);
    };

    const menu = (
        <Menu>
            <Menu.Item
                key="profile"
                onClick={() => history.push(getSettingsAccountRoute())}
                icon={<ProfileOutlined />}
            >
                {t('profile')}
            </Menu.Item>
            {show_old_portal_link && (
                <Menu.Item
                    key="old_cp"
                    onClick={() => Helper.redirectToOldPortal(oldBaseURL)}
                    icon={<LoginOutlined />}
                >
                    {t('legacy_customer_portal')}
                </Menu.Item>
            )}
            {isSuperUser && (
                <Menu.Item
                    key="change_customer"
                    onClick={() => setChangeCustomerModal(true)}
                    icon={<UserOutlined />}
                >
                    {t('change_customer')}
                </Menu.Item>
            )}
            <Menu.Item
                key="logout"
                icon={<LogoutOutlined />}
                onClick={handleLogout}
                disabled={loading}
            >
                {t('logout')}
            </Menu.Item>
        </Menu>
    );

    const renderProfile = () => {
        return (
            <div className={classes.circle}>
                <div className={classes.profile}>
                    {username && username[0].toUpperCase()}
                </div>
            </div>
        );
    };

    const renderDropdown = () => {
        const displayUsername = CustomerUserDetails?.is_customer_user
            ? CustomerUserDetails.username
            : username;
        return (
            <Dropdown
                overlay={menu}
                trigger={['hover']}
            >
                <Button
                    type="link"
                    className={classes.username}
                >
                    <div className={classes.name}>{displayUsername}</div>
                    {renderProfile()}
                </Button>
            </Dropdown>
        );
    };

    const renderSelect = () => {
        let ioptions = [
            {
                value: TrackTypeEnum.CONSIGNMENT,
                label: t(TrackTypeEnum.CONSIGNMENT),
            },
            {
                value: TrackTypeEnum.CUSTOMER_REFERENCE_NO,
                label: t(TrackTypeEnum.CUSTOMER_REFERENCE_NO),
            },
        ];
        if (search_carrier_awb_number) {
            ioptions = [
                ...ioptions,
                {
                    value: TrackTypeEnum.CARRIER_AWB_NUMBER,
                    label: TrackTypeEnum.CARRIER_AWB_NUMBER,
                },
            ];
        }
        if (disable_customer_reference_number_search) {
            ioptions = ioptions.filter((option) => option.value !== TrackTypeEnum.CUSTOMER_REFERENCE_NO);
        }
        return (
            <Select
                className={classes.trackDropDown}
                defaultValue={trackType}
                onChange={(value: string) => setTrackType(value)}
                dropdownStyle={{ borderRadius: '100px !important' }}
                options={ioptions}
            />
        );
    };

    const renderInput = () => {
        if (showCnTracking) {
            let placeholder;
            switch (trackType) {
                case TrackTypeEnum.CUSTOMER_REFERENCE_NO:
                    placeholder = t('track_customer_reference_number');
                    break;
                case TrackTypeEnum.CARRIER_AWB_NUMBER:
                    placeholder = t('track_carrier_awb_number');
                    break;
                default:
                    placeholder = t('track_consignment');
                    break;
            }
            return (
                <>
                    <div>
                        {renderSelect()}
                    </div>
                    <Input
                        className={classes.trackInput}
                        onPressEnter={() => setTrackVisible(trackQuery)}
                        type="search"
                        value={trackVisible as string}
                        onChange={(e: any) => setTrackQuery(e.target.value)}
                        placeholder={placeholder}
                        suffix={<SearchOutlined onClick={() => setTrackVisible(trackQuery)} />}
                    />
                </>
            );
        }
        return null;
    };

    const handleOpenDrawer = (e: any) => {
        e.stopPropagation();
        setPincodeVisible(false);
        setPincodeDrawer(true);
    };

    const disableButton = () => {
        return (
            pincodes.dstPincode?.length !== 6
            || pincodes.srcPincode?.length !== 6
        );
    };

    const renderPincodeInputs = () => {
        const handleChange = (field: 'srcPincode' | 'dstPincode', value: string) => {
            const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '');
            setPincodes((prev) => ({ ...prev, [field]: sanitizedValue }));
        };
        return (
            <div className={classes.inputs}>
                <Input
                    placeholder={t('origin_pin')}
                    value={pincodes.srcPincode}
                    className={classes.input}
                    onChange={(e) => handleChange('srcPincode', e.target.value)}
                />
                <Input
                    placeholder={t('destination_pin')}
                    value={pincodes.dstPincode}
                    className={classes.input}
                    onChange={(e) => handleChange('dstPincode', e.target.value)}
                />
                <Button
                    type="primary"
                    onClick={(e) => handleOpenDrawer(e)}
                    disabled={disableButton()}
                >
                    {t('check')}
                </Button>
            </div>
        );
    };

    const renderAvailability = () => {
        return (
            <div className={classes.pincode}>
                <div className={classes.availabilityTitle}>
                    {t('pincode_serviceability')}
                </div>
                {renderPincodeInputs()}
            </div>
        );
    };

    const handleClose = (e: any) => {
        e.stopPropagation();
        setPincodeVisible(false);
    };

    const renderPincodeModal = () => {
        return (
            <Modal
                visible={pincodeVisible}
                width="10%"
                title={null}
                footer={null}
                closable={false}
                maskClosable
                onCancel={(e) => handleClose(e)}
                className={classes.modal}
                maskStyle={{
                    backgroundColor: 'rgba(0, 0, 0, 0.25)',
                }}
            >
                {renderAvailability()}
            </Modal>
        );
    };

    const renderPincode = () => {
        if (master?.config?.customer_portal_consignment_config?.is_international_client) return null;
        if (showPincodeServiceability) {
            return (
                <div
                    onClick={() => setPincodeVisible(true)}
                    className={classes.img}
                >
                    <Tooltip title={t('check_pincode_serviceability')}>
                        <PicodeAvailablityIcon />
                    </Tooltip>
                    {renderPincodeModal()}
                </div>
            );
        }
        return null;
    };

    const handleCloseForm = () => {
        setPincodeDrawer(false);
        setTrackVisible(undefined);
        setPincodes({});
    };

    const renderTrack = () => {
        if (!trackVisible) {
            return null;
        }
        return (
            <TrackConsignment
                referenceNumber={trackVisible as string}
                onClose={handleCloseForm}
                trackType={trackType as string}
            />
        );
    };

    const renderPincodeDrawer = () => {
        if (!pincodeDrawer) {
            return null;
        }
        return (
            <PincodeService
                onClose={handleCloseForm}
                pincodes={pincodes}
                filterServicesWithoutTat={filterServicesWithoutTat}
            />
        );
    };

    const renderRateCalulator = () => {
        if (!rateVisible) {
            return null;
        }
        return (
            <RateCalculator
                onClose={() => setRateVisible(false)}
            />
        );
    };

    const renderRateCalulatorButton = () => {
        if (!show_rate_calculator) {
            return null;
        }
        return (
            <div
                className={classes.rateCalculator}
                onClick={() => setRateVisible(!rateVisible)}
            >
                <RateIcon style={{ margin: '0px 4px' }} />
                <span> Rate Calculator </span>
            </div>
        );
    };

    const renderChangeCustomer = () => {
        if (!showChangeCustomer) {
            return null;
        }
        return (
            <ChangeCustomer
                onClose={() => setChangeCustomerModal(false)}
            />
        );
    };

    return (
        <div className={classes.mainDiv}>
            {loading && <Loader zIndex={10} />}
            <DocumentTitle title={pageTitle} />
            <div className={classes.logoBox}>
                <img
                    alt="logo-url"
                    src={logoUrl as string}
                    style={{
                        height: '100%',
                    }}
                />
            </div>
            <div className={classes.rightBox}>
                {renderRateCalulatorButton()}
                {renderPincode()}
                {renderInput()}
                {renderDropdown()}
            </div>
            {renderPincodeDrawer()}
            {renderTrack()}
            {renderRateCalulator()}
            {renderChangeCustomer()}
        </div>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const { master } = state;
    return {
        master,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: navbarStyles,
    },
    connectRouter: true,
};

export default GenericHoc(hocConfig)(Navbar);

import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { useTranslation } from 'react-i18next';
import {
    Col,
    Form,
    FormInstance,
    Input,
} from 'antd';
import { commonStyleSheet } from 'library/common-styles';

const styles = (theme: ThemeType) => ({
    formItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        '& .ant-checkbox + span': {
            color: '#111111',
            fontSize: 14,
            fontWeight: 600,
        },
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    contentType: {
        ...commonStyleSheet(theme).flexRow,
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    params: any;
    form: FormInstance;
    shouldNotMakeItemTypeCheck: boolean;
}

const PieceReferenceNumberField = (props: IProps) => {
    const { t } = useTranslation();
    const {
        classes,
        params,
        separateChildCN,
        form,
        shouldNotMakeItemTypeCheck,
    } = props;
    const {
        ReferenceNumber,
        ItemType,
    } = formFields;
    const {
        key,
        isRequired,
        getValidationRules,
        getIsRequiredValidation,
    } = params;

    const itemType = Form.useWatch(ItemType.key, form);

    if (!shouldNotMakeItemTypeCheck && itemType !== 'non-document') {
        return <></>;
    }

    let shouldFieldBeDisabled = true;
    if (!separateChildCN) {
        shouldFieldBeDisabled = false;
    }

    return (
        <Col span={12}>
            <div className={classes.formItem}>
                <div
                    className={classes.title}
                    style={{ width: ReferenceNumber.width }}
                >
                    {t('piece_reference_number')}
                    {isRequired(true, ReferenceNumber.key)
                        || getIsRequiredValidation(ReferenceNumber.key, true) ? '*' : ''}
                </div>
                <Form.Item
                    name={key}
                    className={classes.contentType}
                    initialValue={ReferenceNumber.defaultValue}
                    valuePropName={ReferenceNumber.valuePropName}
                    rules={getValidationRules(ReferenceNumber, true)}
                >
                    <Input
                        disabled={shouldFieldBeDisabled}
                        placeholder={t('piece_reference_number')}
                    />
                </Form.Item>
            </div>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { parts_to_show } = master;
    const separateChildCN = parts_to_show?.separate_child_cns;
    return {
        separateChildCN,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(PieceReferenceNumberField);

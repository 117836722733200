import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { useTranslation } from 'react-i18next';
import { formRules } from 'library/constants';
import { FormField, InputTypes } from 'components/create-consignment/create-consignment.types';
import { StylesProps } from 'theme/jss-types';
import {
    Form,
    Select,
    Col,
    Input,
    FormInstance,
    Radio,
} from 'antd';

const styles = () => ({
    wrapper: {
        gap: '10px',
        margin: '5px',
    },
    colWrapper: {
        flexBasis: '49%',
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    field: FormField;
    form: FormInstance;
    params: any;
}

const DangerousGoodSubLayoutFields = (props: IProps) => {
    const { t, i18n } = useTranslation();
    const {
        field,
        classes,
        form,
        params,
    } = props;

    const { pieceIndex, getValidationRules, getIsRequiredValidation } = params;
    const isPieceLevel = pieceIndex !== null && pieceIndex !== undefined;
    const options = field.options;

    if (Array.isArray(options) && options.length > 0) {
        options.forEach((item: any) => {
            /* eslint-disable no-param-reassign */
            item.label = i18n.exists(item.value) ? t(item.value) : item.label;
        });
    }
    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };

    const renderRadio = () => {
        return (
            <Col span={12} className={classes.colWrapper}>
                <div>
                    <div
                        className={classes.title}
                        style={{ marginBottom: '5px' }}
                    >
                        {t(field.label)}
                        {getIsRequiredValidation(field.key, isPieceLevel) && '*'}
                    </div>
                    <Form.Item
                        name={isPieceLevel ? `${field.key}_${pieceIndex}` : field.key}
                        initialValue={field.defaultValue}
                        valuePropName={field.valuePropName}
                        rules={field.validate
                            ? [formRuleRequired, ...getValidationRules(field, isPieceLevel)]
                            : getValidationRules(field, isPieceLevel)}
                    >
                        <Radio.Group
                            options={field.options?.map((option) => {
                                return {
                                    label: t(option.label),
                                    value: option.value,
                                };
                            })}
                        />
                    </Form.Item>
                </div>
            </Col>
        );
    };

    const renderText = () => {
        return (
            <Col span={12} className={classes.colWrapper}>
                <div>
                    <div
                        className={classes.title}
                        style={{ marginBottom: '5px' }}
                    >
                        {t(field.label)}
                    </div>
                    <Form.Item
                        name={isPieceLevel ? `${field.key}_${pieceIndex}` : field.key}
                        initialValue={field.defaultValue}
                        valuePropName={field.valuePropName}
                        rules={field.validate ? [formRuleRequired] : undefined}
                    >
                        <Input
                            placeholder={t(field.key)}
                        />
                    </Form.Item>
                </div>
            </Col>
        );
    };

    const renderSelect = () => {
        return (
            <Col span={12} className={classes.colWrapper}>
                <div>
                    <div
                        className={classes.title}
                        style={{ marginBottom: '5px' }}
                    >
                        {t(field.label)}
                    </div>
                    <Form.Item
                        name={isPieceLevel ? `${field.key}_${pieceIndex}` : field.key}
                        initialValue={field.defaultValue}
                        valuePropName={field.valuePropName}
                        rules={field.validate ? [formRuleRequired] : undefined}
                    >
                        <Select
                            disabled={field.disabled}
                            placeholder={t(field.key)}
                            options={options}
                            allowClear
                            onChange={(e) => form.setFieldsValue({
                                [isPieceLevel ? `${field.key}_${pieceIndex}` : field.key]: e,
                            })}
                        />
                    </Form.Item>
                </div>
            </Col>
        );
    };

    const getField = () => {
        switch (field.type) {
            case InputTypes.Input: return renderText();
            case InputTypes.Select: return renderSelect();
            case InputTypes.Radio: return renderRadio();
            default: return 'Input is not defined';
        }
    };

    return (
        getField()
    );
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(DangerousGoodSubLayoutFields);

import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { useTranslation } from 'react-i18next';
import {
    Col,
    Form,
    FormInstance,
    Input,
    Select,
} from 'antd';

const styles = () => ({
    formItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        '& .ant-checkbox + span': {
            color: '#111111',
            fontSize: 14,
            fontWeight: 600,
        },
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
    amount: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 20,
        width: '100%',
        '& .ant-input-group > .ant-input:first-child': {
            marginRight: 0,
        },
        '& .ant-select-selection-item, .ant-select-single.ant-select-show-arrow': {
            color: '#111111',
            fontSize: 12,
            width: 65,
            fontWeight: 600,
        },
    },
    label: {
        color: '#333333',
        fontSize: 12,
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    form: FormInstance;
    params: any,
}

const CODAmountField = (props: IProps) => {
    const { t } = useTranslation();
    const {
        classes,
        params,
        form,
        itemPartialDelivery,
        currencyListDomestic,
    } = props;
    const {
        IsCOD,
        Amount,
    } = formFields;
    const {
        id: pieceIndex, key, getValidationRules, getIsRequiredValidation,
    } = params;

    const isCodValue = Form.useWatch(IsCOD.key, form);
    const isPieceLevel = pieceIndex !== null && pieceIndex !== undefined;
    if ((isCodValue && isCodValue === 'no') && !isPieceLevel) return <></>;
    const disabled = isPieceLevel ? (itemPartialDelivery) : false;
    const renderCurrency = () => {
        const options = currencyListDomestic.map((currency: string) => {
            return (
                <Select.Option key={currency} value={currency}>
                    {currency}
                </Select.Option>
            );
        });
        if (!options.length || isPieceLevel) {
            return null;
        }
        return (
            <Form.Item
                name="currency"
                initialValue={currencyListDomestic[0]}
            >
                <Select
                    dropdownMatchSelectWidth={false}
                    placeholder="currency"
                >
                    {options}
                </Select>
            </Form.Item>
        );
    };

    return (
        <Col span={12}>
            <div className={classes.formItem}>
                <span className={classes.title}>
                    {isPieceLevel ? t('COD Amount') : t(Amount.key)}
                    {getIsRequiredValidation(Amount.key, isPieceLevel) && '*'}
                </span>
                <div style={{ display: 'flex', height: '100%', width: '100%' }}>
                    <Form.Item
                        name={key}
                        initialValue={Amount.defaultValue}
                        valuePropName={Amount.valuePropName}
                        style={{ flexBasis: '100%' }}
                        rules={[
                            {
                                validator: (rule, value, cb) => {
                                    if (value < 0) {
                                        return cb(t('negative_amount_error'));
                                    }
                                    return cb();
                                },
                            },
                            ...getValidationRules(Amount, isPieceLevel),
                        ]}
                    >
                        <Input
                            disabled={disabled}
                            type="number"
                            placeholder={t('cod_amount_placeholder')}
                        />
                    </Form.Item>
                    {renderCurrency()}
                </div>
            </div>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config } = master;
    const itemPartialDelivery = config?.item_partial_delivery;
    const currencyListDomestic = config.customer_portal_config?.cod_currency_list || ['INR'];
    return {
        itemPartialDelivery,
        currencyListDomestic,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(CODAmountField);

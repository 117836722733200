import * as React from 'react';
import { commonStyleSheet } from 'library/common-styles';
import withStyles from 'react-jss';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { accountStyles } from './settings.styles';
import { useTranslation } from 'react-i18next';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import BulkPickupSlots from './bulk-pickupslots';
import {
    Button,
    Checkbox,
    Col, Form,
    Input,
    Row, Select,
    Spin,
    Table,
    Tooltip,
    Typography,
    message,
} from 'antd';
import { CloseOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import {
    getAddresses, getPickupRules, createPickupRule, deletePickupRule,
} from 'network/pickup.api';
import Loader from 'components/common/Loader';
import { useState } from 'react';

const { Text } = Typography;
const { Option } = Select;
const styles = (theme: ThemeType) => ({
    ...commonStyleSheet(theme),
    mainDiv: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    heading: {
        fontWeight: 'bold',
        textAlign: 'left',
        fontSize: 'larger',
        marginTop: '20px',
        marginBottom: '20px',
    },
    addressSelection: {
        padding: '20px',
        border: '1px solid #ccc',
        width: '300px',
        margin: '0 auto',
    },
    addressItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
        padding: '10px',
    },
    tableLoader: {
        textAlign: 'center',
        padding: '20px',
    },
    errormsg: {
        color: 'red',
    },
});
interface SchedulePickupProps extends StylesProps<ReturnType<typeof styles>> {
}

interface Address {
    clientId: string;
    pincode: string;
    cityName: string;
    stateName: string;
    name: string;
    phone: string;
    email?: string;
    addressLine1: string;
    addressLine2?: string;
    isInternational: boolean;
    countryName?: string;
    id: string;
    alternatePhone?: string;
    addressCode?: string;
}

const SchedulePickup = (props: SchedulePickupProps) => {
    const { classes } = props;
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [pickupData, setPickupData] = React.useState([]);
    const [addressList, setAddressList] = React.useState<Address[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [tableLoading, setTableLoading] = React.useState(false); // New state for table data loading
    const [drawerContent, setDrawerContent] = React.useState('selectAddress');
    const [selectedAddress, setSelectedAddress] = React.useState<Address | null>(null);
    const [showSelectedAddress, setShowSelectedAddress] = React.useState(false);
    const [searchLoading, setSearchLoading] = React.useState(false);
    const [isModalVisibleForBulkPickupSlot, setIsModalVisibleForBulkPickupSlot] = useState(false);

    const showModalForBulkPickupSlot = () => {
        setIsModalVisibleForBulkPickupSlot(!isModalVisibleForBulkPickupSlot);
    };

    const handleCancel = () => {
        setIsModalVisibleForBulkPickupSlot(false);
    };

    const fetchPickupRules = async () => {
        const response = await getPickupRules();
        if (response.isSuccess) {
            setPickupData(response.data.rules);
        } else {
            message.error(response.errorMessage);
        }
    };
    const fetchAddressesAndRules = async () => {
        setLoading(true);
        try {
            const response = await getAddresses({ pageNumber: 1, resultPerPage: 5 });
            setAddressList(response.data.addressBook);
        } catch (errorMsg: any) {
            message.error(errorMsg.message);
        } finally {
            setLoading(false);
        }
        fetchPickupRules();
    };

    React.useEffect(() => {
        fetchAddressesAndRules();
    }, []);

    const handleDelete = async (record: any) => {
        const rule = { id: record.id };
        setTableLoading(true); // Show loader while fetching table data
        const response = await deletePickupRule(rule);
        if (response.isSuccess) {
            await fetchPickupRules(); // Refresh the pickup rules
        } else {
            message.error(response.errorMessage);
        }
        setTableLoading(false);
    };

    const columns = [
        {
            title: 'Sl no',
            dataIndex: 'slNo',
            key: 'slNo',
            width: 80,
        },
        {
            title: 'Day',
            dataIndex: 'day',
            key: 'day',
            width: 100,
        },
        {
            title: 'Pick Time Slot',
            dataIndex: 'timeSlot',
            key: 'timeSlot',
            width: 140,
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 85,
            render: (record: any) => (
                <Tooltip title={t('delete')}>
                    <DeleteOutlined onClick={() => handleDelete(record)} />
                </Tooltip>
            ),
        },
    ];

    const renderTitle = () => (
        <Text style={{ fontSize: 'larger', marginLeft: '20px', marginBottom: '20px' }} strong>
            {t('pickup_date_and_time')}
        </Text>
    );

    const handleFormSubmit = async (values: any) => {
        const { day, timeSlot } = values;
        const [pickupStart, pickupEnd] = timeSlot.split('-');
        const {
            addressLine1,
            addressLine2,
            cityName,
            stateName,
            countryName,
            pincode,
            phone,
        } = selectedAddress || {};
        const address = {
            addressLine1,
            addressLine2,
            cityName,
            stateName,
            countryName,
            pincode,
            phone,
        };
        const finalObject = {
            day,
            pickupStart,
            pickupEnd,
            address,
        };
        setTableLoading(true); // Show loader while fetching table data
        const response = await createPickupRule(finalObject);
        if (response.isSuccess) {
            await fetchPickupRules();
        } else {
            message.error(response.errorMessage);
        }
        setTableLoading(false);
    };
    const handleAddressSelection = (address: any) => {
        setSelectedAddress(address);
    };
    const handleRemoveSelectedAddress = () => {
        setSelectedAddress(null);
        setShowSelectedAddress(false);
    };
    const handleNewAddressSubmit = (values: any) => {
        const fullAddress = `${values.addressLine1}, 
        ${values.addressLine2 || ''}, 
        ${values.cityName}, 
        ${values.stateName}, 
        ${values.countryName}, 
        ${values.pinCode}, 
        ${values.phone}, 
        ${values.alternateMobileNumber}`;
        setSelectedAddress({
            ...values,
            fullAddress,
        });
        setShowSelectedAddress(true);
        setDrawerContent('selectAddress');
    };

    const SelectAddress = () => {
        setShowSelectedAddress(true);
    };

    const generateTimeSlots = () => {
        const slots = [];
        for (let hour = 7; hour <= 21; hour += 1) { // 2-hour intervals
            const start = `${hour.toString().padStart(2, '0')}:00`;
            const end = `${(hour + 2).toString().padStart(2, '0')}:00`;
            slots.push(`${start}-${end}`);
        }
        return slots;
    };

    const timeSlots = generateTimeSlots();

    const fetchAddresses = async (searchString: any) => {
        setSearchLoading(true);
        const response = await getAddresses({ pageNumber: 1, resultPerPage: 5, searchString });
        if (response.isSuccess) {
            setAddressList(response.data.addressBook);
        } else {
            message.error(response.errorMessage);
        }
        setSearchLoading(false);
    };
    const handleSearchChange = (e: any) => {
        const newSearchString = e.target.value;
        fetchAddresses(newSearchString);
    };

    return (
        <>
            {loading ? <Loader zIndex={10} /> : (
                <div className={classes.mainDiv}>
                    {renderTitle()}
                    <Row gutter={16} style={{ marginBottom: '2px' }}>
                        <Col span={16}>
                            <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
                                <Row gutter={16}>
                                    <Col span={4}>
                                        <Form.Item name="day" label="Day" rules={[{ required: true }]}>
                                            <Select placeholder="Select a day">
                                                <Option value="Sunday">Sunday</Option>
                                                <Option value="Monday">Monday</Option>
                                                <Option value="Tuesday">Tuesday</Option>
                                                <Option value="Wednesday">Wednesday</Option>
                                                <Option value="Thursday">Thursday</Option>
                                                <Option value="Friday">Friday</Option>
                                                <Option value="Saturday">Saturday</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item name="timeSlot" label="Pickup Time Slot" rules={[{ required: true }]}>
                                            <Select>
                                                {timeSlots.map((slot) => (
                                                    <Option key={slot} value={slot}>{slot}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    {showSelectedAddress ? (
                                        <>
                                            <Col span={12}>
                                                <div>
                                                    <span>
                                                        {selectedAddress
                                                            ? `${selectedAddress.addressLine1}
                        ${selectedAddress.addressLine2 ? `, ${selectedAddress.addressLine2}` : ''}, 
                        ${selectedAddress.cityName}, 
                        ${selectedAddress.stateName}, 
                        ${selectedAddress.pincode}`
                                                            : 'No address selected'}
                                                    </span>


                                                    <Button type="link" onClick={handleRemoveSelectedAddress}>
                                                        <CloseOutlined />
                                                    </Button>
                                                </div>
                                            </Col>
                                        </>
                                    ) : (
                                        <>
                                            <Col span={6}>
                                                <Form.Item label="Pickup Address">
                                                    <Button type="link" onClick={() => setDrawerContent('selectAddress')}>
                                                        SELECT FROM SAVED ADDRESS
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item label="Pickup Address">
                                                    <Button type="link" onClick={() => setDrawerContent('newAddress')}>
                                                        ADD NEW ADDRESS
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </>
                                    )}
                                    <Col span={4}>
                                        <Button type="primary" htmlType="submit" disabled={!selectedAddress}>
                                            Add Pickup Slot
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                            <Row justify="space-between" align="middle" gutter={16}>
                                <Col span={12}>
                                    <h2>Active Pickup Schedules</h2>
                                </Col>
                                <Col span={12} style={{ textAlign: 'right', marginBottom: '10px' }}>
                                    <Button type="primary" onClick={showModalForBulkPickupSlot}>
                                        Add Bulk Pickup Slot
                                    </Button>
                                    <BulkPickupSlots
                                        isModalVisible={isModalVisibleForBulkPickupSlot}
                                        handleCancel={handleCancel}
                                        tableLoading={tableLoading}
                                        setTableLoading={setTableLoading}
                                        fetchPickupRules={fetchPickupRules}
                                    />
                                </Col>
                            </Row>
                            <div className={tableLoading ? classes.tableLoader : ''}>
                                {tableLoading ? <Loader zIndex={100} /> : (
                                    <Table
                                        dataSource={pickupData}
                                        columns={columns}
                                        rowKey="slNo"
                                        scroll={{ y: 400 }}
                                        pagination={false}
                                    />
                                )}
                            </div>
                        </Col>
                        <Col span={8}>
                            {drawerContent === 'selectAddress' && (
                                <div>
                                    <h2>Select Address</h2>
                                    <Input
                                        placeholder="Search"
                                        prefix={<SearchOutlined />}
                                        onChange={handleSearchChange}
                                        style={{ marginBottom: '16px', width: '50%' }}
                                    />
                                    <div style={{
                                        marginBottom: '2rem',
                                        padding: '0.5rem',
                                        alignItems: 'center',
                                    }}
                                    >
                                        {searchLoading ? (
                                            <Spin />
                                        ) : (
                                            <div
                                                style={{
                                                    maxHeight: 300,
                                                    overflowY: 'auto',
                                                }}
                                            >
                                                {addressList.map((address) => (
                                                    <div className={classes.addressItem} key={address.id}>
                                                        <Checkbox
                                                            checked={selectedAddress === address}
                                                            onChange={() => handleAddressSelection(address)}
                                                        >
                                                            <div style={{
                                                                marginLeft: '0.5rem',
                                                                marginBottom: '1rem',
                                                                padding: '5px',
                                                            }}
                                                            >
                                                                <div>
                                                                    {`${address.name},
                                                                     ${address.addressLine1}${address.addressLine2 ? `,
                                                                    ${address.addressLine2}` : ''}, 
                                                                     ${address.cityName},
                                                                     ${address.stateName},
                                                                     ${address.pincode}`}
                                                                </div>
                                                            </div>
                                                        </Checkbox>
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                    </div>
                                    <Button type="primary" onClick={SelectAddress} disabled={!selectedAddress}>
                                        Select Address
                                    </Button>
                                </div>
                            )}
                            {drawerContent === 'newAddress' && (
                                <div>
                                    <h2>Add New Address</h2>
                                    <Form layout="vertical" onFinish={handleNewAddressSubmit}>
                                        <Form.Item
                                            name="companyName"
                                            label="Company name"
                                            style={{ marginBottom: '8px' }}
                                        >
                                            <Input placeholder="Company Name" />
                                        </Form.Item>
                                        <Form.Item
                                            name="addressLine1"
                                            label="Address Line 1"
                                            rules={[{ required: true }]}
                                            style={{ marginBottom: '8px' }}
                                        >
                                            <Input placeholder="Address Line 1" />
                                        </Form.Item>
                                        <Form.Item
                                            name="addressLine2"
                                            label="Address Line 2"
                                            style={{ marginBottom: '8px' }}
                                        >
                                            <Input placeholder="Address Line 2" />
                                        </Form.Item>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="pincode"
                                                    label="PIN Code"
                                                    rules={[{ required: true }]}
                                                    style={{ marginBottom: '8px' }}
                                                >
                                                    <Input placeholder="PIN Code" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="cityName"
                                                    label="City"
                                                    rules={[{ required: true }]}
                                                    style={{ marginBottom: '8px' }}
                                                >
                                                    <Input placeholder="City" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="stateName"
                                                    label="State"
                                                    rules={[{ required: true }]}
                                                    style={{ marginBottom: '8px' }}
                                                >
                                                    <Input placeholder="State" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="countryName"
                                                    label="Country"
                                                    rules={[{ required: true }]}
                                                    style={{ marginBottom: '8px' }}
                                                >
                                                    <Input placeholder="Country" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="latitude"
                                                    label="Latitude"
                                                    style={{ marginBottom: '8px' }}
                                                >
                                                    <Input placeholder="Latitude" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="longitude"
                                                    label="Longitude"
                                                    style={{ marginBottom: '8px' }}
                                                >
                                                    <Input placeholder="Longitude" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="phone"
                                                    label="Mobile Number"
                                                    rules={[{ required: true }]}
                                                    style={{ marginBottom: '8px' }}
                                                >
                                                    <Input placeholder="Mobile Number" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="alternateMobileNumber"
                                                    label="Alternate Mobile Number"
                                                    style={{ marginBottom: '8px' }}
                                                >
                                                    <Input placeholder="Alternate Mobile Number" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Button type="primary" htmlType="submit" style={{ marginTop: '8px' }}>
                                            Save & Use as Pickup Address
                                        </Button>
                                    </Form>
                                </div>
                            )}
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};
const mapStateToProps = () => {
};
const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};


export default withStyles(accountStyles)(GenericHoc(hocConfig)(SchedulePickup));

import React from 'react';
import { FormInstance } from 'antd';
import { formFields, dangerousGoodFields } from 'components/create-consignment/create-modal.constants';
import ContentTypeField from './piece-field/content-type';
import CommodityNameField from './piece-field/commodity-name';
import NumPiecesField from './num-pieces';
import WeightField from './weight';
import DimensionsField from './dimensions';
import PieceReferenceNumber from './piece-field/piece-reference-number';
import GenericField from './generic-field';
import DescriptionField from './description';
import RiskField from './risk-surcharge';
import DeclaredValueField from './declared-value';
import DangerousGoodSubLayoutFields from './dangerous-good-generic';
import DangerousGoodUnNumberField from './dangerous-good-un-number';
import ItemUnit from './piece-field/item-unit';
import QuantityField from './piece-field/quantity';
import CodAmount from './cod-amount';
import piecesItemDetailsFields from './pieces-item-details';
import PurchaseOrderNumberField from './piece-field/purchase-order-number';
import DangerousGoodFields from './dangerous-good-renderer';
import ProductCodeField from './piece-field/product-code';

const {
    ContentTypes,
    ContentNameForOthers,
    NumPieces,
    Weight,
    DeclaredValue,
    ReferenceNumber,
    Vol_weight,
    Description,
    RiskSurcharge,
    ItemUnitType,
    Amount,
    Quantity,
    TaxPercentage,
    TotalTax,
    ManufacturingCountry,
    MEIS,
    PurchaseOrderNumber,
    Eccn,
    IsKit,
    PieceProductCode,
    HsCode,
    IossCode,
    IGST,
    CGST,
    SGST,
    TaxDetailsRecieverGstin,
    TaxDetailsSenderGstin,
    ForwardReferenceNumber,
} = formFields;

const {
    DangerousGoodCategoryPackingInstruction,
    DangerousGoodCategoryType,
    DangerousGoodCategory,
    DangerousGoodPackingGroup,
    DangerousGoodAccessible,
    DangerousGoodUnNumber,
    IsDangerousGood,
} = dangerousGoodFields;

const pieceFieldsMapping = {
    [ContentTypes.key]: ContentTypeField,
    [ItemUnitType.key]: ItemUnit,
    [Amount.key]: CodAmount,
    [Quantity.key]: QuantityField,
    [ContentNameForOthers.key]: CommodityNameField,
    [ReferenceNumber.key]: PieceReferenceNumber,
    [NumPieces.key]: NumPiecesField,
    [Weight.key]: WeightField,
    dimensions: DimensionsField,
    [DeclaredValue.key]: DeclaredValueField,
    piecesItem: piecesItemDetailsFields,
    [RiskSurcharge.key]: (props: any) => {
        return <RiskField field={RiskSurcharge} {...props} />;
    },
    [Description.key]: DescriptionField,
    [MEIS.key]: (props: any) => {
        return <GenericField field={MEIS} {...props} />;
    },
    [PurchaseOrderNumber.key]: PurchaseOrderNumberField,
    [Eccn.key]: (props: any) => {
        return <GenericField field={Eccn} {...props} />;
    },
    [PieceProductCode.key]: ProductCodeField,
    [HsCode.key]: (props: any) => {
        return <GenericField field={HsCode} {...props} />;
    },
    [IossCode.key]: (props: any) => {
        return <GenericField field={IossCode} {...props} />;
    },
    [IsKit.key]: (props: any) => {
        return <GenericField field={IsKit} {...props} />;
    },
    dangerousGood: (props: any) => {
        return <DangerousGoodFields {...props} />;
    },
    [ManufacturingCountry.key]: (props: any) => {
        const { params } = props;
        const { manufacturingCountryList } = params;
        params.enableCustomFilter = true;
        return (
            <GenericField
                field={
                    {
                        ...ManufacturingCountry,
                        options: manufacturingCountryList || [],
                    }
                }
                {...props}
            />
        );
    },
    [TotalTax.key]: (props: any) => {
        return (
            <GenericField
                field={TotalTax}
                {...props}
            />
        );
    },
    [TaxPercentage.key]: (props: any) => {
        return (
            <GenericField
                field={TaxPercentage}
                MAX_LIMIT={100}
                {...props}
            />
        );
    },
    [IGST.key]: (props: any) => {
        return (
            <GenericField {...props} field={IGST} MAX_LIMIT={100} />
        );
    },
    [CGST.key]: (props: any) => {
        return (
            <GenericField {...props} field={CGST} MAX_LIMIT={100} />
        );
    },
    [SGST.key]: (props: any) => {
        return (
            <GenericField {...props} field={SGST} MAX_LIMIT={100} />
        );
    },
    [TaxDetailsRecieverGstin.key]: (props: any) => {
        return (
            <GenericField {...props} field={TaxDetailsRecieverGstin} />
        );
    },
    [TaxDetailsSenderGstin.key]: (props: any) => {
        return (
            <GenericField {...props} field={TaxDetailsSenderGstin} />
        );
    },
    [ForwardReferenceNumber.key]: (props: any) => {
        return (
            <GenericField {...props} field={ForwardReferenceNumber} />
        );
    },
};

const documentFieldMapping = {
    [Weight.key]: WeightField,
    [Description.key]: DescriptionField,
    [ContentTypes.key]: ContentTypeField,
    [ContentNameForOthers.key]: CommodityNameField,
    [ReferenceNumber.key]: PieceReferenceNumber,
    [NumPieces.key]: NumPiecesField,
    dimensions: DimensionsField,
    [DeclaredValue.key]: DeclaredValueField,
    [RiskSurcharge.key]: (props: any) => {
        return <RiskField field={RiskSurcharge} {...props} />;
    },
};

const envelopeFieldMapping = {
    [Weight.key]: WeightField,
    [Description.key]: DescriptionField,
    [ContentTypes.key]: ContentTypeField,
    [ContentNameForOthers.key]: CommodityNameField,
    [ReferenceNumber.key]: PieceReferenceNumber,
    [NumPieces.key]: NumPiecesField,
    dimensions: DimensionsField,
    [DeclaredValue.key]: DeclaredValueField,
    [RiskSurcharge.key]: (props: any) => {
        return <RiskField field={RiskSurcharge} {...props} />;
    },
};

const boxDetailsFieldMapping = {
    [NumPieces.key]: NumPiecesField,
    [Weight.key]: WeightField,
    dimensions: DimensionsField,
    [DeclaredValue.key]: (props: any) => {
        return <GenericField field={DeclaredValue} {...props} />;
    },
    [Vol_weight.key]: (props: any) => {
        return <GenericField field={Vol_weight} {...props} />;
    },
    [Description.key]: DescriptionField,
};

const dangerousGoodFieldMapping = {
    [IsDangerousGood.key]: (props: any) => {
        return <DangerousGoodSubLayoutFields field={IsDangerousGood} {...props} />;
    },
    [DangerousGoodUnNumber.key]: (props: any) => {
        return <DangerousGoodUnNumberField {...props} />;
    },
    [DangerousGoodAccessible.key]: (props: any) => {
        return <DangerousGoodSubLayoutFields field={DangerousGoodAccessible} {...props} />;
    },
    [DangerousGoodCategoryType.key]: (props: any) => {
        return <DangerousGoodSubLayoutFields field={DangerousGoodCategoryType} {...props} />;
    },
    [DangerousGoodCategoryPackingInstruction.key]: (props: any) => {
        return <DangerousGoodSubLayoutFields field={DangerousGoodCategoryPackingInstruction} {...props} />;
    },
    [DangerousGoodCategory.key]: (props: any) => {
        return <DangerousGoodSubLayoutFields field={DangerousGoodCategory} {...props} />;
    },
    [DangerousGoodPackingGroup.key]: (props: any) => {
        return <DangerousGoodSubLayoutFields field={DangerousGoodPackingGroup} {...props} />;
    },
};

const getMapping = (
    id: any,
    form: FormInstance,
    params: any,
    Component: any,
    shouldNotMakeItemTypeCheck: boolean = false,
) => {
    if (Component) {
        return <Component form={form} params={params} shouldNotMakeItemTypeCheck={shouldNotMakeItemTypeCheck} />;
    }
    console.log('Cannot find component for ', id);
    return null;
};

export const getPieceLevelFieldFromMapping = (id: any, form: FormInstance, params: any) => {
    return getMapping(id, form, params, pieceFieldsMapping[id]);
};

export const getDocumentFieldFromMapping = (id: any, form: FormInstance, params: any) => {
    return getMapping(id, form, params, documentFieldMapping[id], true);
};

export const getEnvelopeFieldFromMapping = (id: any, form: FormInstance, params: any) => {
    return getMapping(id, form, params, envelopeFieldMapping[id], true);
};

export const getBoxLevelFieldFromMapping = (id: any, form: FormInstance, params: any) => {
    return getMapping(id, form, params, boxDetailsFieldMapping[id]);
};

export const getDangerousGoodFieldFromMapping = (id: any, form: FormInstance, params: any) => {
    return getMapping(id, form, params, dangerousGoodFieldMapping[id]);
};
